import React, { useState, useEffect } from 'react';

// a function that adds 2 days to a date as long as the given date is in the past
// if the given date is in the future, it will be returned
function add2Days (date) {
  const newDate = new Date(date.getTime() + 2 * 24 * 60 * 60 * 1000);
  if (newDate.getTime() < new Date().getTime()) {
    return add2Days(newDate);
  }
  return newDate;
}

function Timer () {
  const startDate = new Date('2023-04-11T12:00:00Z');
  const [now, setNow] = useState(new Date());
  const [startDateAdjusted, setStartDateAdjusted] = useState(add2Days(startDate));
  const [timeDifference, setTimeDifference] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newNow = new Date();
      const newTimeDifference = startDateAdjusted.getTime() - newNow.getTime();
      setTimeDifference(newTimeDifference);
      setNow(newNow);
    }, 1000);
    return () => clearInterval(interval);
  }, [startDateAdjusted]);

  const hours = Math.max(Math.floor(Math.abs(timeDifference) / (60 * 60 * 1000)), 0);
  const minutes = Math.max(Math.floor((Math.abs(timeDifference) % (60 * 60 * 1000)) / (60 * 1000)), 0);
  const seconds = Math.max(Math.floor((Math.abs(timeDifference) % (60 * 1000)) / 1000), 0);

  return (
    <>{timeDifference >= 0 ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : '00:00:00'}</>
  );
}

export default Timer;
