import React from 'react';
const data = {
  fonts: [
    'Arial',
    'OpenSans',
    'Courier',
    'Helvetica',
    'Times New Roman',
    'Roboto',
    'Lato',
    'Lora',
    'Calibri'
  ].map(f => ({ value: f, label: f })),
  applicationTypes: [
    { label: 'Initiativbewerbung', value: 'initiativ' },
    { label: 'Stellenanzeige', value: 'stellenanzeige' }
  ],
  documentTypes: [
    {
      name: 'Anschreiben',
      headline: 'Anschreiben',
      description: null,
      required: true,
      maxFiles: 100,
      hide: true
    },
    {
      name: 'Deckblatt',
      headline: 'Deckblatt',
      description: null,
      required: false,
      maxFiles: 100,
      hide: true
    },
    {
      name: 'Lebenslauf-Foto',
      headline: 'Lebenslauf-Foto',
      description: null,
      required: false,
      maxFiles: 1,
      hide: true,
      allowed: ['.jpeg', '.png', '.jpg']
    },
    {
      name: 'Unterschrift',
      headline: 'Unterschrift',
      description: null,
      required: false,
      maxFiles: 1,
      hide: true,
      allowed: ['.jpeg', '.png', '.jpg']
    },
    {
      name: 'Lebenslauf',
      headline: 'Lebenslauf',
      description: null,
      required: true,
      maxFiles: 1
    },
    {
      name: 'Approbationsurkunde',
      headline: 'Approbationsurkunde',
      description: 'Wenn du deine Approbationsurkunde schon hast, kannst du sie hier hochladen. Das wäre empfehlenswert. Wenn nicht, reichen erst einmal die Zeugnisse der Examina.',
      required: false,
      maxFiles: 1
    },
    {
      name: 'Zeugnisse der Examina',
      headline: 'Zeugnisse der Examina',
      description: 'Hier kannst du alle Zeugnisse hochladen, die du im Laufe deines Studiums für Examina erhalten hast.',
      required: true,
      maxFiles: 100
    },
    {
      name: 'Zeugnis der ärztlichen Prüfung',
      headline: 'Zeugnis der ärztlichen Prüfung',
      description: null,
      required: false,
      maxFiles: 1
    },
    {
      name: 'Ausbildungszeugnis',
      headline: 'Ausbildungszeugnis',
      description: 'Wenn du z.B. eine Ausbildung im Rettungsdienst absolviert hast, kannst du dessen Zeugnis hier hochladen.',
      required: false,
      maxFiles: 1
    },
    {
      name: 'Abiturzeugnis',
      headline: 'Abiturzeugnis',
      description: null,
      required: false,
      maxFiles: 1
    },
    {
      name: 'Empfehlungsschreiben',
      headline: 'Empfehlungsschreiben',
      description: 'Wenn du im Laufe deiner ärztlichen Karriere Empfehlungsschreiben erhalten hast, kannst du sie hier hochladen',
      required: false,
      maxFiles: 100
    },
    {
      name: 'Auslandsaufenthalte',
      headline: 'Auslandsaufenthalte',
      description: 'Wenn du Nachweise und Zeugnisse von Auslandsaufenthalten hast, kannst du sie hier hochladen.',
      required: false,
      maxFiles: 100
    },
    {
      name: 'Fachkunde- und Fortbildungsnachweise',
      headline: 'Fachkunde- und Fortbildungsnachweise',
      description: 'Hier kannst du alles hochladen, was du an Fortbildungen gemacht und Fachkundenachweisen bekommen hast.',
      required: false,
      maxFiles: 100
    },
    {
      name: 'Promotionsurkunde',
      headline: 'Promotionsurkunde',
      description: 'Wenn du bereits promoviert bist, kannst du das Zeugnis hier hochladen.',
      required: false,
      maxFiles: 1
    },
    {
      name: 'Weiteres',
      headline: 'Andere relevante Dokumente',
      description: null,
      required: false,
      maxFiles: 100
    }
  ],
  medAreasOfExpertise: [
    { label: 'Bitte wählen...', value: '', isDisabled: true },
    { label: 'Allgemeinmedizin', value: 'Allgemeinmedizin' },
    { label: 'Anästhesiologie', value: 'Anästhesiologie' },
    { label: 'Arbeitsmedizin', value: 'Arbeitsmedizin' },
    { label: 'Augenheilkunde', value: 'Augenheilkunde' },
    { label: 'Allgemeinchirurgie', value: 'Allgemeinchirurgie' },
    { label: 'Gefäßchirurgie', value: 'Gefäßchirurgie' },
    { label: 'Herzchirurgie', value: 'Herzchirurgie' },
    { label: 'Kinderchirurgie', value: 'Kinderchirurgie' },
    { label: 'Orthopädie und Unfallchirurgie', value: 'Orthopädie und Unfallchirurgie' },
    { label: 'Plastische und Ästhetische Chirurgie', value: 'Plastische und Ästhetische Chirurgie' },
    { label: 'Thoraxchirurgie', value: 'Thoraxchirurgie' },
    { label: 'Viszeralchirurgie', value: 'Viszeralchirurgie' },
    { label: 'Frauenheilkunde und Geburtshilfe', value: 'Frauenheilkunde und Geburtshilfe' },
    { label: 'Geriatrie', value: 'Geriatrie' },
    { label: 'Hals-Nasen-Ohrenheilkunde', value: 'Hals-Nasen-Ohrenheilkunde' },
    { label: 'Haut- und Geschlechtskrankheiten', value: 'Haut- und Geschlechtskrankheiten' },
    { label: 'Humangenetik', value: 'Humangenetik' },
    { label: 'Hygiene und Umweltmedizin', value: 'Hygiene und Umweltmedizin' },
    { label: 'Innere Medizin', value: 'Innere Medizin' },
    { label: 'Innere Medizin und Angiologie', value: 'Innere Medizin und Angiologie' },
    { label: 'Innere Medizin und Endokrinologie und Diabetologie', value: 'Innere Medizin und Endokrinologie und Diabetologie' },
    { label: 'Innere Medizin und Gastroenterologie', value: 'Innere Medizin und Gastroenterologie' },
    { label: 'Innere Medizin und Hämatologie und Onkologie', value: 'Innere Medizin und Hämatologie und Onkologie' },
    { label: 'Innere Medizin und Kardiologie', value: 'Innere Medizin und Kardiologie' },
    { label: 'Innere Medizin und Nephrologie', value: 'Innere Medizin und Nephrologie' },
    { label: 'Innere Medizin und Pneumologie', value: 'Innere Medizin und Pneumologie' },
    { label: 'Innere Medizin und Rheumatologie', value: 'Innere Medizin und Rheumatologie' },
    { label: 'Kinder- und Jugendpsychiatrie', value: 'Kinder- und Jugendpsychiatrie' },
    { label: 'Laboratoriumsmedizin', value: 'Laboratoriumsmedizin' },
    { label: 'Mikrobiologie, Virologie und Infektionsepidemiologie', value: 'Mikrobiologie, Virologie und Infektionsepidemiologie' },
    { label: 'Mund-Kiefer-Gesichtschirurgie', value: 'Mund-Kiefer-Gesichtschirurgie' },
    { label: 'Neurochirurgie', value: 'Neurochirurgie' },
    { label: 'Neurologie', value: 'Neurologie' },
    { label: 'Nuklearmedizin', value: 'Nuklearmedizin' },
    { label: 'Öffentliches Gesundheitswesen', value: 'Öffentliches Gesundheitswesen' },
    { label: 'Neuropathologie', value: 'Neuropathologie' },
    { label: 'Pathologie', value: 'Pathologie' },
    { label: 'Klinische Pharmakologie', value: 'Klinische Pharmakologie' },
    { label: 'Pharmakologie und Toxikologie', value: 'Pharmakologie und Toxikologie' },
    { label: 'Physikalische und Rehabilitative Medizin', value: 'Physikalische und Rehabilitative Medizin' },
    { label: 'Psychiatrie und Psychotherapie', value: 'Psychiatrie und Psychotherapie' },
    { label: 'Psychosomatische Medizin und Psychotherapie', value: 'Psychosomatische Medizin und Psychotherapie' },
    { label: 'Radiologie', value: 'Radiologie' },
    { label: 'Rechtsmedizin', value: 'Rechtsmedizin' },
    { label: 'Strahlentherapie', value: 'Strahlentherapie' },
    { label: 'Transfusionsmedizin', value: 'Transfusionsmedizin' },
    { label: 'Urologie', value: 'Urologie' },
    { label: 'Kinder- und Jugendmedizin', value: 'Kinder- und Jugendmedizin' },
    { label: 'Gynäkologie', value: 'Frauenheilkunde und Geburtshilfe' },
    { label: 'Dermatologie', value: 'Haut- und Geschlechtskrankheiten' },
    { label: 'Pädiatrie', value: 'Kinder- und Jugendmedizin' },
    { label: 'HNO', value: 'Hals-Nasen-Ohrenheilkunde' },
    { label: 'KJP', value: 'Kinder- und Jugendpsychiatrie' }
  ],
  cookies: [
    {
      name: 'Felix Medicus Cookie',
      id: 'fxmed-rating',
      type: 'Nutzerverhalten',
      description: 'Cookie für das Aufzeichnen des Nutzerverhaltens, um unsere Plattform weiterzuentwickeln.'
    },
    {
      name: 'Cookie für Bewertungen',
      id: 'rating',
      type: 'Nutzerinformationen',
      description: 'Cookie, der deine Daten für eine Bewertung speichert, damit du nicht immer deine Daten eingeben musst.'
    }
  ],
  germanMonths: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ],
  languages: [
    { label: 'Bitte wählen', value: null },
    { label: 'Deutsch', value: 'Deutsch' },
    { label: 'Englisch', value: 'Englisch' },
    { label: 'Türkisch', value: 'Türkisch' },
    { label: 'Spanisch', value: 'Spanisch' },
    { label: 'Italienisch', value: 'Italienisch' },
    { label: 'Chinesisch', value: 'Chinesisch' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'Arabisch', value: 'Arabisch' },
    { label: 'Französisch', value: 'Französisch' },
    { label: 'Russisch', value: 'Russisch' },
    { label: 'Portugiesisch', value: 'Portugiesisch' },
    { label: 'Japanisch', value: 'Japanisch' },
    { label: 'Vietnamesisch', value: 'Vietnamesisch' },
    { label: 'Koreanisch', value: 'Koreanisch' },
    { label: 'Albanisch', value: 'Albanisch' },
    { label: 'Rumänisch', value: 'Rumänisch' },
    { label: 'Mazedonisch', value: 'Mazedonisch' },
    { label: 'Bulgarisch', value: 'Bulgarisch' },
    { label: 'Bosnisch', value: 'Bosnisch' },
    { label: 'Serbisch', value: 'Serbisch' },
    { label: 'Kroatisch', value: 'Kroatisch' },
    { label: 'Montenegrinisch', value: 'Montenegrinisch' },
    { label: 'Griechisch', value: 'Griechisch' },
    { label: 'Norwegisch', value: 'Norwegisch' },
    { label: 'Holländisch', value: 'Holländisch' },
    { label: 'Schwedisch', value: 'Schwedisch' },
    { label: 'Finnisch', value: 'Finnisch' }
  ],
  positions: [
    { label: 'PJler/in', value: 'PJ' },
    { label: 'Assistenzärztin/-arzt', value: 'Assistenzarzt' },
    { label: 'Fachärztin/-arzt', value: 'Facharzt' }
  ],
  sex: [
    { value: 'm', label: 'männlich' },
    { value: 'w', label: 'weiblich' },
    { value: 'd', label: 'divers' }
  ],
  salutation: [
    {
      value: '',
      label: 'Auswählen...',
      isDisabled: true
    },
    { value: 'Sehr geehrte Frau', label: 'Frau' },
    { value: 'Sehr geehrter Herr', label: 'Herr' }
  ],
  docTitle: [
    {
      value: '',
      label: 'kein Titel'
    },
    { value: 'Dr.', label: 'Dr.' },
    { value: 'Dr. med.', label: 'Dr. med.' },
    { value: 'Prof. Dr. med.', label: 'Prof. Dr. med.' },
    { value: 'Prof.', label: 'Prof.' }
  ],
  fileTypeMapper: {
    'application/pdf': require('../img/mimetypes/application-pdf.png'),
    'application/msword': require('../img/mimetypes/application-msword.png'),
    'application/vnd.openxmlformats-officedocument. wordprocessingml.document': require('../img/mimetypes/application-msword.png'),
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': require('../img/mimetypes/application-msword.png'),
    'application/rtf': require('../img/mimetypes/application-rtf.png'),
    'text/plain': require('../img/mimetypes/text-plain.png'),
    'text/rtf': require('../img/mimetypes/text-rtf.png'),
    'image/jpeg': require('../img/mimetypes/image-x-generic.png'),
    'image/png': require('../img/mimetypes/image-x-generic.png'),
    'image/tiff': require('../img/mimetypes/image-x-generic.png'),
    'image/gif': require('../img/mimetypes/image-x-generic.png'),
    fallback: require('../img/mimetypes/text-plain.png')
  },
  applicationPictureTips: [
    {
      header: 'Dein Blick',
      tip: 'Strahle in die Kamera, als würdest Du Deinen besten Freund sehen. Ein offenes Lächeln, bei dem die Zähne sichtbar sind, wirkt freundlicher und einladender, als ein geschlossener Mund. Wenn der Fotograf einen Moment länger braucht, um den Auslöser zu betätigen, entspanne Deine Gesichtsmuskeln und lächele noch einmal.'
    },
    {
      header: 'Deine Haut',
      tip: 'Da vor allem dein Gesicht auf dem Foto zu sehen ist, ist das Hautbild ein entscheidender Faktor. Auch Männer sollten die Haut zumindest abpudern, damit kein fettiger Glanz zu sehen ist. Frauen sehen mit einem leichten Make-up vorteilhafter aus. Leg nur ein Abend-Make-up auf, wenn du dich im Nachtclub bewirbst. Vermeide eine extreme Sommerbräune auf dem Bewerbungsbild, das wirkt deplatziert.'
    },
    {
      header: 'Deine Haare',
      tip: 'Ein Besuch beim Friseur, um sich für das Bewerbungsfoto und das Vorstellungsgespräch in Schuss zu bringen, tut deinem Haar und deinem Selbstbewusstsein gut. Wenn du mit einer professionellen Föhnwelle vom Frisör kommst, kann das auch unnatürlich aussehen. Trage auf dem Foto die Haare so natürlich, wie du es immer tust. Dann siehst du authentisch aus. Frauen (und Männer) mit langen Haaren legen die Haare auf dem Bewerbungsfoto hinter die Schultern.'
    },
    {
      header: 'Deine Kleidung',
      tip: 'Pass auf, dass deine Kleidung seriös und gepflegt aussieht. Ein eher konservatives Outfit in gedämpften Farben ist vorzuziehen. Schrille Neonfarben und große Muster sind ebenso ungeeignet, wie ein zu tiefes Dekolleté oder ein zu weit aufgeknöpftes Hemd - das wirkt billig. Bei der Schmuckauswahl auf dem Bewerbungsfoto bleib bei der dezenten und unauffälligen Variante. Da Frauen fürs Bewerbungsfoto zu einer Bluse und Herren ein Hemd mit Krawatte greifen, ist darauf zu achten, dass der Kragen ordentlich liegt. Bei widerspenstigen Krägen behilfst du dich mit Sprühstärke. Der Krawattenknoten ist ordentlich gebunden und sitzt in der Mitte des Kragens. Wer ein Bügeleisen besitzt, ist klar im Vorteil.'
    },
    {
      header: 'Der Hintergrund',
      tip: 'Du solltest für dein Bewerbungsfoto einen hellen, neutralen Hintergrund wählen. Dieser sollte möglichst keine Sachen aus deiner Privatsphäre enthalten und gut mit den Farben des Deckblattes abstimmen.'
    },
    {
      header: 'Die Ausleuchtung',
      tip: 'Ein Bewerbungsfoto sieht edel aus, wenn der Hintergrund richtig ausgeleuchtet wird. In der Regel werden mehrere Blitze synchronisiert und gleichzeitig ausgelöst. Außerdem wird mit entsprechenden Aufhellern gearbeitet, damit das Bild hell, lebendig und schön aussieht. Ein Profi-Fotograf hat es gelernt, Dich hervorragend in Szene zu setzen.'
    }
  ],
  arztbriefExamples: [
    {
      type: 'Post-OP-Überwachung',
      example: {
        anamnese: '- Postoperative intensivmedizinische Überwachung und Therapie nach VATS rechts mit Oberlappenresektion rechts bei V.a. NSCLC am 18.04.2023',
        verlauf: '- kompolikationsloser Aufenthalt\n- kardiopulmonal stabil katecholaminfrei komplett adäquat orientiert\n- schmerzfrei\n- Verlegung auf Wachzimmer in gutem Allgemeinzustand'
      }
    },
    {
      type: 'Pneumonie',
      example: {
        anamnese: '- Fieber und Husten seit 08.04.2023\n- stationäre Aufnahme 10.04.2023',
        verlauf: '- Röntgen Thorax Infiltrate rechter Unterlappen 10.04.2023\n- Leukozytose, CRP Erhöhung 10.04.2023\n-ambulant erworbene Pneumonie\n- Ampicillin/Sulbactam ab 10.04.2023\n-NIV Therapie intermittierend mit HF seit 11.04.2023\n-rückläufige Infektzeichen und Oralisierung Antibiose auf Amoxiclav am 15.04.2023\n-Entlassung 16.04.2023'
      }
    },
    {
      type: 'ITS nach Operation',
      example: {
        anamnese: '- Übernahme aus dem OP, Cholezystektomie\n-Überwachung auf ITS wegen kardialen Vorerkrankungen',
        verlauf: '- TTE bei Aufnahme weiter global hypokinetisch bei eher balloniert imponierendem Ventrikel\n- Beginn von Dobutamin auf 4ml/h sowie NA 5/50 auf 3ml/h\n- Im Verlauf wurde der ICD ausgelesen, welcher intraoperativ ausgelöst habe. Zwei Mal innerhalb von 26 Sekunden habe dieser bei einer Ventrikulären Tachykardie ausgelöst\n- Für 24h Therapie mit Simdax\n- Da vor zwei Wochen bereits TTE durchgeführt wurde - keine Indikation für erneute Untersuchung durch die Internisten\n- Kardiologisches Konsil: Patient hat hohes Risiko bei anstehenden Narkosen. Aus kardiologischer Sicht wird von Sedierung sowie Narkose abgeraten\n- Dobutamin weiter an zentralvenöse Sättigung angepasst\n- PTA für 21.4 geplant'
      }
    },
    {
      type: 'Bewusstlosigkeit und Hyponatriämie',
      example: {
        anamnese: '- von Nachbarn schlafend auf dem Boden liegend vorgefunden worden\n- vom Rettungsdienst ins Krankenhaus Bellingheim transportiert worden\n- In der Notaufnahme schlafend aber auf Schmerzreiz erweckbar\n- Alkoholintoxikation (3,09‰)\n- unklarer Vigilanzminderung\n- einmaligem Krampfanfall\n- Hyponatriämie\n- Übernahme erfolgte aus dem Krankenhaus Bellingheim aufgrund mangelnder Intensivkapazitäten',
        verlauf: '- nach der Übernahme auf unsere Intensivstation\n- reagiert auf Ansprache\n- cCT druchgeführt (keine Verletzungszeichen, keine Blutung)\n- entzügig, daher Gabe von Distraneurin\n- Natrium war in Perz zunächst 128mmol/l, es erfolgte dort die Gabe von 1,5l Jonosteril. Nach Ankunft hier zeigte sich sowohl in Labor als auch BGA ein Natrium von 143mmol. Viel zu schnell angestiegen, daher Gegensteuern mit G5. Hierunter sank das Serumnatrium wieder auf 132mmol/l.\n- Im Verlauf vital stabil\n- Kalium bereits bei Ankunft niedrig normal'
      }
    },
    {
      type: 'Amnioninfketionssyndrom',
      example: {
        anamnese: '- Frühgeborenes 27+3 ssw am 1.3.\n-Sectio\n-Amnioninfektionssyndrom\n-iGDM',
        verlauf: '- Aufnahme am 1.3. Am hfo cpap\n- Antibiose mit ampicillin cefotaxim tobramycin'
      }
    },
    {
      type: 'Ausschluss LAE',
      example: {
        anamnese: '- 01.04.2023 stationäre Aufnahme Geriatrie\n- in der Nacht vom 02.04.2023 auf den 03.04.2023 Thorakale Schmerzen und Entsättigung auf der Normalstation, daher übernommen auf die Intensivstation',
        verlauf: '- Highflow-Therapie, zunächst FiO2 85%, im Tagesverlauf reduziert auf 60%, hierunter jeweils gutes pO2. Jedoch Hyperventilation mit pCO2 um 25mmHg.\n- Labordiagnostik: Troponin diskret erhöht (zweiter Wert bei Verlegung ausstehend). CRP leicht erhöht, jedoch ohne Leukozytose, daher PCT nachgefordert, dieses bei Verlegung ausstehend.\n- CT Thorax: Ausschluss einer LAE, keine Pneumonie, keine erneute Aortendissektion\n- längeres Gespräch mit dem Patienten, dessen Ehefrau und beiden Töchtern: Im Sinne des Patienten zurückhaltende Diagnostik und Therapie. Daher Festlegung DNR, DNI und in Zukunft keine Intensivtherapie. Fortführung der Therapie auf Normalstation.\n- 04.04.2023 Verlegung auf Normalstation mit symptomatischer Therapie (Highflow)'
      }
    },
    {
      type: 'Bauchschmerzen',
      example: {
        anamnese: '- 05.04. in Begleitung der Mutter\n- 2 Tage Durchfall und Erbrechen\n- starke Bauchschmerzen\n- Trinkverhalten reduziert\n- Miktion reduziert\n- AZ Verschlechterung\n- Keine Vorerkrankungen, Allergien bekannt\n- Dauermedikation Vitamin D 500 IE 1-0-0',
        verlauf: '- Aufnahme 05.04. bei reduziertem Turgor\n- Substitution mit elektrolytbilanzierten Infusionen\n- Sonographie Abdomen opB\n- Besserung des AZ\n- Nach 2 Tagen gutes Trink- und Essverhalten\n- Kein Erbrechen mehr, Stuhlgang normalisiert, Schmerzen spontan sistierend\n- Entlassung am 08.04.'
      }
    }
  ]
};

const getFileImage = mt => {
  if (Object.keys(data.fileTypeMapper).includes(mt)) {
    return data.fileTypeMapper[mt];
  } else {
    return require('../img/mimetypes/text-plain.png');
  }
};

const PRICE_PER_CREDIT = 0.29;

export default {
  ...data,
  getFileImage,
  PRICE_PER_CREDIT
}
;
