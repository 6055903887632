import { CircularProgress, Container } from '@mui/material';
import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { fontWeight } from '@mui/system';
import DocumentIcon from '../../assets/img/document-icon.png';
import Logo from '../../assets/img/felix-check.png';
import helper from '../../assets/helper';
import { Cancel, CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { WindowManagerContext } from '../../context/WindowManager';
import WizardButton from '../buttons/WizardButton';
import ProgressTracker from './ProgressTracker';

const ArztbriefHolder = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 180,
    height: 285,
    marginLeft: 12,
    marginRight: 12
  },
  [theme.breakpoints.down('md')]: {
    width: 150,
    height: 285,
    marginLeft: 6,
    marginRight: 6
  },
  textAlign: 'center',
  fontWeight: 500,
  paddingLeft: '0px!important',
  paddingRight: '0px!important',
  marginTop: 32,
  fontSize: 14,
  cursor: 'pointer'
}));

const DinA4Holder = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 160,
    height: 160 * 1.39
  },
  [theme.breakpoints.down('md')]: {
    width: 130,
    height: 130 * 1.39
  },
  ':hover': {
    opacity: 1
  },
  backgroundColor: 'rgba(255, 255, 255, 0.69)',
  border: '3px solid rgba(0, 0, 0, 0.1)',
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const generateStartAndEnd = (start, { anamnese, verlauf }) => {
  // get length of prompt
  const lengthOfPrompt = ((anamnese.split(' ').length + verlauf.split(' ').length) * 3) + 200;
  const estimatedSeconds = 0.1 * lengthOfPrompt;

  start = new Date(start).getTime();

  // add seconds and save it to end
  const end = start + (estimatedSeconds * 1000);

  return { start, end };
};

const ArztbriefIcon = ({ onClick = () => {}, name = 'Example Arztbrief', result = 's', updatedAt: created = new Date('2023-04-11T08:30:00'), createdAt, status = 'created', style, regenerate, reloadArztbriefe, original_prompt: originalPrompt, ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  const [{ start, end }, setGeneratedStartEnd] = React.useState(generateStartAndEnd(created, originalPrompt));
  const regenerateErrorArztbrief = () => {
    const regenerateFunction = regenerate();
    regenerateFunction();
  };
  const openErrorWindow = () => {
    openWindow('notification', {
      maxWidth: 400,
      text: close => {
        return (
          <>
            <h2>Leider ist die Generierung fehlgeschlagen...</h2>
            <p>Probier' es gerne nochmal.<br />Dafür fallen natürlich <b>keine Kosten</b> an!</p>
            <WizardButton onClick={() => { regenerateErrorArztbrief(); reloadArztbriefe(); }} scale={2} style={{ float: 'right' }}>Neu generieren</WizardButton>
          </>
        );
      }
    });
  };
  return (
    <>
      <ArztbriefHolder onClick={status === 'created' ? () => {} : status === 'done' ? onClick : () => openErrorWindow()}>
        <DinA4Holder sx={status === 'created' ? { opacity: 0.5 } : status === 'done' ? { backgroundImage: `url(${DocumentIcon})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundColor: 'white', opacity: 0.5 } : { backgroundColor: '#fcada9', opacity: 0.5 }}>
          {
            status === 'created'
              ? (
                <div>
                  <span style={{ color: 'rgba(0,0,0,0.54)', fontWeight: 500, fontSize: 16 }}>wird generiert...</span>
                  <br /><br />
                  <ProgressTracker start={start} end={end} />
                </div>
                )
              : status === 'done'
                ? (
                  <CheckCircle style={{ color: 'green', fontSize: 50 }} />
                  )
                : (
                  <Cancel style={{ color: 'red', fontSize: 50 }} />
                  )
          }
        </DinA4Holder>
        {helper.shortenText(name)}<br /><span style={{ fontWeight: 400, color: 'grey' }}>{helper.getGermanTimeDifference(new Date(created))} erstellt</span>
      </ArztbriefHolder>
    </>
  );
};

export default ArztbriefIcon;
