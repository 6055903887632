import React, { useContext, useEffect } from 'react';
import CoverLetterWizardFooter from '../../cover-letter-wizard/CoverLetterWizardFooter';
import { ApplicationDataContext } from '../../../context/ApplicationData';
import { CircularProgress } from '@mui/material';
import { Container } from '@mui/system';
import PrimaryButton from '../../buttons/PrimaryButton';
import { WindowManagerContext } from '../../../context/WindowManager';
import PhotoContainer from '../../forms/photo-upload/PhotoContainer';
import PhotoUploader from '../../forms/photo-upload/PhotoUploader';
import { AuthContext } from '../../../context/Auth';

const Photo = ({ cvData, previousStep, nextStep, saveData, ...props }) => {
  const [photo, setPhoto] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { data: applicationData } = useContext(ApplicationDataContext);
  const { openWindow } = useContext(WindowManagerContext);
  const { api } = useContext(AuthContext);
  const { applicationCause, employerData } = applicationData;
  const { photo: relevantCvData } = cvData;

  const goToNextStep = () => {
    if (photo !== null) {
      nextStep();
    } else {
      alert('Bitte lade ein Bild für den Lebenslauf hoch.');
    }
  };

  const downloadPhoto = async photo => {
    setLoading(true);
    console.log(photo);
    const blob = await api(jwt => photo.downloadBlob(jwt, () => {}));
    setImage(URL.createObjectURL(blob));
    setLoading(false);
  };

  useEffect(() => {
    if (photo?.id) {
      downloadPhoto(photo);
    }
  }, [photo]);

  useEffect(() => {
    if (relevantCvData > 0) {
      setPhoto({ id: relevantCvData });
    }
  }, []);

  const UploadWindowButton = props => (
    <PrimaryButton
      onClick={() => openWindow('notification', {
        width: Math.min(window.innerWidth - window.innerWidth * 0.25, 500),
        header: 'Foto hochladen',
        text: close => <PhotoUploader defaultFile={photo} onSet={file => { setPhoto(file); close(); }} />
      })}
    >
      {image ? 'Bearbeiten / Neues hochladen' : 'Foto hochladen'}
    </PrimaryButton>
  );

  return (
    <>
      {
        !loading
          ? (
            <>
              <h2>Dein Bewerbungsfoto</h2>
              {
                image
                  ? (
                    <center>
                      <img src={image} width={300} style={{ borderRadius: 2 }} /><br />
                      <UploadWindowButton />
                    </center>
                    )
                  : (
                    <PhotoContainer sx={{ width: 300, height: 300 * 5 / 4 }}>
                      <center>
                        {
                          loading
                            ? <CircularProgress />
                            : <UploadWindowButton />
                        }
                      </center>
                    </PhotoContainer>
                    )
              }

            </>
            )
          : <CircularProgress />
      }
      <CoverLetterWizardFooter
        previous={previousStep}
        next={goToNextStep}
      />
    </>
  );
};

export default Photo;
