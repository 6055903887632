import React from 'react';
import { Card, CardContent, Grid, Hidden, useMediaQuery } from '@mui/material';
import IconWithText from '../misc/IconWithText';
import useTraceUpdate from '../hooks/useTraceUpdate';
import Check from '@mui/icons-material/Check';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';

const cardStyles = {
  marginTop: '10px',
  border: '1px solid rgba(0, 0, 0, 0.13)',
  padding: '8px 12px',
  boxShadow: 0
};

const TextModuleBox = props => {
  const { checked, saveData, headline, children, disabled, styles } = props;
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Card sx={{ ...cardStyles, ...styles }} onClick={() => disabled ? alert(props.errorMessage) : saveData(!checked)}>
        <CardContent style={{ padding: 0 }}>
          <Grid container style={{ padding: 5, cursor: 'pointer' }}>
            <Grid item xl={12} md={12} xs={12}>
              <IconWithText
                icon={checked
                  ? <Check sx={{ color: 'primary.main', fontWeight: 700 }} />
                  : <RadioButtonUnchecked sx={{ color: 'primary.main', fontWeight: 700 }} />}
                text={<span style={{ fontWeight: 500, marginLeft: 12 }}>{headline}</span>}
              />
            </Grid>
          </Grid>
          {
            checked && (
              <>
                {
                isMobile
                  ? (
                    <div style={{ display: checked ? null : 'none', padding: 4, paddingTop: 12 }}>
                      {children}
                    </div>
                    )
                  : (
                    <div style={{ display: checked ? null : 'none', padding: 20 }}>
                      {children}
                    </div>
                    )
              }
              </>
            )
          }
        </CardContent>
      </Card>
    </>
  );
};

export default TextModuleBox;
