import React, { createContext } from 'react'
import { Outlet } from 'react-router-dom'
import { getApplication, getFiles, sendApplication, updateApplication } from '../api/methods'
import { AuthContext } from './Auth'

const ApplicationDataContext = createContext()

const ApplicationData = ({ ...props }) => {
  const { user, auth, api } = React.useContext(AuthContext)
  const [data, setData] = React.useState({
    id: props.id,
    files: props.files || [],

    applicationCause: props.applicationCause || 'ownInitiative',
    jobRole: props.jobRole,
    subject: props.subject,

    domainSpecificInformation: {
      expertiseArea: props.expertiseArea
    },

    employerData: {
      name: props.employerData?.name,
      address: {
        street: props.employerData?.address?.street,
        postalCode: props.employerData?.address?.postalCode,
        city: props.employerData?.address?.city,
        country: props.employerData?.address?.country
      }
    },

    contactPerson: {
      salutation: props.contactPerson?.salutation,
      title: props.contactPerson?.title,
      firstName: props.contactPerson?.firstName,
      lastName: props.contactPerson?.lastName,
      email: props.contactPerson?.email
    },

    personalData: {},

    cvId: props.cvId,
    coverLetterId: props.coverLetterId,

    sendingInformation: {
      recipient: props.sendingInformation?.recipient,
      timestamp: props.sendingInformation?.timestamp,
      emailText: props.sendingInformation?.emailText,
      subject: props.sendingInformation?.subject
    },
    status: 'in progress'
  })

  React.useEffect(() => {
    console.log('makes reload')
    save()
  }, [data])

  const loadData = async (applicationId) => {
    const application = await api(jwt => getApplication(applicationId, jwt))
    setData(old => ({ ...old, ...application, id: applicationId }))
  }

  const resetData = newData => {
    setData(old => ({ ...newData }))
  }

  const setOfflineData = newData => {
    setData(old => ({ ...old, ...newData }))
  }

  const addFile = (fileObject) => {
    setData(old => ({ ...old, files: [...old.files, fileObject] }))
  }

  const removeFileId = (fileId) => {
    setData(old => ({ ...old, files: old.files.filter(file => file.id !== fileId) }))
  }

  const removeFileType = (type) => {
    setData(old => {
      if (type === 'Anschreiben') old.coverLetterId = null
      if (type === 'Lebenslauf') old.cvId = null
      return { ...old, files: old.files.filter(file => file.type !== type) }
    })
  }

  const hasSpecificFile = (type) => {
    data.files?.forEach(file => {
      if (file.type === type) return true
    })
    return false
  }

  const getFirstFileOfType = (type) => {
    data.files?.forEach(file => {
      if (file.type === type) return file
    })
    return false
  }

  const hasCoverLetter = () => {
    return (data.coverLetterId || hasSpecificFile('Anschreiben'))
  }

  const hasCV = () => {
    return (data.cvId || hasSpecificFile('Lebenslauf'))
  }

  const getCoverLetterFile = async () => {
    if (!hasCoverLetter()) return null
    if (data.coverLetterId) {
      const coverLetter = (await api(jwt => getFiles({ ids: [data.coverLetterId] }, jwt)))[0]
      // const blob = await coverLetter.downloadBlob(jwt, () => {});
      return coverLetter
    } else {
      const coverLetter = getFirstFileOfType('Anschreiben')
      // const blob = await coverLetter.downloadBlob(jwt, () => {});
      return coverLetter
    }
  }

  const getCVFile = async () => {
    if (!hasCV()) return null
    if (data.cvId) {
      const cv = (await api(jwt => getFiles({ ids: [data.cvId] }, jwt)))[0]
      return cv
    } else {
      const cv = getFirstFileOfType('Lebenslauf')
      return cv
    }
  }

  const addCoverLetter = (newCoverLetterId) => {
    setData(old => ({ ...old, coverLetterId: newCoverLetterId, files: old.files?.filter(file => file.type !== 'Anschreiben') || [] }))
  }

  const addCV = (newCVId) => {
    setData(old => ({ ...old, cvId: newCVId }))
  }

  const hasEmployerData = () => {
    return (data.employerData?.address ? Object.keys(data.employerData?.address).length > 0 : false) && !!data.employerData?.name
  }

  const getApplicantAddress = () => {
    return `${getApplicantName()}\n${data.personalData?.address?.street}\n${data.personalData?.address?.postalCode} ${data.personalData?.address?.city}`
  }

  const getEmployerAddressWithoutPerson = () => {
    return `${data.employerData.name}\n${data.domainSpecificInformation.expertiseArea ? `Fachrichtung ${data.domainSpecificInformation.expertiseArea}\n` : ''}${data.employerData.address?.street}\n${data.employerData.address?.postalCode} ${data.employerData.address?.city}`
  }

  const getEmployerAddress = () => {
    return `${data.employerData.name}\n${data.domainSpecificInformation.expertiseArea ? `Fachrichtung ${data.domainSpecificInformation.expertiseArea}\n` : ''}z. Hd. ${data.contactPerson.salutation === 'Herr' ? 'Herrn' : 'Frau'} ${data.contactPerson.title ? data.contactPerson.title + ' ' : ''}${data.contactPerson.firstName} ${data.contactPerson.lastName}\n${data.employerData.address?.street}\n${data.employerData.address?.postalCode} ${data.employerData.address?.city}`
  }

  const getSalutation = () => {
    return `${data.contactPerson.salutation === 'Herr' ? 'Sehr geehrter Herr' : 'Sehr geehrte Frau'} ${data.contactPerson.title ? data.contactPerson.title + ' ' : ''}${data.contactPerson.lastName},`
  }

  const getApplicantName = () => {
    return (data.personalData?.title ? data.personalData?.title + ' ' : '') + data.personalData?.firstName + ' ' + data.personalData?.lastName
  }

  const saveEmployerData = async (newData) => {
    setData(old => ({
      ...old,
      employerData: {
        ...old.employerData,
        name: newData.employerData.name,
        address: newData.employerData.address
      },
      contactPerson: {
        ...old.contactPerson,
        ...newData.contactPerson
      }
    }))
  }

  const save = async () => {
    if (!auth?.jwt || !data.id) return
    console.log('ApplicationData', 'save data', data)
    api(jwt => updateApplication(data, jwt))
  }

  const sendMail = async (emailData) => {
    const email = {
      emailText: emailData.emailText,
      recipient: emailData.recipient,
      applicant: {
        firstName: emailData.applicant?.firstName,
        lastName: emailData.applicant?.lastName,
        email: emailData.applicant?.email
      },
      domain: 'felixmedicus.de',
      subject: emailData.subject,
      replyTo: emailData.applicant?.email,
      files: emailData.files
    }

    console.log(email)

    await api(jwt => sendApplication(email, jwt))

    setData(old => ({
      ...old,
      status: 'sent',
      sendingInformation: {
        recipient: email.recipient,
        timestamp: emailData.timestamp,
        emailText: email.emailText,
        subject: email.subject
      }
    }))
  }

  const setStatus = newStatus => {
    setData(old => ({
      ...old,
      status: newStatus
    }))
  }

  const hasPersonalData = (applicationData = data) => {
    if (!data.personalData?.firstName) return false
    if (!data.personalData?.lastName) return false
    if (!data.personalData?.address.street) return false
    if (!data.personalData?.address.city) return false
    if (!data.personalData?.address.postalCode) return false
    return true
  }

  const setPersonalData = async (applicantData) => {
    setData(old => ({
      ...old,
      personalData: {
        ...old.personalData,
        title: applicantData?.title || '',
        firstName: applicantData?.firstName,
        lastName: applicantData?.lastName,
        email: applicantData?.email,
        address: {
          ...old.personalData?.address,
          street: applicantData?.address?.street,
          postalCode: applicantData?.address?.postalCode,
          city: applicantData?.address?.city,
          country: applicantData?.address?.country
        }
      }
    }))
  }

  return (
    <ApplicationDataContext.Provider value={{
      methods: {
        resetData,
        setPersonalData,
        hasPersonalData,
        hasSpecificFile,
        hasEmployerData,
        hasCoverLetter,
        sendMail,
        saveEmployerData,
        getSalutation,
        getApplicantAddress,
        getApplicantName,
        getEmployerAddress,
        getEmployerAddressWithoutPerson,
        addCV,
        addCoverLetter,
        addFile,
        removeFileType,
        loadData,
        removeFileId,
        getCoverLetterFile,
        hasCV,
        getCVFile,
        setOfflineData,
        setStatus
      },
      data
    }}
    >
      {props.children}
    </ApplicationDataContext.Provider>
  )
}

export { ApplicationData, ApplicationDataContext }
