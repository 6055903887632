import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/Auth';
import { styled } from '@mui/material/styles';
import GridBase from '@mui/material/Grid';
import CreditGraphic from '../assets/img/credit_graphic.png';
import Paypal from '../assets/img/paypal.png';
import FelixCoin from '../assets/img/felix_medicus_coin.png';
import Payment from '../assets/img/payment.png';
import { CircularProgress, Typography, useMediaQuery } from '@mui/material';
import SelectField from '../components/forms/SelectField';
import IconWithText from '../components/misc/IconWithText';
import helper from '../assets/helper';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { Container } from '@mui/system';
import InputField from '../components/forms/InputField';
import Edit from '@mui/icons-material/Edit';
import { WindowManagerContext } from '../context/WindowManager';
import LocalOffer from '@mui/icons-material/LocalOffer';
import Check from '@mui/icons-material/Check';
import PaypalButton from '../components/buttons/PaypalButton';
import { createDeposit } from '../api/methods';
import LoadingIcon from '../assets/img/loading_transp.gif';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import DomainData from '../assets/datasets/DomainData';
import WizardButton from '../components/buttons/WizardButton';

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`;

const FullHeightGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100vh',
    minHeight: '100vw'
  },
  color: 'black',
  padding: '5%',
  flexGrow: 1
}));

const FullHeightGridSecondary = styled(FullHeightGrid)(({ theme }) => ({
  color: 'white',
  paddingTop: '0%'
}));

const discountCalculator = credits => {
  if (credits < 10) return 0;
  if (credits >= 10 && credits < 30) return 0.1;
  if (credits >= 30 && credits < 50) return 0.2;
  if (credits >= 50) return 0.3;
};

const addressDataValid = data => {
  if (!data.firstName) return false;
  if (!data.lastName) return false;
  if (!data.email) return false;
  if (!data.address?.street) return false;
  if (!data.address?.postalCode) return false;
  if (!data.address?.city) return false;
  return true;
};

const { PRICE_PER_CREDIT } = DomainData;

const CreditDepositWindow = ({ style, close, minWidth, maxWidth, ...props }) => {
  const { auth, user, saveUserData, api, reloadUser } = useContext(AuthContext);
  const { openWindow } = useContext(WindowManagerContext);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [toBuy, setToBuy] = useState(2);
  const [couponCode, setCouponCode] = useState('');
  const [giftedCredits, setGiftedCredits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [billingAddress, setBillingAddress] = React.useState({ title: user?.title, email: user?.email, firstName: user?.firstName, lastName: user?.lastName, address: user?.address });

  const creditsPrice = (c = toBuy) => (toBuy * PRICE_PER_CREDIT) - (discountCalculator(toBuy) * (toBuy * PRICE_PER_CREDIT));
  const inEuros = creditsPrice();

  const applicationsForCredits = (c = toBuy) => Math.floor((c || 0));
  const applicationsToBuy = (c = toBuy) => <>+{applicationsForCredits(c)} {applicationsForCredits(c) !== 1 ? 'Arztbriefe' : 'Arztbriefe'}</>;

  const createDepositPayment = async paymentData => {
    setLoading(true);
    const depositObject = {
      billingAddress,
      credits: toBuy,
      price: creditsPrice(toBuy),
      discount: discountCalculator(toBuy) * 100,
      paymentData
    };
    try {
      await api(jwt => createDeposit(depositObject, jwt));
      setPaymentDone(true);
      setLoading(false);
    } catch {
      alert('Leider konnten deine Credits nicht gutgeschrieben werden... Der Admin wurde benachrichtigt. Keine Sorge: Du bekommst deine Credits!');
      setPaymentDone(false);
      setPaymentFailed(true);
      setLoading(false);
      return;
    }
    reloadUser();
  };

  const BuyButton = props =>
    <PaypalButton
      price={Math.max(0, inEuros)}
      formData={billingAddress}
      onSuccessfulCheckout={data => createDepositPayment(data)}
      handleValidationFail={() => {}}
      setLoading={() => {}}
      disabled={!addressDataValid(billingAddress || {})}
      onClick={addressDataValid(billingAddress || {}) ? null : () => alert('Bitte gib vorher noch deine Rechnungsadresse an...')}
    />;

  const CreditAdder = ({ style, ...props }) =>
    <div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : null, margin: '0px auto' }}>
      <a onClick={() => setToBuy(old => old > 0 ? old - 1 : old)} style={{ cursor: 'pointer', fontSize: 36 * props.scale, fontWeight: 600, padding: 12 * props.scale, borderRadius: '50%', width: 16 * props.scale, lineHeight: 0.3, height: 16 * props.scale, background: 'black' }}>-</a>&nbsp;&nbsp;
      <Typography sx={{ color: 'black', fontSize: 55 * props.scale, fontWeight: 500, letterSpacing: -3, marginBottom: 0 }}>{(toBuy || 0)}</Typography>&nbsp;&nbsp;
      <a onClick={() => { setToBuy(old => old + 1); }} style={{ cursor: 'pointer', fontSize: 36 * props.scale, fontWeight: 600, padding: 12 * props.scale, borderRadius: '50%', width: 16 * props.scale, lineHeight: 0.3, height: 16 * props.scale, background: 'black' }}>+</a>
    </div>;

  const BillingAddressComponent = props =>
    <IconWithText
      icon={<>{(billingAddress?.firstName || '???') + ' ' + (billingAddress?.lastName || '???') + ', ' + (billingAddress?.address?.street || '???') + ', ' + (billingAddress?.address?.postalCode || '???') + ' ' + (billingAddress?.address?.city || '???')}</>}
      text={<Edit />}
      style={{ cursor: 'pointer', color: 'black' }}
      onClick={
        () => openWindow('personalData', {
          maxWidth: 800,
          saveData: data => {
            setBillingAddress({ title: data?.title, email: data?.email, firstName: data?.firstName, lastName: data?.lastName, address: data?.address });
            if (auth?.loggedIn) saveUserData(data);
          }
        })
      }
    />;

  const currentState = () => {
    console.log(loading, paymentDone, paymentFailed);
    if (loading) return 1;
    if (paymentDone) return 2;
    if (paymentFailed) return 3;
    return 4;
  };

  return (
    <>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
      >
        {/** https://dribbble.com/shots/15937369-Bank-Loan-Superman-Illustrations-animations-JSON-Lottie */}
        <FullHeightGridSecondary
          item xs={12} sm={12} md={5} lg={5} xl={6} sx={{ backgroundColor: isMobile ? null : 'secondary.main' }}
        >
          <div style={{
            height: '80%',
            backgroundImage: `url(${Payment})`,
            backgroundPosition: isMobile ? 'top' : 'center',
            backgroundSize: isMobile ? '120%' : '100%',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            minHeight: '100vh'
          }}
          >
            {
            isMobile &&
              (
                <Container style={{ width: '80%', position: 'absolute', bottom: '5%', left: '10%' }} sx={{ color: 'grey.dark' }}>
                  {
                    {
                      1: (
                        <IconWithText
                          icon={<CircularProgress />}
                          text={<>Deine Credits werden gutgeschrieben...</>}
                        />
                      ),
                      2: (
                        <IconWithText
                          icon={<CheckCircle style={{ color: 'green', fontSize: '3rem' }} />}
                          text={<Typography style={{ fontSize: '2rem', color: 'green' }}>{toBuy} Credits wurden erfolgreich gutgeschrieben...</Typography>}
                        />
                      ),
                      3: (
                        <IconWithText
                          icon={<Cancel style={{ color: 'red', fontSize: '3rem' }} />}
                          text={<Typography style={{ fontSize: '2rem', color: 'red' }}>Leider ist etwas schiefgegangen... Wir kümmern uns umgehend darum!!!</Typography>}
                        />
                      ),
                      4: (
                        <>
                          <CreditAdder scale={1} />

                          <center>
                            <Typography sx={{ color: 'grey.light', fontSize: 23, fontWeight: 500, letterSpacing: -2 }} style={{ marginTop: -10 }}>
                              aktuell: {parseInt(user?.credits)} Credits<br />
                            </Typography>
                          </center>
                          <br /><br />
                          <span style={{ fontSize: '2rem', fontWeight: 600, color: 'green' }}><nobr>{helper.formatCurrency(inEuros)}</nobr></span><br />
                          <span style={{ fontSize: '1rem', fontWeight: 500, color: 'red' }}>Du sparst {helper.formatCurrency(discountCalculator(toBuy) * (toBuy * PRICE_PER_CREDIT))}</span>
                          <br /><br />
                          <b>Rechnungsadresse</b>
                          <BillingAddressComponent />
                          <br />
                          <BuyButton />
                        </>
                      )
                    }[currentState()]
                  }

                </Container>
              )
        }
          </div>

        </FullHeightGridSecondary>
        {
          !isMobile && (
            <FullHeightGrid item xs={12} md={7} lg={7} xl={6} sx={{ display: isMobile ? 'none' : null, padding: '10%' }}>
              <IconWithText
                icon={<img src={FelixCoin} width={45} />}
                text={<h1>Credits aufladen</h1>}
              />
              <br />
              {
                    {
                      1: (
                        <IconWithText
                          icon={<CircularProgress />}
                          text={<>Deine Credits werden gutgeschrieben...</>}
                        />
                      ),
                      2: (
                        <>
                          <IconWithText
                            icon={<CheckCircle style={{ color: 'green', fontSize: '3rem' }} />}
                            text={<Typography style={{ fontSize: '1.5rem', color: 'green' }}>{toBuy} Credits wurden erfolgreich gutgeschrieben...</Typography>}
                          />
                          <center><WizardButton scale={1.5} onClick={close} /></center>
                        </>
                      ),
                      3: (
                        <IconWithText
                          icon={<Cancel style={{ color: 'red', fontSize: '3rem' }} />}
                          text={<Typography style={{ fontSize: '1.5rem', color: 'red' }}>Leider ist etwas schiefgegangen... Wir kümmern uns umgehend darum!!!</Typography>}
                        />
                      ),
                      4: (
                        <>
                          <b>Anzahl der Credits:</b>
                          <div style={{ display: 'flex', margin: '12px auto', flexFlow: 'row wrap' }}>

                            {
                          Array.from(Array(5)).map((_, idx) => {
                            const currentRaise = [2, 10, 30, 50, 100][idx];
                            const discount = discountCalculator(currentRaise);
                            const active = currentRaise === toBuy;
                            return (
                              <Container
                                key={idx}
                                sx={{
                                  border: '2px solid',
                                  color: !active ? 'grey.light' : 'secondary.main',
                                  borderColor: !active ? 'grey.light' : 'secondary.main'
                                }}
                                style={{
                                  borderRadius: 6,
                                  cursor: 'pointer',
                                  padding: 12,
                                  paddingBottom: 6,
                                  background: 'white',
                                  maxWidth: 75,
                                  position: 'relative'
                                }}
                                onClick={() => setToBuy(currentRaise)}
                              >
                                <div style={{ position: 'absolute', top: 2, right: 10, color: 'red', fontSize: 12 }}>{discount === 0 ? '' : '-'}{discount * 100}%</div>
                                <center><span style={{ fontSize: '1.5rem', fontWeight: 500, letterSpacing: -1 }}>{currentRaise}</span><br /></center>
                              </Container>
                            );
                          })
                        }
                          </div>
                          <IconWithText
                            style={{ margin: 12 }}
                            icon={
                              <Check style={{ color: 'green' }} />
                        }
                            text={<Typography sx={{ color: 'grey.dark' }}>Damit kannst du <b>{applicationsForCredits()} Arztbriefe</b> generieren</Typography>}
                          />

                          {/* <CreditAdder /> */}
                          <br />
                          <b>Rechnungsadresse:&nbsp;</b>
                          <BillingAddressComponent />
                          <br />
                          <b>Preis:</b><br />
                          <span style={{ fontSize: '2rem', fontWeight: 600, color: 'green' }}><nobr>{helper.formatCurrency(inEuros)}</nobr></span><br />
                          <span style={{ fontSize: '1rem', fontWeight: 500, color: 'red' }}>Du sparst {helper.formatCurrency(discountCalculator(toBuy) * toBuy * PRICE_PER_CREDIT)}</span>
                          <br /><br />
                          <BuyButton />
                        </>
                      )
                    }[currentState()]
                  }

            </FullHeightGrid>
          )
        }
      </Grid>
    </>
  );
};

export default CreditDepositWindow;
