
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import React from 'react';

const CssSelectField = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 2,
    borderWidth: 1,
    borderColor: theme.palette.grey.lightest,
    '&:focus': {
      borderColor: theme.palette.secondary.lighter
    }
  },
  '&.Mui-focused': {
    borderColor: theme.palette.secondary.main + '!important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 2,
      borderWidth: 1,
      borderColor: theme.palette.secondary.main + '!important'
    }
  }
}));

const CssLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.grey.light,
  '&.Mui-focused': {
    color: theme.palette.secondary.main + '!important'
  }
}));

const SelectField = ({ id = Math.random() * 100, fullWidth, label, value, onChange, items, ...props }) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <CssLabel id={'select-label-' + id}>{label}</CssLabel>
      <CssSelectField
        labelId={'select-label-' + id}
        id={id}
        value={value || ''}
        label={label}
        onChange={onChange}
        MenuProps={{
          style: { zIndex: 3000 }
        }}
        {...props}
      >
        {
          items.map(({ value, label }, i) => <MenuItem key={i} value={value}>{label}</MenuItem>)
        }
      </CssSelectField>
    </FormControl>
  );
};

export default SelectField;
