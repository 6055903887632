import React, { useContext } from 'react';
import { AuthContext } from '../context/Auth';
import WindowWrapper from './WindowWrapper';
import Logo from '../assets/img/logo_other_way_around.png';
import LoadingIcon from '../assets/img/loading_transp.gif';
import { WindowManagerContext } from '../context/WindowManager';
import ShareButtons from '../misc/ShareButtons';
import { TrackingContext } from '../context/Tracking';
import PrimaryButton from '../components/buttons/PrimaryButton';
import InputField from '../components/forms/InputField';
import { CircularProgress, Container, Grid } from '@mui/material';
import { Box } from '@mui/system';
import WizardButton from '../components/buttons/WizardButton';

const LoginWindow = ({ style, close, className, ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  const { auth, login, user } = useContext(AuthContext);
  const { t } = useContext(TrackingContext);

  const [data, setData] = React.useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = React.useState(false);

  const editData = (key, value) => {
    setData(old => ({ ...old, [key]: value }));
  };

  const makeLogin = async () => {
    setLoading(true);
    try {
      await login(data.email, data.password);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    // t('action', 'opened_login', props.justLoggedOut ? 'from_logout' : '');
  }, []);

  return (
    <>
      <WindowWrapper
        maxWidth={400}
        maxHeight={600}
        style={style}
        close={close}
      >
        {
          auth?.loggedIn
            ? (
              <center>
                <img src={Logo} width={80} style={{ marginTop: 20 }} />
                <br />
                <h3>Willkommen, {user.firstName}!</h3>
                <p style={{ color: 'grey' }}>Schön, dass du wieder da bist! </p>
                <br />
                <WizardButton scale={2} onClick={() => { close(); }}>Jetzt Arztbrief generieren 🔥</WizardButton>
                <br /><br />
                <a style={{ color: 'grey', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => close()}>Fenster schließen</a>
                <br />
                <br />
                <p style={{ fontSize: 14, color: 'grey' }}>Teilen</p>
                <ShareButtons />
              </center>
              )
            : (
              <>
                <center><img src={Logo} width={80} /></center>
                <br />
                <center><h2>Einloggen</h2></center>
                {
                  props.hintText &&
                  (
                    <Container style={{ fontWeight: 500, marginTop: 18, marginBottom: 18, padding: 10, backgroundColor: '#fcf3b8', width: 'fit-content', marginLeft: 0, maxWidth: 350 }}>
                      {props.hintText}
                    </Container>
                  )
                }

                <br />
                <center>
                  <InputField
                    value={data.email || ''}
                    onChange={e => editData('email', e.currentTarget.value)}
                    name='email'
                    type='email'
                    label='E-Mail-Adresse'
                    placeholder='E-Mail-Adresse'
                    style={{ minWidth: 300 }}
                    fullwidth
                  />
                  <br />
                  <InputField
                    type='password'
                    value={data.password || ''}
                    name='password'
                    onChange={e => editData('password', e.currentTarget.value)}
                    label='Passwort'
                    placeholder='Passwort'
                    style={{ minWidth: 300, marginTop: 30 }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        makeLogin();
                      }
                    }}
                    fullwidth
                  />
                </center>
                <br />
                <center>
                  <WizardButton scale={2} onClick={!loading ? () => makeLogin() : () => {}}>{loading && <><CircularProgress size={20} />&nbsp;&nbsp;</>} Einloggen</WizardButton>
                </center>
                <br />
                {
                  props.justLoggedOut
                    ? (
                      <p style={{ color: 'grey', marginBottom: 0, textAlign: 'center' }}>
                        <i className='fa fa-check' style={{ color: 'green' }} /> Du hast dich erfolgreich ausgeloggt.
                      </p>
                      )
                    : (
                      <p style={{ color: 'grey', marginBottom: 0, textAlign: 'center' }}>
                        Hast du noch keinen Account?<br />&nbsp;<a onClick={() => openWindow('register')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Hier registrieren</a><br />
                      </p>
                      )
                }
              </>
              )
        }
      </WindowWrapper>
    </>
  );
};

export default LoginWindow;
