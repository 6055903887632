
import { Checkbox } from '@mui/material'
import React from 'react'

const CheckField = ({ id, onChange, variant = 'outlined', checked, ...props }) => {
  return (
    <Checkbox id={id} variant={variant} onChange={onChange} checked={checked} {...props} />
  )
}

export default CheckField
