import Logout from '@mui/icons-material/Logout';
import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/Auth';
import { WindowManagerContext } from '../../context/WindowManager';
import IconWithText from '../misc/IconWithText';

const Root = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: '100vh',
    paddingTop: '20%',
    padding: '15%'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    paddingTop: '20%',
    padding: '10%'
  },
  color: 'grey.dark'
}));

const ApplicationWizardMenu = ({ image, stepNr, header, explanation, tip, stepDescription, tipDescription, ...props }) => {
  const { auth } = useContext(AuthContext);
  const { openWindow } = useContext(WindowManagerContext);
  return (
    <Root>
      <img src={image} height={85} />
      <br /><br />
      <Typography variant='h6' sx={{ color: 'grey.lightest', fontWeight: 700, fontSize: 'h6.fontSize' }}>{stepDescription || 'STEP' + stepNr}</Typography>
      <h1 style={{ marginTop: 10, lineHeight: 1 }}>{header}</h1>
      <br />
      <Typography variant='p' sx={{ fontSize: '0.9rem', fontWeight: 500 }}>{explanation}</Typography>
      <br /><br />
      <hr style={{ marginTop: 24, marginBottom: 24 }} />
      <br />
      <Typography variant='p' sx={{ color: 'grey.light', fontWeight: 400, fontSize: '0.9rem' }}>
        <span style={{ fontWeight: 600 }}>{tipDescription || 'TIPP:'}</span><br />
        {tip}
      </Typography>
      <div style={{ width: '100%', height: 30 }} />
    </Root>
  );
};

export default ApplicationWizardMenu;
