import React, { createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { AuthContext } from './Auth';
import { v4 as uuidv4 } from 'uuid';
import useDeviceDetect from '../utils/useDeviceDetect';
import { createTracking } from '../api/methods';
import DomainData from '../assets/datasets/DomainData';

const TrackingContext = createContext();
const cookieName = 'arztbrief-tracking';
const cookieConsentName = 'cookieConsent';
const mode = process.env.NODE_ENV;

const MAX_FREE_RATING_VIEWS = 3;

const Tracking = (props) => {
  const [currentAction, setCurrentAction] = React.useState(null);
  const [id, setId] = React.useState(uuidv4());
  const { user, auth } = useContext(AuthContext);
  const { userAgent, isMobile } = useDeviceDetect();
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

  const setAcceptedCookies = accepted => {
    setCookie(cookieConsentName, JSON.stringify(accepted), { path: '/' });
    DomainData.cookies.map(a => a.id).forEach(ac => {
      if (cookies[ac] && !accepted.includes(ac)) {
        removeCookie(ac, { path: '/' });
      }
    });
    setMissingCookiesIfAllowed(accepted);
  };

  const createTrackingItem = (type, context, payload = '') => {
    // type = action, error
    // context = name of actions
    // payload = any data linked to the action / error message
    // duration = how long until next action (last action gets updated)
    // userId = unique id if not logged in
    // email = user email if logged in
    // userAgent = userAgent infos
    // test = is in test mode?!
    // timestamp = current timestamp
    // "71335d5c-49ae-49c3-83ec-ace57e5d2b85"
    const newAction = {
      type,
      context,
      payload,
      userId: user?.email || cookies[cookieName]?.id || id,
      email: user?.email,
      userAgent,
      mode,
      timestamp: new Date().getTime(),
      isMobile
    };

    /* if (currentAction) {
      console.log('action: ', currentAction, new Date(currentAction.timestamp));
      const trackingItem = { ...currentAction, duration: Math.floor((new Date().getTime() - currentAction.timestamp) / 10) / 100 };
      createTracking(trackingItem);
    } */

    setCurrentAction(old => {
      console.log(newAction);
      if (old && (old.type !== newAction.type && old.context !== newAction.context)) {
        const trackingItem = { ...old, duration: Math.floor((new Date().getTime() - old.timestamp) / 10) / 100 };
        track(trackingItem);
      } else if (newAction.type === 'website_hit') {
        track(newAction);
        return null;
      }
      return newAction;
    });
  };

  React.useEffect(() => {
    console.log('current action timestamp', currentAction?.timestamp, new Date(currentAction?.timestamp || 0));
  }, [currentAction]);

  const track = trackingItem => {
    try {
      createTracking(trackingItem);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCookie = () => {
    removeCookie(cookieName, { path: '/' });
  };

  const setMissingCookiesIfAllowed = (/* allowed = cookies[cookieConsentName] */) => {
    /* if (!cookies[cookieName] && allowed?.includes(cookieName)) {
      setCookie(cookieName, JSON.stringify({ id, ratingViews: 0 }), { path: '/' });
      setCurrentAction({});
    }
    if (!(cookies[cookieName]?.ratingViews !== undefined)) {
      setCookie(cookieName, JSON.stringify({ ...cookies[cookieName], ratingViews: 0 }), { path: '/' });
    } */
    if (!cookies[cookieName]) {
      console.log('set cookie', cookieName);
      setCookie(cookieName, JSON.stringify({ id, ratingViews: 0 }), { path: '/' });
      setCurrentAction(null);
    }
  };

  React.useEffect(() => {
    setMissingCookiesIfAllowed();
  }, [id, cookies]);

  const setSource = source => {
    if (cookies[cookieConsentName]?.includes(cookieName)) {
      setCookie(cookieName, JSON.stringify({ ...cookies[cookieName], source }), { path: '/' });
    }
  };

  const increaseRatingViews = () => {
    if (!cookies[cookieConsentName]?.includes(cookieName)) return;
    const currentRatingViews = cookies[cookieName].ratingViews || 0;
    cookies[cookieName].ratingViews = currentRatingViews + 1;
  };

  return (
    <TrackingContext.Provider value={{
      t: createTrackingItem,
      setSource,
      source: cookies[cookieName]?.source || null,
      increaseRatingViews,
      ratingViewsCount: cookies[cookieConsentName]?.includes(cookieName) ? cookies[cookieName]?.ratingViews : MAX_FREE_RATING_VIEWS,
      trackingActive: cookies[cookieConsentName]?.includes(cookieName),
      ratingViewsAllowed: auth?.loggedIn ? true : (cookies[cookieConsentName]?.includes(cookieName) ? cookies[cookieName]?.ratingViews : MAX_FREE_RATING_VIEWS) < MAX_FREE_RATING_VIEWS,
      setAcceptedCookies
    }}
    >
      {props.children}
    </TrackingContext.Provider>
  );
};

export { Tracking, TrackingContext }
;
