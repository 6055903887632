import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, LinearProgress, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const ProgressTracker = ({ start, end }) => {
  // create a function to track the current time every second and update the progress state
  const length = end - start;
  const alreadyPassed = new Date().getTime() - start;
  const [progress, setProgress] = useState(alreadyPassed / length);

  useEffect(() => {
    const interval = setInterval(() => {
      const alreadyPassed = new Date().getTime() - start;
      setProgress(alreadyPassed / length);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <CircularProgress value={progress} />
      <br />
      <Typography variant='body2' color='text.secondary'>{Math.min(Math.round(progress * 100), 100)} %</Typography>
    </div>
  );
};

export default ProgressTracker;
