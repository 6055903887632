import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const basicStyle = {
  padding: 20
};

const WindowWrapper = ({ title, children, style, maxWidth, maxHeight, close, open, ...props }) => {
  return (
    <>
      {/* <style>
        {
        `.customModal {
          width: ${style.width + (style?.width?.includes('%') ? '' : 'px')};
          margin: 0;
          height: ${style.height + (style?.height?.includes('%') ? '' : 'px')};
          padding: 0;
        }
        ` + (style.width === '100%'
          ? `
        .react-responsive-modal-modal {
          max-width: 100%
        }
        `
          : '') + `
        .react-responsive-modal-closeButton {
          margin-right: 10px;
        }
        `
      }
      </style>
      <Modal
        open
        onClose={close}
        center
        style={{ minWidth: 200, minHeight: 200, maxWidth, maxHeight, ...style }}
        closeIcon={<span style={{ fontSize: 24 }}>x</span>}
        classNames={{
          modal: (style.width || style.height) ? 'customModal' : ''
        }}
      >
        <div style={{ ...basicStyle, minWidth: 200, maxWidth, maxHeight, ...style }}>
          {children}
        </div>
      </Modal> */}
      {children}
    </>
  );
};

export default WindowWrapper;
