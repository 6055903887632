import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/Auth'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { createNewCV, getCVById, updateCoverLetter, updateCV } from '../../api/methods'
import { ApplicationDataContext } from '../../context/ApplicationData'
import LoadingIcon from '../../assets/img/loading_transp.gif'

import Step1Img from '../../assets/img/cause.png'
import Step2Img from '../../assets/img/personal.png'
import Step3Img from '../../assets/img/motivation.png'
import Step4Img from '../../assets/img/overview.png'
import PrimaryButton from '../buttons/PrimaryButton'
import ApplicationWizardMenu from '../wizard/ApplicationWizardMenu'
import { WindowManagerContext } from '../../context/WindowManager'
import DomainData from '../../assets/datasets/DomainData'
import Photo from './steps/Photo'
import { CircularProgress } from '@material-ui/core'
import { useIsMount } from '../hooks/useIsMount'
import useTraceUpdate from '../hooks/useTraceUpdate'

const MenuGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#F5F5F5',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  }
}))

const MainGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey.megaLight,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  }
}))

const Root = styled(Box)(({ theme }) => ({
  paddingTop: '5%',
  color: 'grey.dark',
  padding: '5%',
  paddingLeft: '10%',
  paddingRight: '10%'
}))

const CVWizardHolder = ({ cvId, close, saveCv: setCv, ...props }) => {
  const stepsMenuItems = [
    {},
    {
      image: Step1Img,
      header: 'Foto',
      explanation: <>Das Bewerbungsfoto ist natürlich ein zentraler Bestandteil deines Lebenslaufs. Daran sollte man nicht sparen...</>,
      tip: (
        <>
          Wir haben für dich ein paar Tipps gesammelt.
          <br />
          <PrimaryButton
            secondary
            scale={0.8}
            style={{ marginTop: 12 }}
            onClick={() => openWindow('notification', {
              maxWidth: 600,
              header: 'Tipps für\'s Bewerbungsfoto',
              text: close => (
                <>
                  {
                    DomainData.applicationPictureTips.map(({ header, tip }, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <p><b>{header}</b></p>
                          <p>{tip}</p>
                        </React.Fragment>
                      )
                    })
                  }
                </>
              )
            })}
          >
            Tipps öffnen
          </PrimaryButton>
        </>
      )
    },
    {
      image: Step2Img,
      header: 'Deine Person',
      explanation: <>Dieser Teil ist über deine Person. Hier musst du auf den Punkt bringen, welche Qualifikationen du mitbringst, wo du gerade stehst und durchblicken lassen, warum du für das Fachgebiet brennst, für das du dich bewirbst.</>,
      tip: 'Beschreibe nicht deinen ganzen Lebenslauf sondern erwähne die Kerneckpunkte deiner bisher erreichten Qualifikationen.'
    },
    {
      image: Step3Img,
      header: 'Deine Motivation',
      explanation: <>In diesem Fall sollst du formulieren, warum du gerade bei DEM Arbeitgeber arbeiten möchtest.</>,
      tip: 'Hier kannst du mal etwas recherchieren. Aber wir haben ein paar Tipps, was du hier schreiben kannst.'
    },
    {
      image: Step4Img,
      header: 'Übersicht',
      explanation: <>Hier kannst du den Text deines Anschreibens bearbeiten und als PDF herunterladen!</>,
      tip: (
        <>
          Du kannst nach dem Generieren das Anschreiben später noch anpassen.
        </>
      )
    }
  ]

  const steps = [
    null,
    props => <Photo {...props} />
  ]

  const stepNames = [
    null,
    'photo',
    'personal',
    'motivation',
    'details'
  ]

  const [step, setStep] = useState(1)
  const [cvData, setCvData] = useState({
    ...stepNames.slice(1).reduce((acc, curr) => (acc[curr] = {}, acc), {})
  })
  const { data: applicationData } = useContext(ApplicationDataContext)
  const { auth, api } = useContext(AuthContext)
  const { openWindow } = useContext(WindowManagerContext)
  const scroller = React.useRef(null)
  const isMount = useIsMount()

  const nextStep = async () => {
    if (step < steps.length - 1) {
      setStep(step + 1)
    } else {
      // await generatePDF();
      // setCoverLetter(coverLetterData.id);
    }
  }

  const previousStep = () => {
    if (step > 1) setStep(step - 1)
  };

  const createOrLoadCV = () => {
    if (cvId) {
      api(jwt => getCVById(cvId, jwt))
        .then(cv => { setCvData(old => cv) })
        .catch(err => { alert('Leider konnte das Anschreiben nicht erstellt werden'); console.log(err) })
    } else {
      if (auth?.loggedIn && applicationData.id && !cvData.id) {
        console.log('cv wizard create VORHER:', cvData)
        api(jwt => createNewCV({ applicationId: applicationData.id }, jwt))
          .then(cv => {
            console.log('cv wizard create NACHHER:', { ...cv, ...cvData })
            setCvData(old => ({ ...stepNames.slice(1).reduce((acc, curr) => (acc[curr] = {}, acc), {}), ...cv, ...old }))
          })
          .catch(err => { alert('Leider konnte das Anschreiben nicht erstellt werden'); console.log(err) })
      } else if (!auth?.loggedIn) {
        setCvData(old => ({ ...old, ...stepNames.slice(1).reduce((acc, curr) => (acc[curr] = {}, acc), {}) }))
      }
    }
  }

  useEffect(() => {
    if (isMount) return
    // createOrLoadCV();
  }, [auth, applicationData.id])

  useEffect(() => {
    console.log('hallo?')
    // createOrLoadCV();
  }, [])

  React.useEffect(() => {
    if (scroller.current) scroller.current.scrollIntoView()
  }, [step])

  /* const generatePDF = async (data) => {
    // const data = generateData(coverLetterData);
    try {
      if (auth?.loggedIn) {
        const { fileSpecs } = await api(jwt => generateCoverLetter(data, jwt));
        const file = new File(fileSpecs);
        const fileAsBlob = await api(jwt => file.downloadBlob(jwt, () => {}));
        updateAttribute('fileId', fileSpecs.id);
        return { fileSpecs, file: fileAsBlob };
      } else {
        openWindow('notification', {
          header: 'Login erforderlich',
          maxWidth: 400,
          text: close => {
            return (
              <>
                <TeaserContent close={close} />
              </>
            );
          }
        });
      }
    } catch (error) {
      alert('Leider hat das Generieren nicht geklappt. Bitte probiere es nochmal');
      return null;
    }
  }; */

  const saveCvData = () => {
    /* if (cvData.id) {
      api(jwt => updateCV(cvData, jwt));
    } */
  }

  useEffect(() => {
    // TODO: bessere Speichervariante! => für den Moment OK
    if (auth?.loggedIn) saveCvData()
  }, [step, cvData.fileId])

  const updateAttribute = (type, data) => {
    setCvData(old => ({ ...old, [type]: data }))
  };

  const wizardProps = {
    cvData,
    saveData: data => updateAttribute([stepNames[step]], data),
    previousStep,
    nextStep,
    goto: stepNames[step] === 'details' ? stepName => stepNames.indexOf(stepName) > -1 ? setStep(stepNames.indexOf(stepName)) : () => {} : null,
    close,
    setCv
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div ref={scroller} />
      <Grid container spacing={1}>
        <MenuGrid item xs={12} sm={12} md={3} lg={3} xl={2} style={{ background: 'white' }}>
          <ApplicationWizardMenu
            stepNr={step}
            {...stepsMenuItems[step]}
          />
        </MenuGrid>
        <MainGrid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ background: 'grey.dark' }}>
          {
            /* (auth?.loggedIn && cvData.id) || (!auth?.loggedIn && cvData[stepNames[1]]) */
            true
              ? (
                <Root>
                  {steps[step]({
                    ...wizardProps
                  })}
                </Root>
                )
              : <CircularProgress />
            }
        </MainGrid>
      </Grid>
    </Box>
  )
};

export default CVWizardHolder
