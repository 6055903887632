import React, { createContext, useEffect } from 'react';

const WindowManagerContext = createContext();

const WindowManager = (props) => {
  const [windows, setWindows] = React.useState({});
  const [currentZIndex, setCurrentZIndex] = React.useState(2500);

  const openWindow = (name, open = true, props = { }) => {
    if (open) {
      const newZIndex = currentZIndex + 2;
      setWindows(old => ({
        ...old,
        [name]: { onClose: () => {}, zIndex: newZIndex, open, props }
      }));
      setCurrentZIndex(old => newZIndex);
    } else {
      if (windows[name]?.props?.onClose) windows[name]?.props?.onClose();
      setWindows(old => ({
        ...old,
        [name]: { zIndex: null, open, props }
      }));
      setCurrentZIndex(old => old - 2);
    }
  };

  useEffect(() => {
    if (currentZIndex > 2600) setCurrentZIndex(2500);
  }, [currentZIndex]);

  return (
    <WindowManagerContext.Provider value={{ windows, openWindow: (name, props) => openWindow(name, true, props), closeWindow: (name) => openWindow(name, false), windowBackgroundZIndex: currentZIndex - 1 }}>
      {props.children}
    </WindowManagerContext.Provider>
  );
};

export { WindowManager, WindowManagerContext }
;
