import { TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.secondary.light
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 2,
    '& fieldset': {
      borderColor: theme.palette.grey.lightest
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey.lighter
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.light
    }
  }
}));

const InputField = ({ id = Math.random() * 100 + '', label = '...', variant = 'outlined', ...props }) => {
  return (
    <CssTextField id={id} label={label} variant={variant} {...props} />
  );
};

export default InputField;
