import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AuthContext } from '../../context/Auth';
import LoadingIcon from '../../assets/img/loading_transp.gif';
import PrimaryButton from '../buttons/PrimaryButton';
import { createFileInDatabase, receiveUploadURL } from '../../api/methods';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import IconWithText from '../misc/IconWithText';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const Upload = (props) => {
  const [myFiles, setMyFiles] = useState([]);
  const [fileSize, setFileSize] = useState();
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successfullyUploaded, setSuccessfullyUploaded] = useState([]);
  const { auth, api } = useContext(AuthContext);
  const [type, setType] = useState(props.type);

  useEffect(() => {
    console.log(props.type);
    setType(props.type);
  }, [props.type]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 0) {
      alert(`Diese Datei konnte nicht hochgeladen werden.${props.allowed ? ' Folgende Formate sind erlaubt: ' + props.allowed.join(', ') : ''}`);
      return;
    }
    if (props.allowed) {
      console.log(acceptedFiles, props.allowed);
      let allowed = false;
      acceptedFiles.forEach(file => {
        props.allowed.forEach(a => { if (file.path.indexOf(a) > -1) allowed = true; });
      });
      if (!allowed) { alert('Leider sind nur Dateien mit folgenden Formaten erlaubt: ' + props.allowed.join(', ')); return; }
    }
    setMyFiles([...myFiles, ...acceptedFiles]);
    if (props.maxFiles === 1) {
      uploadFiles([...myFiles, ...acceptedFiles]);
    }
  }, [myFiles]);

  const dropZoneParameters = {};
  if (props.allowed) dropZoneParameters.accept = props.allowed.join(',');

  const { getRootProps, getInputProps } = useDropzone({
    ...dropZoneParameters,
    onDrop
  });

  const uploadFile = async (file, fileType, description) => {
    const { uploadURL, name: fileName } = await getUploadUrl(file);
    try {
      await axios.put(uploadURL, file, { onUploadProgress });
      const fileInDatabase = await api(jwt => createFileInDatabase({
        type: fileType, description, fileName, mimeType: file.type
      }, jwt));
      props.onUploadedFile(fileInDatabase);
    } catch (error) {
      console.log(error);
      alert('Der Upload von ' + file.path + ' ist fehlgeschlagen');
    }
  };

  const calculateFileSize = (files) => {
    let overallSize = 0;
    files.forEach(file => {
      overallSize += file.size;
    });
    return overallSize;
  };

  const onUploadProgress = (progressEvent) => {
    setUploadProgress(progressEvent.loaded);
  };

  const getUploadUrl = async (file) => {
    setLoading(true);
    try {
      // also receives key
      const result = await api(jwt => receiveUploadURL({ fileName: file.path, mimeType: file.type }, jwt));
      return result;
    } catch (error) {
      alert(error.message);
    }
  };

  const uploadFiles = async (files, fileType = type, name = props.name) => {
    setUploadProgress(0);
    setFileSize(calculateFileSize(files));
    setLoading(true);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      try {
        console.log('uploading', fileType, type);
        await uploadFile(file, fileType, '');
        setSuccessfullyUploaded(old => [...old, file.path]);
        setMyFiles(old => old.filter(f => f.path !== file.path));
      } catch (error) {
        alert(`Die Datei mit dem Namen '${file.path}' konnte nicht hochgeladen werden.`);
      }
    }
    setLoading(false);
  };

  const removeFile = file => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  // https://react-dropzone.js.org/#section-basic-example

  return (
    <>
      <p><b>{myFiles.length === 0 ? 'Datei(en) auswählen:' : 'Weitere Dateien auswählen'}</b></p>
      <i>{props.allowed ? `Es werden nur Dateien folgenden Formates akzeptiert: ${props.allowed.join(', ')}` : ''}</i>
      <br />
      <div {...getRootProps({ style: { ...baseStyle } })}>
        <input {...getInputProps()} />
        {/* <div style={{props.componentStyle}} */}
        {
          (myFiles.length < props.maxFiles)
            ? props.children
                ? <>{props.children({ files: myFiles })}</>
                : <p>{myFiles.length === 0 ? 'Hier kannst du die Dateien auswählen' : 'Hier kannst du weitere Dateien auswählen'} (auch per Drag 'n' Drop)</p>
            : null
        }
      </div>

      {
        myFiles.length > 0 &&
          <>
            <div style={{ marginTop: 30, height: 1, width: '100%' }} />
            <p><b>Zum Hochladen:</b></p>
          </>
      }
      {
        loading
          ? (
            <div style={{ width: '100%' }}>
              <center><img src={LoadingIcon} width='50' style={{ margin: '50px auto' }} /></center>
              <center><p>{Math.round(uploadProgress / fileSize * 10000) / 100} %</p></center>
            </div>
            )
          : (
            <>
              {props.uploadItem({ files: myFiles, multi: props.maxFiles > 1, removeFile })}
              {/* {myFiles.length > 1 && <a style={{ color: 'red', cursor: 'pointer', textDecoration: 'underline', float: 'right' }} onClick={removeAll}>Alle entfernen</a>} */}
              <div style={{ marginTop: 20, height: 1, width: '100%' }} />
              {(myFiles.length > 0 && props.maxFiles > 1) && <div style={{ maxWidth: 350, float: 'right' }}><PrimaryButton onClick={() => { uploadFiles([...myFiles], props.name, type); }}>{myFiles.length > 1 ? 'Alle hochladen' : 'Hochladen'}</PrimaryButton></div>}
              <br />
              <br />
              <br />
            </>
            )
      } {
        (successfullyUploaded.length > 0 && myFiles.length === 0) && (
          <IconWithText
            icon={<CheckIcon color='success' />}
            text={<p style={{ color: 'green' }}>Der Upload war erfolgreich</p>}
          />
        )
      }
    </>
  );
}
;

export default Upload;
