import axios from 'axios';
import React from 'react';
import File from '../classes/File';
import { CREATE_RATING, CREATE_TRACKING, CREATE_USER_PROFILE, GET_AUTH_INFO, CREATE_NEW_APPLICATION, LOGIN, SIGNUP, UPDATE_USER_DATA, RECEIVE_UPLOAD_URL, CREATE_FILE_IN_DATABASE, GET_FILES, RECEIVE_DOWNLOAD_URLS, GET_APPLICATION_BY_ID, UPDATE_APPLICATION, GENERATE_COVER_LETTER, CREATE_NEW_COVER_LETTER, GET_COVER_LETTER_BY_ID, UPDATE_COVER_LETTER, GET_ALL_COVER_LETTERS, GET_ALL_APPLICATIONS, DELETE_FILE, SEND_APPLICATION, GET_COVER_LETTER_BY_FILE_ID, GET_CREDIT_TRANSACTIONS, CREATE_DEPOSIT, DECREASE_CREDITS, GET_CV_BY_ID, UPDATE_CV, CREATE_NEW_CV, CHECK_IF_USER_EXISTS, CREATE_ARZTBRIEF_USER_FROM_XASS, GET_ARZTBRIEF_USER_INFO, GENERATE_ARZTBRIEF, CREATE_FEEDBACK, GENERATE_ARZTBRIEF_WITHOUT_AUTH, CREATE_QUEUE_ITEM, GET_ALL_QUEUED_ITEMS, GET_QUEUED_ITEM_BY_ID, SAVE_NEW_ARZTBRIEF_RESULT } from './endpoints';

const getAuthenticatedHeaders = jwt => {
  return {
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  };
};

function wait (seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

export const signup = async (signupData) => {
  if (!signupData.firstName) throw new Error('Bitte gib deinen Vornamen bei der Registrierung an.');
  if (!signupData.lastName) throw new Error('Bitte gib deinen Nachnamen bei der Registrierung an.');
  if (!signupData.email) throw new Error('Bitte gib deine E-Mail-Adresse bei der Registrierung an.');
  if (!signupData.password) throw new Error('Bitte gib dein Passwort bei der Registrierung an.');
  if (signupData.passwordRepeat !== signupData.password) throw new Error('Die von dir angegebenen Passwörter stimmen nicht überein.');
  signupData.metaData = { expertiseArea: signupData.expertiseArea };
  await axios.post(SIGNUP, signupData);
  return true;
}
;

export const createArztbriefUserFromXass = async (email) => {
  await axios.post(CREATE_ARZTBRIEF_USER_FROM_XASS, { email });
  return true;
};

export const createFeedback = async (data, jwt) => {
  await axios.post(CREATE_FEEDBACK, { ...data }, getAuthenticatedHeaders(jwt));
  return true;
}
;
export const generateArztbrief = async (data, jwt) => {
  console.log(data);
  if (process.env.NODE_ENV === 'development') {
    if (data.anamnese.includes('Dysarthrie')) {
      await wait(15);
      return 'Der Patient stellte sich gegen 11:15 Uhr mit einer plötzlichen Lähmung des rechten Armes, einer motorischen Aphasie, einer Dysarthrie sowie einer Hypästhesie des rechten Armes und der rechten Gesichtshälfte in unserem Hause vor. Bei Aufnahme in die Notaufnahme waren die Symptome bereits teilweise rückläufig.\n\nIm Verlauf führten wir eine kranielle Computertomographie (CCT) und eine CT-Angiographie durch, welche jedoch keine pathologischen Befunde zeigten. Anschließend erfolgte eine Lysetherapie mit 55,5 mg rekombinanter Gewebeplasminogenaktivator (rtPA). Ein weiteres kraniales MRT zeigte eine kleine bandförmige Diffusionsstörung in der linken Hemisphäre. Bei einer anschließenden Duplexsonographie konnten ebenfalls keine pathologischen Befunde festgestellt werden.';
    } else {
      await wait(12);
      return 'Der Patient stellte sich in unserer Zentralen Notaufnahme aufgrund von seit drei Wochen anhaltenden, drückenden Kopfschmerzen, die den gesamten Kopfbereich betrafen, sowie begleitender Übelkeit vor. Die bisher eingenommenen Medikamente zeigten leider keine Wirkung. Des Weiteren klagte der Patient seit einer Woche über ein Kribbeln in seinem linken Bein und rechten Arm. Eine kürzlich stattgefundene Reise ins Ausland oder ein Zeckenbiss wurden von ihm verneint.';
    }
  }
  const request = await axios.post(GENERATE_ARZTBRIEF, { ...data }, { ...getAuthenticatedHeaders(jwt), timeout: 300000 });
  return request.data;
}
;

export const generateArztbriefWithoutAuth = async (data, jwt) => {
  console.log(data);
  if (process.env.NODE_ENV === 'development') {
    return 'Hier sollte dann jetzt der Arztbrief stehen';
  }
  const request = await axios.post(GENERATE_ARZTBRIEF_WITHOUT_AUTH, { ...data }, { timeout: 300000 });
  return request.data;
}
;

export const createArztbriefQueueItem = async (data, jwt) => {
  const request = await axios.post(CREATE_QUEUE_ITEM, { ...data }, { ...getAuthenticatedHeaders(jwt), timeout: 300000 });
  return request.data;
}
;

export const saveNewArztbriefResult = async (taskId, result, jwt) => {
  const request = await axios.post(SAVE_NEW_ARZTBRIEF_RESULT, { taskId, result }, { ...getAuthenticatedHeaders(jwt), timeout: 300000 });
  return request.data;
}
;

export const getAllQueuedItems = async (jwt) => {
  const request = await axios.get(GET_ALL_QUEUED_ITEMS, { ...getAuthenticatedHeaders(jwt), timeout: 300000 });
  return request.data;
}
;

export const getQueuedItemById = async (taskId, jwt) => {
  const request = await axios.get(GET_QUEUED_ITEM_BY_ID, {
    data: { taskId },
    params: { taskId },
    ...getAuthenticatedHeaders(jwt)
  });
  return request.data;
}
;

export const createNewApplication = async (applicationData, jwt) => {
  if (!applicationData.applicationCause) throw new Error('Bitte gib deinen den Anlass der Bewerbung an.');
  if (!applicationData.jobRole) throw new Error('Bitte gib die Job-Rolle an.');
  if (!applicationData.expertiseArea) throw new Error('Bitte gib ein Fachgebiet an.');
  if (!applicationData.subject) throw new Error('Bitte gib den Betreff an.');
  const request = await axios.post(CREATE_NEW_APPLICATION, applicationData, getAuthenticatedHeaders(jwt));
  return request.data;
};

export const login = async (email, password) => {
  if (!email || !password) alert('Gib bitte die E-Mail-Adresse und das Passwort zum Einloggen ein.');

  const request = await axios.post(LOGIN, { email, password });
  return request.data;
}
;

export const createTracking = async (trackingData) => {
  const request = await axios.post(CREATE_TRACKING, trackingData);
  return request.data;
}
;

export const getAuthInfo = async (jwt) => {
  const request = await axios.get(GET_ARZTBRIEF_USER_INFO, getAuthenticatedHeaders(jwt));
  console.log({ ...request.data, firstName: request.data.first_name, lastName: request.data.last_name });
  return { ...request.data, firstName: request.data.first_name, lastName: request.data.last_name };
}
;

export const createUserProfile = async (data, jwt) => {
  const request = await axios.post(CREATE_USER_PROFILE, data, getAuthenticatedHeaders(jwt));
  return request.data;
}
;

export const updateUserData = async (data, jwt) => {
  const request = await axios.post(UPDATE_USER_DATA, data, getAuthenticatedHeaders(jwt));
  return request.data;
}
;

export const receiveUploadURL = async (data, jwt) => {
  const request = await axios.get(RECEIVE_UPLOAD_URL, {
    data: data,
    params: data,
    ...getAuthenticatedHeaders(jwt)
  });
  return request.data;
}
;

export const receiveDownloadURLs = async (files, jwt, progressTracker) => {
  const request = await axios.get(RECEIVE_DOWNLOAD_URLS, {
    data: { files },
    params: { files },
    ...getAuthenticatedHeaders(jwt)
  });
  return request.data;
}
;

export const createFileInDatabase = async (file, jwt) => {
  const fileInfo = await axios.post(CREATE_FILE_IN_DATABASE, file, getAuthenticatedHeaders(jwt));
  return new File(fileInfo.data);
};

export const sendApplication = async (applicationData, jwt) => {
  const applicationSent = await axios.post(SEND_APPLICATION, applicationData, getAuthenticatedHeaders(jwt));
  return applicationSent;
};

export const getFiles = async (filter, jwt) => {
  const files = await axios.get(GET_FILES, {
    data: filter,
    params: filter,
    ...getAuthenticatedHeaders(jwt)
  });
  return files.data.map(f => new File(f));
};

export const getCreditTransactions = async (jwt) => {
  const { data: transactions } = await axios.get(GET_CREDIT_TRANSACTIONS, getAuthenticatedHeaders(jwt));
  return transactions.map(data => ({ credits: data.credits, paymentType: data.payment_type, paymentDetails: data.payment_details, createdAt: data.createdAt || data.created_at, updatedAt: data.updatedAt || data.updated_at, price: data.price }));
}
;

export const updateApplication = async (data, jwt) => {
  const savedApplication = await axios.post(UPDATE_APPLICATION, data, getAuthenticatedHeaders(jwt));
  return savedApplication.data;
};

export const deleteFile = async (fileId, jwt) => {
  await axios.post(DELETE_FILE, { fileId }, getAuthenticatedHeaders(jwt));
};

const convertApplication = application => {
  return {
    applicationCause: application.application_cause,
    domainSpecificInformation: application.domain_specific_information,
    employerData: application.employer_information,
    contactPerson: application.contact_person,
    personalData: application.personal_data,
    sendingInformation: application.sending_information,
    coverLetterId: application.cover_letter_id,
    cvId: application.cv_id,
    jobRole: application.job_role,
    id: application.id,
    files: (application.files || []).map(f => new File(f)),
    subject: application.subject,
    status: application.status,
    updatedAt: application.updatedAt,
    createdAt: application.createdAt
  };
};

export const getApplication = async (id, jwt) => {
  const application = await axios.get(GET_APPLICATION_BY_ID, {
    data: { id },
    params: { id },
    ...getAuthenticatedHeaders(jwt)
  });
  return convertApplication(application.data);
  /* {
    jobRole: 'Weiterbildungsassistent für die Anästhesie',
    applicationCause: 'ownInitiative',
    employerData: {
      name: 'Felix Medicus Klinik',
      address: {
        street: 'Rudelgasse 4',
        postalCode: '50667',
        city: 'Köln',
        country: 'Deutschland'
      }
    },
    contactPerson: {
      salutation: 'Sehr geehrter Herr',
      title: 'Dr.',
      firstName: 'Manuel',
      lastName: 'Peters',
      email: 'peters@felixmedicus.de'
    },
    applicantData: {
      title: 'Dr.',
      firstName: 'Thorben',
      lastName: 'Schlätzer',
      address: {
        street: 'Grafenwerthstr. 5',
        postalCode: '50937',
        city: 'Köln',
        country: 'Deutschland'
      }
    },
    coverLetterId: 1
  }; */
}
;

export const generateCoverLetter = async (data, jwt) => {
  const pdf = await axios.post(GENERATE_COVER_LETTER, data, getAuthenticatedHeaders(jwt));
  return pdf.data;
};

export const createNewCoverLetter = async (data, jwt) => {
  const newCoverLetter = await axios.post(CREATE_NEW_COVER_LETTER, data, getAuthenticatedHeaders(jwt));
  return newCoverLetter.data;
};

export const updateCoverLetter = async (data, jwt) => {
  const newCoverLetter = await axios.post(UPDATE_COVER_LETTER, data, getAuthenticatedHeaders(jwt));
  return newCoverLetter.data;
};

export const createNewCV = async (data, jwt) => {
  const newCoverLetter = await axios.post(CREATE_NEW_CV, data, getAuthenticatedHeaders(jwt));
  return newCoverLetter.data;
};

export const updateCV = async (data, jwt) => {
  const newCoverLetter = await axios.post(UPDATE_CV, data, getAuthenticatedHeaders(jwt));
  return newCoverLetter.data;
};

export const getCVById = async (id, jwt) => {
  const { data: cvData } = await axios.get(GET_CV_BY_ID, {
    data: { id },
    params: { id },
    ...getAuthenticatedHeaders(jwt)
  });
  const coverLetter = await convertToCoverLetterObject(cvData, jwt);
  return coverLetter;
};

export const createDeposit = async (data, jwt) => {
  await axios.post(CREATE_DEPOSIT, data, getAuthenticatedHeaders(jwt));
};

export const decreaseCredits = async (creditsToDecrease, jwt) => {
  await axios.post(DECREASE_CREDITS, { credits: creditsToDecrease }, getAuthenticatedHeaders(jwt));
};

export const getAllCoverLetters = async (jwt) => {
  const coverLetters = await axios.get(GET_ALL_COVER_LETTERS, getAuthenticatedHeaders(jwt));
  return coverLetters.data;
};

export const getAllApplications = async (jwt) => {
  const coverLetters = await axios.get(GET_ALL_APPLICATIONS, getAuthenticatedHeaders(jwt));
  return coverLetters.data.map(a => convertApplication(a));
};

export const getCoverLetterById = async (id, jwt) => {
  const { data: coverLetterData } = await axios.get(GET_COVER_LETTER_BY_ID, {
    data: { id },
    params: { id },
    ...getAuthenticatedHeaders(jwt)
  });
  const coverLetter = await convertToCoverLetterObject(coverLetterData, jwt);
  return coverLetter;
};

export const getCoverLetterByFileId = async (id, jwt) => {
  const { data: coverLetterData } = await axios.get(GET_COVER_LETTER_BY_FILE_ID, {
    data: { id },
    params: { id },
    ...getAuthenticatedHeaders(jwt)
  });
  const coverLetter = await convertToCoverLetterObject(coverLetterData, jwt);
  return coverLetter;
};

export const checkIfUserExists = async email => {
  const { data: { xassExists, arztbriefExists } } = await axios.get(CHECK_IF_USER_EXISTS, {
    data: { email },
    params: { email }
  });
  return { xassExists, arztbriefExists };
};

const convertToCoverLetterObject = async (coverLetterData, jwt) => {
  let signatureImage = null;
  if (coverLetterData.signature_image_id) signatureImage = (await getFiles({ ids: [coverLetterData.signature_image_id] }, jwt))[0];
  const coverLetter = {
    id: coverLetterData.id,
    cause: coverLetterData.cause,
    personal: coverLetterData.personal,
    motivation: coverLetterData.motivation,
    date: {
      text: coverLetterData.date
    },
    salutation: {
      text: coverLetterData.salutation
    },
    subject: {
      text: coverLetterData.subject
    },
    hospitationWish: {
      value: coverLetterData.hospitation_wish
    },
    endOfCoverLetter: {
      text: coverLetterData.end_of_cover_letter
    },
    adoption: {
      text: coverLetterData.adoption
    },
    signatureImage: {
      image: signatureImage
    },
    applicantName: {
      text: coverLetterData.applicant_name
    },
    applicantAddress: {
      text: coverLetterData.applicant_address
    },
    employerAddress: {
      text: coverLetterData.employer_address
    }
  };
  return coverLetter;
};
