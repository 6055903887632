import React, { useContext } from 'react';
import TextModuleBox from '../TextModuleBox';
import InputField from '../../forms/InputField';
import MedData from '../../../assets/datasets/MedData';
import CoverLetterWizardFooter from '../CoverLetterWizardFooter';
import { ApplicationDataContext } from '../../../context/ApplicationData';
import { Grid, useMediaQuery } from '@mui/material';
import SelectField from '../../forms/SelectField';
import PrimaryButton from '../../buttons/PrimaryButton';
import { WindowManagerContext } from '../../../context/WindowManager';

const variants = MedData.textModules.personal;

const TextModuleButton = props => {
  return (
    <div
      style={{
        border: '1px solid rgba(0,0,0,0.2)',
        padding: 8,
        paddingLeft: 12,
        paddingRight: 12,
        width: 'fit-content',
        blockSize: 'fit-content',
        cursor: 'pointer',
        float: 'left',
        margin: 4,
        ...props.style
      }}
      onClick={props.onClick}
    >
      <span style={{ fontSize: 12 }}>{props.children}</span>
    </div>
  );
};

const Personal = ({ coverLetterData, previousStep, nextStep, saveData, ...props }) => {
  /*  const [textModules, setTextModules] = React.useState({});
  const [checked, setChecked] = React.useState(null); */
  const isMobile = useMediaQuery('(max-width:600px)');
  const [text, setText] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState(null);

  const { data: applicationData } = useContext(ApplicationDataContext);
  const { openWindow } = useContext(WindowManagerContext);
  const { applicationCause: applicationType } = applicationData;
  const { personal: relevantCoverLetterData } = coverLetterData;

  const ChangeDataInput = ({ close, originalText, header }) => {
    const [value, setValue] = React.useState(originalText);
    return (
      <>
        <p>Hier kannst du den Text noch ändern:</p>
        <Grid container>
          <Grid item xs={12} xl={12}>
            <InputField
              multiline
              fullWidth
              rows={6}
              label={header}
              onChange={(e) => setValue(e.currentTarget?.value || '')}
              value={value}
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <div style={{ maxWidth: 200, float: 'right' }}>
              <PrimaryButton onClick={() => { setText(old => old + (old !== '' ? ' ' : '') + value); close(); }}>Speichern</PrimaryButton>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  /*  React.useEffect(() => {
    // Wenn ein Feld gecheckt wird, ändert sich der Text für den Teil des Anschreibens
    if (checked !== null) {
      saveData({ textModules, checked, text: textModules[checked] });
    } else {
      saveData({ });
    }
  }, [checked, textModules[checked]]); */

  React.useEffect(() => {
    /* if (relevantCoverLetterData?.textModules) setTextModules(old => ({ ...old, ...relevantCoverLetterData.textModules }));
    if (relevantCoverLetterData?.checked !== null) setChecked(old => relevantCoverLetterData?.checked); */
    if (relevantCoverLetterData?.text) setText(relevantCoverLetterData.text);
  }, []);

  /* const setPartText = (idx, text) => {
    setTextModules(old => ({ ...old, [idx]: text }));
  }; */

  React.useEffect(() => {
    saveData({ text });
  }, [text]);

  const goToNextStep = () => {
    if (text !== '') {
      nextStep();
    } else {
      alert('Bitte wähle einen der Textbausteine aus.');
    }
  };

  const editTextFunction = (header, originalText) => {
    openWindow('notification', {
      minWidth: Math.min(450, window.innerWidth - 0.1 * window.innerWidth),
      header,
      text: close => <ChangeDataInput {...{ header, originalText, close }} />
    });
  };

  return (
    <>
      {
        isMobile
          ? <h3>Wähle aus der Liste einen passenden Baustein und klicke auf den Button, um ihn anzuhängen</h3>
          : <h3>Klicke auf die Bausteine, bearbeite sie und ergänze deinen Text zu deiner Person</h3>
      }

      {
        !isMobile
          ? variants.map((cb, idx) => {
              return (
                <TextModuleButton
                  key={idx}
                  style={{
                    backgroundColor: 'white'
                  }}
                  onClick={() => editTextFunction(cb.title, cb.text(applicationType))}
                >
                  {cb.title}
                </TextModuleButton>
              );
            })
          : (
            <>
              <div style={{ width: '100%', height: 12 }} />
              <SelectField
                fullWidth
                items={variants.map((v, idx) => ({ value: idx, label: v.title }))}
                label='Baustein'
                onChange={(e) => setSelectedItem(e.target.value)}
                value={selectedItem}
              />
              <div style={{ width: '100%', height: 12 }} />
              <PrimaryButton
                secondary
                onClick={() => setText(old => old + (old !== '' ? ' ' : '') + variants[selectedItem].text)}
                style={{ float: 'right' }}
              >
                Text einfügen
              </PrimaryButton>
            </>
            )
        }
      <InputField
        label='Hier kommt die Beschreibung deiner Person hin'
        multiline
        fullWidth
        rows={12}
        onChange={(e) => setText(old => e?.currentTarget?.value || '')}
        value={text}
        style={{ marginBottom: 0, marginTop: 32, marginLeft: 0 }}
      />

      {/* {
        variants.map((cb, idx) => {
          return (
            <TextModuleBox
              headline={cb.title}
              key={'tmb' + idx}
              checked={checked === idx}
              saveData={() => { setChecked(idx); setPartText(idx, textModules[idx] || cb.text(applicationType || 'initiativ')); }}
            >
              <InputField
                label='Ändere gerne deinen Text'
                multiline
                fullWidth
                rows={6}
                onChange={(e) => setPartText(idx, e.currentTarget.value)}
                value={textModules[idx] || cb.text(applicationType || 'ownInitiative')}
                style={{ maxHeight: 200, padding: 10, marginBottom: 0 }}
              />
            </TextModuleBox>
          );
        })
      } */}
      <CoverLetterWizardFooter
        previous={previousStep}
        next={goToNextStep}
      />

    </>
  );
};

export default Personal;
