import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFDA18', // Felix Medicus
      light: '#ffe97f',
      dark: '#e0bb00',
      contrastText: '#000000'
    },
    secondary: {
      main: '#3281C2' // dark blue
    },
    third: {
      main: '#EF5DA8' // pink
    },
    grey: {
      dark: '#545454', // 'rgba(0, 0, 0, 0.67)',
      light: '#8A8A8A', // 'rgba(0, 0, 0, 0.46)',
      lighter: '#9E9E9E', // 'rgba(0, 0, 0, 0.38)',
      lightest: '#B3B3B3', // 'rgba(0, 0, 0, 0.3)',
      megaLight: '#F5F5F5'
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Work Sans', 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`
  }
});

export default theme;
