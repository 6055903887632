import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { AuthContext } from '../context/Auth';
import { WindowManagerContext } from '../context/WindowManager';
import PrimaryButton from '../components/buttons/PrimaryButton';
import LogoLang from '../assets/img/logo_beta.png';
import IconWithText from '../components/misc/IconWithText';
import { AddCircle, Login, Logout } from '@mui/icons-material';
import { Menu, MenuItem, useMediaQuery } from '@mui/material';
import WizardButton from '../components/buttons/WizardButton';
import helper from '../assets/helper';
import { AppDataContext } from '../context/AppData';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    marginBottom: 10,
    paddingLeft: '3%',
    paddingRight: '3%'
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    cursor: 'pointer'
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  signupButton: {
    marginLeft: theme.spacing(2),
    textTransform: 'none'
  },
  profileCircle: {
    marginLeft: theme.spacing(2),
    width: 40,
    height: 40,
    cursor: 'pointer'
  },
  largeMenu: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

function Header (props) {
  const classes = useStyles();
  const { auth = {}, user, logout } = useContext(AuthContext);
  const { openWindow } = useContext(WindowManagerContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { remainingArztbriefe } = useContext(AppDataContext);
  const isMobile = useMediaQuery('(max-width:600px)');
  const open = Boolean(anchorEl);
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <div className={classes.title} onClick={props.goHome}>
            <img src={LogoLang} height={55} alt='Logo' />
          </div>
          {
            !auth.loggedIn
              ? (
                <>
                  <p paragraph style={{ fontFamily: 'Work Sans', margin: 0 }}>noch {remainingArztbriefe} kostenlos</p>
                  <a style={{ cursor: 'pointer', color: 'black' }} onClick={() => openWindow('login')}>
                    <IconWithText
                      icon={<Login />}
                      text=''
                    />
                  </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <WizardButton scale={1.5} onClick={() => props.register()} style={{ display: isMobile ? 'none' : null }}>
                    Kostenlos starten
                  </WizardButton>
                </>
                )
              : (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                    {
                      user.beta
                        ? (
                          <><p style={{ fontFamily: 'Work Sans', margin: 0, fontSize: 'smaller' }}>noch {helper.getTimeDifferenceInDays(new Date(user.subscriptions.filter(s => s.type === 'beta')[0].valid_until))} Tage kostenlos</p></>
                          )
                        : (
                          <><p style={{ fontFamily: 'Work Sans', margin: 0 }}>{user.credits} Credits</p>&nbsp;<a style={{ cursor: 'pointer' }} onClick={() => openWindow('creditDeposit')}><AddCircle /></a></>
                          )
                    }
                    <Avatar alt='Profile' className={classes.profileCircle} onClick={handleAvatarClick}>
                      {user.firstName ? user.firstName[0] + user.lastName[0] : 'FM'}
                    </Avatar>
                  </div>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    <MenuItem onClick={() => { logout(); handleClose(); }}>Logout</MenuItem>
                  </Menu>
                </>
                )
          }

        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
