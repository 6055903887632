import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams, createSearchParams, Link } from 'react-router-dom';
import { TrackingContext } from '../context/Tracking';
import { AuthContext } from '../context/Auth';
import Box from '@mui/material/Box';
import { WindowManagerContext } from '../context/WindowManager';
import LoadingIcon from '../assets/img/loading_transp.gif';
import { styled, useTheme } from '@mui/material/styles';
import { CircularProgress, Grid, LinearProgress, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import TeaserContent from './misc/TeaserContent';
import Credits from './pages/Credits';
import CVDownloader from './misc/CVDownloader';
import FeedbackButton from './buttons/FeedbackButton';
import Header from '../misc/Header';
import ApplicationWizardMenu from './wizard/ApplicationWizardMenu';
import Step2Img from '../assets/img/step2_img.png';
import Step3Img from '../assets/img/step3_img.png';
import { checkIfUserExists, createArztbriefQueueItem, generateArztbrief, generateArztbriefWithoutAuth, getQueuedItemById, saveNewArztbriefResult } from '../api/methods';
import InputField from './forms/InputField';
import { Container } from '@mui/system';
import PrimaryButton from './buttons/PrimaryButton';
import WizardButton from './buttons/WizardButton';
import Logo from '../assets/img/logo_other_way_around.png';
import CheckField from './forms/CheckField';
import FeedbackComponent from './FeedbackComponent';
import InputPage from './pages/InputPage';
import ArztbriefView from './pages/ArztbriefView';
import ArztbriefOverview from './pages/ArztbriefOverview';
import helper from '../assets/helper';
import { v4 as uuidv4 } from 'uuid';
import { AppDataContext } from '../context/AppData';
import DomainData from '../assets/datasets/DomainData';

const MenuGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: 'grey.dark'
}));

const MainGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#F5F5F5',
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  },
  color: 'grey.dark'
}));

const Root = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: '7%',
    paddingTop: '4%'
  },
  [theme.breakpoints.down('md')]: {
    padding: '5%',
    paddingTop: '3%'
  },
  h2: {
    marginBottom: 5
  }
}));

const ApplicationHolder = props => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = React.useState({});
  const [windowOpen, setWindowOpen] = React.useState(false);
  const [currentState, setCurrentState] = React.useState('overview');
  const [arztbrief, setArztbrief] = React.useState('');
  const [currentItem, setCurrentItem] = React.useState();

  const { auth = {}, api, user, reloadUser } = useContext(AuthContext);
  const { openWindow, closeWindow } = useContext(WindowManagerContext);
  const { t, setSource } = React.useContext(TrackingContext);
  const { remainingArztbriefe, decreaseGeneratedArztbriefe, maxArztbriefe } = useContext(AppDataContext);

  useEffect(() => {
    if (currentState === 'overview' && auth?.ready && !auth?.loggedIn) setCurrentState('input');
  }, [currentState, auth]);

  const openLoadingWindow = () => {
    // calulate progress
    openWindow('notification', {
      header: '',
      text: close => (
        <>
          <center><img src={Logo} width={70} />
            <p style={{ color: 'grey' }}>Deine Anfrage wird verschickt...</p>
            <p style={{ color: 'grey' }}><CircularProgress size={40} /></p>
          </center>
        </>
      ),
      notClosable: true,
      maxWidth: 400
    });
  };

  const loadArztbriefFromQueue = (taskId) => {
    openWindow('loading', { text: 'Dein Arztbrief wird geladen... 🤗', notClosable: true });
    api(jwt => getQueuedItemById(taskId, jwt))
      .then((item) => { setCurrentItem(item); setArztbrief(item.result); })
      .catch((err) => { console.error(err); alert('Leider ist ein Fehler aufgetreten. Bitte nochmal probieren.'); setCurrentState('overview'); })
      .finally(() => closeWindow('loading'));
  };

  const saveNewResult = newResult => {
    openWindow('loading', { text: 'Dein Arztbrief wird gespeichert... 🤗' });
    api(jwt => saveNewArztbriefResult(currentItem.task_id, newResult))
      .then((newItem) => { setCurrentItem(newItem); })
      .catch((err) => { console.error(err); alert('Leider ist ein Fehler aufgetreten. Bitte nochmal probieren.'); })
      .finally(() => closeWindow('loading'));
  };

  const changeParams = newParams => {
    setSearchParams({
      ...newParams// Object.keys(params).length > 0 ? '?' + queryString.stringify(params) : ''
    });
  };

  const openPricingWindow = (props) => openWindow('notification', {
    header: '',
    maxWidth: '100%',
    minWidth: 300,
    text: close => {
      return (
        <>
          <center><img src={Logo} width={70} /></center>
          <h2>🏷️ Preise</h2>
          <p style={{ color: 'grey' }}>Wir haben keine Lust auf Intransparenz.<br />Du bezahlst nur für das, was du nutzt! 🚀</p>
          {
            remainingArztbriefe > 0 &&
              <>
                <p style={{ color: 'grey' }}>Du kannst noch {remainingArztbriefe} kostenlos generieren</p>
              </>
          }
          <h3>1 Credit = <b>{helper.formatCurrency(DomainData.PRICE_PER_CREDIT)}</b></h3>
          {remainingArztbriefe <= 0 && <p style={{ color: 'grey', marginTop: -10 }}>1 Credit = 1 Arztbrief</p>}
          <br />
          <center><WizardButton scale={2} onClick={() => openWindow('register')}>Jetzt durchstarten 🚀</WizardButton></center>
        </>
      );
    },
    ...props
  });

  React.useEffect(() => {
    if (searchParams.get('s')) {
      console.log('should create item');
      t('website_hit', '', searchParams.get('s'));
      setSource(searchParams.get('s'));
      changeParams({});
    }
  }, []);

  const openRegister = () => openWindow('notification', {
    header: 'Registrier\' dich',
    maxWidth: 400,
    text: close => (
      <>
        <TeaserContent close={close} />
      </>
    )
  });

  const openWindowToDepositCredit = data => {
    openWindow('notification', {
      maxWidth: 500,
      header: 'Credits aufladen 😊',
      notClosable: true,
      text: close => {
        return (
          <>
            <p>
              Du willst weiter generieren lassen statt selber zu schreiben? Wir verstehen das!<br />
              Die KI braucht aber mehr Ressourcen 🤖
            </p>
            <p>Lad' deine Credits auf und du kannst <b>weitermachen 💪</b></p>
            <PrimaryButton style={{ float: 'right' }} onClick={() => openWindow('creditDeposit', { onClose: () => { setCurrentState('input'); close(); } })}>Credits aufladen</PrimaryButton>
          </>
        );
      }
    });
  };

  const generateQueueItemObject = (anamnese, verlauf, currentItem, status = 'created') => {
    const taskId = currentItem?.task_id || uuidv4();
    if (currentItem && !anamnese && !verlauf) {
      return { ...currentItem, prompt: currentItem.original_prompt, status, taskId: currentItem.task_id };
    } else {
      const name = anamnese.replace('- ', '');
      const prompt = { anamnese, verlauf };
      return { taskId, name, prompt, status };
    }
  };

  const openErrorWindow = err => {
    console.log(err);
    openWindow('notification', {
      header: 'Fehler...',
      text: close => (
        <>
          <p>Leider gab es einen Fehler.</p>
          <p>Bitte probiere es zu einem späteren Zeitpunkt nochmal.</p>

          <Container style={{ marginRight: 0, width: 'fit-content', paddingTop: 22, paddingRight: 0 }}>
            <WizardButton scale={1.5} onClick={() => { close(); }}>OK</WizardButton>
          </Container>
        </>
      ),
      maxWidth: 400
    });
  };

  const generateArztbriefMethod = (anamnese = '', verlauf = '', wholeItem = currentItem) => {
    console.log(wholeItem);
    if (auth.loggedIn) {
      openLoadingWindow();
      if (user.credits <= 1) return () => openWindowToDepositCredit();
      return () => api(jwt => createArztbriefQueueItem(generateQueueItemObject(anamnese, verlauf, wholeItem), jwt)).then(resp => { setCurrentState('overview'); setArztbrief(''); reloadUser(); closeWindow('notification'); closeWindow('loading'); }).catch(openErrorWindow);
    } else if (remainingArztbriefe > 0) {
      return () => generateArztbriefWithoutAuth({ anamnese, verlauf }).then(resp => { setCurrentState('arztbrief'); decreaseGeneratedArztbriefe(); setArztbrief(resp); closeWindow('notification'); closeWindow('loading'); }).catch(openErrorWindow);
    } else {
      return () => openWindow('notification', {
        header: 'Login erforderlich',
        maxWidth: 400,
        text: close => {
          return (
            <>
              <TeaserContent close={close} hintText={'Du hast bereits ' + maxArztbriefe + ' Arztbriefe ohne Registrierung generiert. Jetzt bitten wir um deine Registierung 😊'} />
            </>
          );
        }
      });
    }
  };

  const description = {
    image: Step2Img,
    stepDescription: 'Aus Stichpunkten',
    header: 'Arztbrief-Vorschlag generieren!',
    explanation: <>Wir nutzen die spezifisch angepasste <span style={{ color: 'blue' }}>Felix Medicus KI</span>, um aus Stichpunkten zu deinen Patient:innen Arztbrief-reife Formulierungen zu bekommen.</>,
    tipDescription: 'Feedback?',
    tip: <>Wir freuen uns über Dein Feedback über den Button unten links.<br /><br /><a style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }} href='https://felixmedicus.de/datenschutz' rel='noopener noreferrer' target='_blank'>Datenschutzerklärung</a> {!auth?.loggedIn && <>| <a style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openPricingWindow()}>Preise</a></>}</>
  };

  if (auth?.ready) {
    return (
      <>
        <FeedbackButton style={{ bottom: 40, left: 40, position: 'fixed', zIndex: 10000 }} />
        <Header register={openRegister} goHome={() => { setCurrentState('overview'); }} />
        <Box sx={{ flexGrow: 1, paddingTop: '75px' }}>
          <Grid container spacing={1}>
            <MenuGrid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <ApplicationWizardMenu
                stepNr={1}
                {...description}
              />
            </MenuGrid>
            <MainGrid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Root>
                {
                  (currentState !== 'overview' && auth.loggedIn) &&
                  (
                    <>
                      <a style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }} onClick={() => { setCurrentState('overview'); }}>
                        Übersicht
                      </a>
                      &nbsp;&nbsp;/&nbsp;&nbsp;
                      {
                      currentState === 'input'
                        ? (
                          <>
                            {
                            currentItem
                              ? (
                                <>
                                  <a style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }} onClick={() => { setCurrentState('arztbrief'); }}>{helper.shortenText(currentItem.name)}</a>
                                &nbsp;&nbsp;/&nbsp;&nbsp;Notizen bearbeiten
                                </>
                                )
                              : 'Neuer Arztbrief'
                            }
                          </>
                          )
                        : (
                          <>
                            {
                            currentItem
                              ? helper.shortenText(currentItem.name)
                              : 'Arztbrief ansehen'
                            }
                          </>
                          )
                      }
                      <br />
                      <br />
                    </>
                  )
                }
                {
                  currentState === 'arztbrief'
                    ? (
                      <ArztbriefView
                        arztbrief={arztbrief}
                        setArztbrief={setArztbrief}
                        currentItem={currentItem}
                        changeState={setCurrentState}
                        saveNewResult={saveNewResult}
                      />
                      )
                    : currentState === 'input'
                      ? (
                        <>
                          <InputPage
                            setArztbrief={setArztbrief}
                            openLoadingWindow={openLoadingWindow}
                            changeState={setCurrentState}
                            /** Only use the current item if authenticated!! */
                            currentItem={auth?.loggedIn ? currentItem : null}
                            openWindowToDepositCredit={openWindowToDepositCredit}
                            generateArztbriefMethod={generateArztbriefMethod}
                          />
                        </>
                        )
                      : (
                        <>
                          <ArztbriefOverview
                            changeState={setCurrentState}
                            loadArztbrief={(taskId) => { setCurrentState('arztbrief'); loadArztbriefFromQueue(taskId); }}
                            releaseCurrentItem={() => setCurrentItem(null)}
                            generateArztbriefMethod={generateArztbriefMethod}
                          />
                        </>
                        )
                }
              </Root>
            </MainGrid>
          </Grid>
        </Box>
      </>
    );
  } else {
    return <img src={LoadingIcon} width={60} />;
  }
  /* */
};

export default ApplicationHolder;
