import { Container } from '@mui/system';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../context/Auth';
import { WindowManagerContext } from '../../../context/WindowManager';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import PrimaryButton from '../../buttons/PrimaryButton';
import PhotoContainer from './PhotoContainer';
import axios from 'axios';
import { createFileInDatabase, receiveUploadURL } from '../../../api/methods';
import { CircularProgress } from '@mui/material';

const PhotoUploader = ({ onSet, onUploaded, defaultFile, ...props }) => {
  const [originalFileInfos, setOriginalFileInfos] = useState(defaultFile || {});
  const [originalFileBlob, setOriginalFileBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const { openWindow } = useContext(WindowManagerContext);
  const { api } = useContext(AuthContext);
  const [crop, setCrop] = useState(null);
  const [cutFile, setCutFile] = useState(null);
  const imgRef = useRef(null);

  const openUploadWindow = () => openWindow('upload', { type: 'Lebenslauf-Foto', addFile: file => setOriginalFileInfos(file) });

  useEffect(() => {
    if (originalFileInfos?.id) {
      setLoading('download');
      api(jwt => originalFileInfos.downloadBlob(jwt, () => {}))
        .then(blob => setOriginalFileBlob(blob))
        .catch(() => {
          setLoading(false);
          alert('Leider konnte das gewünschte Bild nicht genutzt werden. Stimmt die Dateiendung??');
        });
    }
  }, [originalFileInfos]);

  useEffect(() => {
    if (!originalFileInfos?.id) openUploadWindow();
  }, []);

  const uploadFile = async (file, fileType, description) => {
    const { uploadURL, name: fileName } = await api(jwt => receiveUploadURL({ fileName: file.path || file.name, mimeType: file.type }, jwt));
    try {
      await axios.put(uploadURL, file);
      const fileInDatabase = await api(jwt => createFileInDatabase({
        type: fileType, description, fileName, mimeType: file.type
      }, jwt));
      setLoading(false);
      return fileInDatabase;
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert('Der Upload von ' + file.path + ' ist fehlgeschlagen');
    }
  };

  const getCroppedImg = (image, pixelCrop, fileName) => {
    setLoading('upload');
    if (!imgRef?.current) return alert('Gerade nicht möglich - Versuch\' es gleich nochmal...');
    const canvas = document.createElement('canvas');

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    canvas.width = pixelCrop.width * scaleX;
    canvas.height = pixelCrop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      imgRef.current,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY
    );

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, 'image/jpeg');
    });
  };

  const saveCroppedImage = (originalFileBlob, crop) => {
    getCroppedImg(originalFileBlob, crop, 'lebenslauf_bild.jpg')
      .then(async (blob) => {
        const file = await uploadFile(blob, 'image/jpeg', '');
        onSet(file);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        alert('Das Bild konnte leider nicht erstellt werden. Probier\' es gleich nochmal.');
      });
  };

  useEffect(() => {
    if (originalFileBlob) {
      setCrop({
        unit: 'px',
        x: 0,
        y: 0,
        width: 40,
        height: 50
      });
      setCutFile(originalFileBlob);
    }
  }, [originalFileBlob]);

  return (
    <>
      {
        !originalFileBlob
          ? (
            <PhotoContainer
              sx={{ maxWidth: 300 }}
            >
              {loading === 'download' && <CircularProgress />}
            </PhotoContainer>
            )
          : cutFile &&
              (
                <center>
                  <ReactCrop
                    crop={crop}
                    onChange={c => setCrop(c)}
                    aspect={4 / 5}
                  >
                    <img ref={imgRef} src={URL.createObjectURL(originalFileBlob)} width='100%' style={{ maxWidth: 300 }} />
                  </ReactCrop>
                </center>
              )
      }

      <center>
        <div style={{ display: 'flex-inline' }}>
          <PrimaryButton
            style={{ margin: 6 }}
            onClick={() => openUploadWindow()}
          >
            {originalFileInfos?.id && 'Neues'} Foto hochladen
          </PrimaryButton>
          {
            originalFileInfos?.id &&
              <PrimaryButton
                secondary
                style={{ margin: 6 }}
                onClick={() => saveCroppedImage(originalFileBlob, crop)}
              >
                {loading === 'upload' && <CircularProgress size={16} />}&nbsp;Ausschnitt speichern
              </PrimaryButton>
          }
        </div>
      </center>
    </>
  );
};

export default PhotoUploader
;
