import React, { useContext, useState } from 'react';
import WindowWrapper from './WindowWrapper';
import LoadingIcon from '../assets/img/loading_transp.gif';
import { Grid } from '@mui/material';
import SelectField from '../components/forms/SelectField';
import IconWithText from '../components/misc/IconWithText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CorporateFare from '@mui/icons-material/CorporateFare';
import DomainData from '../assets/datasets/DomainData';
import InputField from '../components/forms/InputField';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { AuthContext } from '../context/Auth';

const EmployerDataWindow = ({ style, close, className, saveData, originalData, ...props }) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    salutation: originalData.contactPerson?.salutation,
    title: originalData.contactPerson?.title,
    firstName: originalData.contactPerson?.firstName,
    lastName: originalData.contactPerson?.lastName || '',
    email: originalData.contactPerson?.email || '',
    employerName: originalData.employerData?.name || '',
    employerStreet: originalData.employerData?.address?.street || '',
    employerPostalCode: originalData.employerData?.address?.postalCode || '',
    employerCity: originalData.employerData?.address?.city || ''
  });

  const changeData = (key, value) => {
    setFormData(old => ({ ...old, [key]: value }));
  };

  const saveEmployerData = async (data) => {
    try {
      if (!data.salutation) { alert('Bitte gib deine Anrede an'); return; }
      if (!data.firstName) { alert('Bitte gib deinen Vornamen an'); return; }
      if (!data.lastName) { alert('Bitte gib deinen Nachnamen an'); return; }
      if (!data.email) { alert('Bitte gib deine E-Mail-Adresse an'); return; }
      if (!data.employerName) { alert('Bitte gib den Namen des Arbeitgebers an'); return; }
      if (!data.employerStreet) { alert('Bitte gib die Straße deines Arbeitgebers an'); return; }
      if (!data.employerPostalCode) { alert('Bitte gib die PLZ deines Arbeitgebers an'); return; }
      if (!data.employerCity) { alert('Bitte gib die Stadt deines Arbeitgebers an'); return; }

      setLoading(true);
      const employerData = {
        name: data.employerName,
        address: {
          street: data.employerStreet,
          postalCode: data.employerPostalCode,
          city: data.employerCity,
          country: ''
        }
      };

      const contactPerson = {
        salutation: data.salutation,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email
      };

      await saveData({
        employerData,
        contactPerson
      });

      close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <WindowWrapper
        style={{ ...style }}
        close={close}
      >
        <div style={{ padding: 20 }}>
          <h2>Die Adressdaten deines Arbeitgebers</h2>
          <p>Jetzt fehlen nur noch die richtigen Kontaktdaten des/der Adressat:in.<br />
            Achte hier darauf, dass die Angaben auf jeden Fall richtig sind. Kein Mensch mag es, mit falschem Namen angesprochen zu werden...<br />
            <b>Hier kannst du die/den Chefärzt:in adressieren.</b>
          </p>

          <IconWithText
            icon={<AccountCircle sx={{ fontSize: 24 }} />}
            text={<h3>Ansprechpartner:in (Chefärzt:in)</h3>}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              <SelectField
                id='contact-person-salutation'
                label='Anrede'
                variant='outlined'
                fullWidth
                value={formData.salutation || ''}
                onChange={(event) => {
                  changeData('salutation', event.target.value);
                }}
                items={DomainData.salutation}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <SelectField
                id='contact-person-title'
                label='Titel'
                variant='outlined'
                fullWidth
                value={formData.title || ''}
                onChange={(event) => {
                  changeData('title', event.target.value);
                }}
                items={DomainData.docTitle}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <InputField
                id='contact-person-firstname'
                label='Vorname'
                placeholder='Manuela'
                variant='outlined'
                fullWidth
                value={formData.firstName}
                onChange={(event) => {
                  changeData('firstName', event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <InputField
                id='contact-person-lastName'
                label='Nachname'
                placeholder='Mustermann'
                variant='outlined'
                fullWidth
                value={formData.lastName}
                onChange={(event) => {
                  changeData('lastName', event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <InputField
                id='contact-person-email'
                label='E-Mail-Adresse'
                placeholder='manuela.mustermann@xyz.de'
                variant='outlined'
                fullWidth
                value={formData.email}
                onChange={(event) => {
                  changeData('email', event.target.value);
                }}
              />
            </Grid>
          </Grid>

          <IconWithText
            icon={<CorporateFare sx={{ fontSize: 24 }} />}
            text={<h3>Klinik</h3>}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              <InputField
                id='employer-name'
                label='Klinik-Name (inkl. Rechtsform)'
                placeholder='Ganzer Name der Klinik'
                variant='outlined'
                fullWidth
                value={formData.employerName}
                onChange={(event) => {
                  changeData('employerName', event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <InputField
                id='employer-street'
                label='Straße & Hausnummer'
                placeholder='Straße & Hausnummer'
                variant='outlined'
                fullWidth
                value={formData.employerStreet}
                onChange={(event) => {
                  changeData('employerStreet', event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <InputField
                id='employer-postal-code'
                label='Postleitzahl'
                placeholder='XXXXXX'
                variant='outlined'
                fullWidth
                value={formData.employerPostalCode}
                onChange={(event) => {
                  changeData('employerPostalCode', event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <InputField
                id='employer-city'
                label='Stadt'
                placeholder='Köln'
                variant='outlined'
                fullWidth
                value={formData.employerCity}
                onChange={(event) => {
                  changeData('employerCity', event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <IconWithText
                style={{ float: 'right' }}
                icon={loading && <img src={LoadingIcon} style={{ float: 'right' }} width={20} />}
                text={<PrimaryButton style={{ float: 'right' }} onClick={() => saveEmployerData(formData)}>Daten speichern</PrimaryButton>}
              />
            </Grid>
          </Grid>
        </div>
      </WindowWrapper>
    </>
  );
};

export default EmployerDataWindow;
