import React, { useContext, useEffect } from 'react';
import InputField from '../../forms/InputField';
import CoverLetterWizardFooter from '../CoverLetterWizardFooter';
import { WindowManagerContext } from '../../../context/WindowManager';
import PrimaryButton from '../../buttons/PrimaryButton';
import CheckField from '../../forms/CheckField';
import IconWithText from '../../misc/IconWithText';
import { CircularProgress, Container, Grid } from '@mui/material';
import PDFViewer from '../../misc/pdfview/PDFViewer';
import { ApplicationDataContext } from '../../../context/ApplicationData';
import LoadingIcon from '../../../assets/img/loading_transp.gif';

import { AuthContext } from '../../../context/Auth';
import Layout1 from '../../../assets/img/cover_letter_layout_1.png';
import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';
import TeaserContent from '../../misc/TeaserContent';
import Edit from '@mui/icons-material/Edit';
import ArrowBack from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { createFileInDatabase, receiveUploadURL } from '../../../api/methods';

const layouts = [
  {
    id: 0,
    name: 'Klassisch',
    image: (width) => <img src={Layout1} width={width} />
  }
];

const Details = ({ coverLetterData, previousStep, nextStep, saveData, setCoverLetter, goto, generatePDF, ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  const { auth, api } = useContext(AuthContext);
  const [generatedCoverLetter, setGeneratedCoverLetter] = React.useState(null);
  const [pdfWindowOpen, setPdfWindowOpen] = React.useState(false);
  const [hovers, setHovers] = React.useState({});
  const [signatureImage, setSignatureImage] = React.useState(null);
  const [loadingSignature, setLoadingSignature] = React.useState(false);

  const { methods, data: applicationData } = useContext(ApplicationDataContext);
  const { getSalutation, getApplicantAddress, getApplicantName, getEmployerAddress } = methods;

  const openNotification = ({ header, text }) => {
    openWindow('notification', { minWidth: Math.min(600, window.innerWidth - 0.1 * window.innerWidth), header, text });
  };

  const changeCoverLetterData = (coverLetterPart, text) => {
    saveData(coverLetterPart, { ...coverLetterData[coverLetterPart], text });
  };

  React.useEffect(() => {
    console.log(coverLetterData);
    if (!coverLetterData.date) {
      saveData('date', { text: new Intl.DateTimeFormat('de').format(new Date()) });
    }
    if (!coverLetterData.salutation?.text) {
      saveData('salutation', { text: getSalutation() });
    }
    if (!coverLetterData.subject?.text) {
      saveData('subject', { text: applicationData.subject || '' });
    }
    if (!coverLetterData.hospitationWish?.value === null) {
      saveData('hospitationWish', { value: true });
    }
    if (!coverLetterData.signatureText?.text) {
      const name = getApplicantName().split(' ');
      if (applicationData.personalData?.firstName) {
        saveData('signatureText', { text: applicationData.personalData?.firstName[0] + '. ' + name[name.length - 1] });
      } else {
        console.log(applicationData);
      }
    }
    if (!coverLetterData.endOfCoverLetter?.text) {
      saveData('endOfCoverLetter', { text: 'Über die Möglichkeit, Sie von meinen Fähigkeiten in einem persönlichen Gespräch zu überzeugen, würde ich mich sehr freuen. Wenn Sie mir die Möglichkeit zu einer Hospitation einräumen, würde ich mich sehr freuen und mich gerne von Ihrer Abteilung persönlich überzeugen lassen.' });
    }
    if (!coverLetterData.adoption?.text) {
      saveData('adoption', { text: 'Mit freundlichen Grüßen' });
    }
    if (!coverLetterData.signatureImage?.image) {
      saveData('signatureImage', { image: null });
    }
    if (!coverLetterData.applicantName?.text) {
      saveData('applicantName', { text: getApplicantName() });
    }
    if (!coverLetterData.applicantAddress?.text) {
      saveData('applicantAddress', { text: getApplicantAddress() });
    }
    if (!coverLetterData.employerAddress?.text) {
      console.log(getEmployerAddress());
      saveData('employerAddress', { text: getEmployerAddress() });
    }
  }, []);

  const downloadSignatureImage = async (file) => {
    if (coverLetterData.signatureImage.image || !!file) {
      setLoadingSignature(true);
      const image = file ? await api(jwt => file.downloadBlob(jwt, () => {})) : await api(jwt => coverLetterData.signatureImage.image.downloadBlob(jwt, () => {}));
      setSignatureImage(image);
      setLoadingSignature(false);
      console.log(image);
    }
  };

  useEffect(() => {
    downloadSignatureImage();
  }, [coverLetterData.signatureImage]);

  const ChangeDataInput = ({ close, coverLetterPart, description, header }) => {
    const [value, setValue] = React.useState(coverLetterData[coverLetterPart]?.text || '');
    return (
      <>
        <p>{description}:</p>
        <Grid container>
          <Grid item xs={12} xl={12}>
            <InputField
              multiline
              fullWidth
              rows={6}
              label={header}
              onChange={(e) => setValue(e.currentTarget.value)}
              value={value}
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <div style={{ maxWidth: 200, float: 'right' }}>
              <PrimaryButton onClick={() => { changeCoverLetterData(coverLetterPart, value); close(); }}>Speichern</PrimaryButton>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const editTextFunction = (header, description, coverLetterPart = 'cause') => {
    return () => {
      openNotification({
        header,
        text: close => <ChangeDataInput {...{ header, description, coverLetterPart, close }} />
      });
    };
  };

  const generateData = () => {
    const data = {
      font: 'Open Sans',
      layout: 0,
      text: {
        applicantName: coverLetterData.applicantName.text,
        applicantAddress: coverLetterData.applicantAddress.text,
        anlass: coverLetterData.cause.text,
        ueberDich: coverLetterData.personal.text,
        motivation: coverLetterData.motivation.text,
        endOfCoverLetter: coverLetterData.endOfCoverLetter.text,
        adoption: coverLetterData.adoption.text,
        signatureText: coverLetterData.signatureText.text,
        date: coverLetterData.date.text,
        salutationText: coverLetterData.salutation.text,
        address: coverLetterData.employerAddress.text,
        subject: applicationData.subject
      },
      signatureImageId: coverLetterData.signatureImage?.image?.id,
      fileSize: 0
    };
    console.log(data);
    return data;
  };

  const goToNextStep = async () => {
    if (!coverLetterData.signatureImage?.image?.id && !coverLetterData.signatureText?.text) { alert('Lade noch eine Unterschrift hoch'); return; }
    const data = generateData();
    setPdfWindowOpen(true);
    const { file, fileSpecs } = await generatePDF(data);
    setGeneratedCoverLetter({ file, fileSpecs });
  };

  React.useEffect(() => {
    if (coverLetterData.endOfCoverLetter?.text) {
      const hospitationString = 'Wenn Sie mir die Möglichkeit zu einer Hospitation einräumen, würde ich mich sehr freuen und mich gerne von Ihrer Abteilung persönlich überzeugen lassen.';
      const re = new RegExp(hospitationString, 'g');
      if (coverLetterData.hospitationWish?.value) {
        changeCoverLetterData('endOfCoverLetter', coverLetterData.endOfCoverLetter?.text?.replace(re, '') + hospitationString);
      } else {
        changeCoverLetterData('endOfCoverLetter', coverLetterData.endOfCoverLetter?.text?.replace(re, ''));
      }
    }
  }, [coverLetterData.hospitationWish]);

  const coverLetterParts = [
    {
      function: editTextFunction('Deine Adresse bearbeiten', 'Hier kannst du deine Adresse bearbeiten', 'applicantAddress'),
      title: 'Deine Adresse',
      content: <>{coverLetterData.applicantAddress?.text ? coverLetterData.applicantAddress.text.split('\n').map(t => <React.Fragment key={t}>{t}<br /></React.Fragment>) : 'Hier Adresse eintragen'}</>,
      align: 'left'
    },
    {
      function: editTextFunction('Die Adresse des Arbeitgebers bearbeiten', 'Hier kannst du die Adresse des Arbeitgebers nochmal nachbearbeiten', 'employerAddress'),
      title: 'Klinik-Adresse',
      content: <>{coverLetterData.applicantAddress?.text ? coverLetterData.employerAddress.text.split('\n').map(t => <React.Fragment key={t}>{t}<br /></React.Fragment>) : 'Hier Adresse eintragen'}</>,
      align: 'left'
    },
    {
      function: editTextFunction('Das Absendedatum bearbeiten', 'Hier kannst du das Absendedatum nachbearbeiten', 'date'),
      title: 'Datum',
      content: coverLetterData.date?.text || new Intl.DateTimeFormat('de').format(new Date()),
      align: 'right'
    },
    {
      function: editTextFunction('Betreff bearbeiten', 'Hier kannst du den Betreff bearbeiten', 'subject'),
      title: 'Betreff',
      content: <b>{coverLetterData.subject?.text}</b>,
      align: 'left'
    },
    {
      function: editTextFunction('Begrüßungstext bearbeiten', 'Hier kannst du den Begrüßungstext bearbeiten', 'salutation'),
      title: 'Begrüßungstext',
      content: coverLetterData.salutation?.text,
      align: 'left'
    },
    {
      function: editTextFunction('Anlass bearbeiten', 'Hier kannst du den Anlass bearbeiten', 'cause'),
      title: 'Anlass',
      content: coverLetterData.cause.text,
      align: 'left'
    },
    {
      function: editTextFunction('Text über dich bearbeiten', 'Hier kannst du den Text über dich bearbeiten', 'personal'),
      title: 'Über Dich',
      content: coverLetterData.personal.text,
      align: 'left'
    },
    {
      function: editTextFunction('Motivation bearbeiten', 'Hier kannst du die Motivation bearbeiten', 'motivation'),
      title: 'Deine Motivation',
      content: coverLetterData.motivation.text,
      align: 'left'
    },
    {
      function: editTextFunction('Abschlusssatz bearbeiten', 'Hier kannst du den Abschlusssatz bearbeiten', 'endOfCoverLetter'),
      title: 'Abschluss',
      content: coverLetterData.endOfCoverLetter?.text,
      align: 'left'
    },
    {
      function: editTextFunction('Schlussformel bearbeiten', 'Hier kannst du die Schlussformel ändern', 'adoption'),
      title: 'Schlussformel',
      content: coverLetterData.adoption?.text,
      align: 'left'
    },
    {
      title: 'Unterschrift',
      content: (
        <>
          {
          loadingSignature
            ? <CircularProgress size={30} />
            : (
              <>
                {
                  !auth?.loggedIn || (coverLetterData.signatureText?.text && !signatureImage)
                    ? (
                      <p style={{ fontSize: 35, color: 'blue' }} className='rock-salt'>
                        {coverLetterData.signatureText?.text}
                      </p>
                      )
                    : (
                      <>
                        {signatureImage ? <img src={URL.createObjectURL(signatureImage)} height={100} /> : <>Hier kannst du den Scan deiner Unterschrift hochladen.</>}
                      </>
                      )
                }
                {
                  auth?.loggedIn
                    ? (
                      <div style={{ display: 'flex' }}>
                        <PrimaryButton
                          onClick={() => {
                            openWindow('upload', {
                              type: 'Unterschrift',
                              addFile: (file) => {
                                saveData('signatureImage', { image: file });
                                setLoadingSignature(true);
                                downloadSignatureImage(file);
                              }
                            });
                          }}
                          style={{ color: signatureImage ? 'white' : 'black', maxWidth: 250, backgroundColor: signatureImage ? 'blue' : 'white', marginTop: 12, marginRight: 12 }}
                        >
                          {signatureImage ? 'Bild ändern' : 'Scan hochladen'}
                        </PrimaryButton>
                        <PrimaryButton
                          onClick={async () => {
                            const uploadSignature = async file => {
                              const { uploadURL, name: fileName } = await receiveUploadURL({ fileName: 'Unterschrift.png', mimeType: 'image/png' }, auth.jwt);
                              await axios.put(uploadURL, file, { onUploadProgress: () => {} });
                              const fileInDataBase = await createFileInDatabase({
                                type: 'Unterschrift', description: 'Unterschrift', fileName, mimeType: 'image/png'
                              }, auth.jwt);
                              saveData('signatureImage', { image: fileInDataBase });
                              downloadSignatureImage(fileInDataBase);
                            };
                            openWindow('signature', {
                              onSave: async (file, close) => {
                                try {
                                  await uploadSignature(file);
                                  close();
                                } catch (error) {
                                  console.log(error);
                                  alert('Leider hat das Hochladen nicht funktioniert. Probiere es gleich nochmal und stelle sicher, dass du eine Internetverbindung hast...');
                                }
                              }
                            });
                          }}
                          style={{ marginTop: 12 }}
                        >
                          Selber unterschreiben
                        </PrimaryButton>
                        <PrimaryButton
                          onClick={() => {
                            const name = getApplicantName().split(' ');
                            saveData('signatureText', { text: name[0][0] + '. ' + name[name.length - 1] });
                          }}
                          style={{ color: signatureImage ? 'white' : 'black', maxWidth: 250, display: coverLetterData.signatureText?.text ? 'none' : null, marginTop: 12 }}
                        >
                          Unterschrift generieren
                        </PrimaryButton>
                      </div>
                      )
                    : (
                      <PrimaryButton
                        onClick={() => openWindow('notification', {
                          header: 'Login erforderlich!',
                          text: close => (
                            <>
                              <TeaserContent close={close} />
                            </>
                          )
                        })}
                        style={{
                          color: signatureImage ? 'white' : 'black',
                          maxWidth: 250,
                          backgroundColor: signatureImage ? 'blue' : 'white'
                        }}
                      >
                        <StarBorderPurple500 style={{ color: 'orange' }} />&nbsp;Scan hochladen / per Touchpad unterschreiben
                      </PrimaryButton>
                      )
                }

              </>
              )
        }

        </>
      ),
      noButton: true,
      align: 'left'
    },
    {
      function: editTextFunction('Deinen Namen bearbeiten', 'Hier kannst deinen Namen bearbeiten', 'applicantName'),
      title: 'Dein Name',
      content: coverLetterData.applicantName?.text,
      align: 'left'
    }
  ];

  return (
    <>
      <style>
        {
      `.dr-sugiyama {
        font-family: 'Dr Sugiyama', cursive;
      }
      .la-belle-aurore {
        font-family: 'La Belle Aurore', cursive;
      }
      .indie-flower {
        font-family: 'Indie Flower', cursive;
      }
      .rock-salt {
        font-family: 'Rock Salt', cursive;
      }
      .swanky-and-moo-moo {
        font-family: 'Swanky and Moo Moo', cursive;
      }
      .zeyada {
        font-family: 'Zeyada', cursive;
      }`
    }
      </style>

      {/* {
          layouts.map((layoutItem, idx) => {
            const checked = layouts.length === 1 ? true : coverLetterData.layout === layoutItem.id;
            return (
              <React.Fragment key={idx}>
                <Card style={{ width: 220, cursor: 'pointer', position: 'relative' }} onClick={() => checked && !layouts.length === 1 ? saveData('layout', null) : saveData('layout', layoutItem.id)}>
                  <div style={{ position: 'absolute', top: 10, right: 10 }}>
                    {
                      checked
                        ? <CheckIcon style={{ color: 'green', fontSize: 25 }} />
                        : <CloseIcon style={{ color: 'red', fontSize: 25 }} />
                      }
                  </div>
                  <center>
                    {layoutItem.image(150)}
                    <p>{layoutItem.name}</p>
                  </center>
                </Card>
                <a
                  style={{ cursor: 'pointer', margin: '5px auto' }}
                  onClick={() => openNotification({
                    header: 'Nur ein Beispiel',
                    text: close => (
                      <>
                        <center>{layoutItem.image(550)}</center>
                      </>
                    )
                  })}
                >
                  <IconWithText
                    icon={<ZoomInIcon />}
                    text={<>Vergrößern</>}
                  />
                </a>
              </React.Fragment>
            );
          })
        } */}
      <Grid container>
        {
          coverLetterParts.map((d, idx) => {
            const component = (
              <>
                <IconWithText
                  icon={
                    <div style={{ width: 30, height: 30, background: 'white', borderRadius: '50%', position: 'relative' }}>
                      <center><p style={{ fontSize: 16, position: 'absolute', top: '50%', marginTop: -11, left: '50%', marginLeft: idx >= 9 ? -9 : -4 }}><b>{idx + 1}</b></p></center>
                    </div>
                  }
                  text={<h3 style={{ margin: 0 }}>{d.title}</h3>}
                />
                {
                  d.before &&
                    <div style={{ padding: 16, marginBottom: -32 }}>
                      {d.before}
                    </div>
                }
                <Container
                  style={{
                    margin: 16,
                    /* boxShadow: !d.noButton && hovers[idx] === true ? '0px 0px 7px 0px rgba(0,0,0,0.7)' : null, */
                    float: d.align,
                    padding: 15,
                    width: 'fit-content',
                    blockSize: 'fit-content',
                    cursor: !d.noButton ? 'pointer' : null,
                    position: 'relative'
                  }}
                  sx={{ fontStyle: !d.noButton && hovers[idx] === true ? 'italic' : null }}
                  onMouseEnter={() => setHovers(old => ({ ...old, [idx]: true }))}
                  onMouseLeave={() => setHovers(old => ({ ...old, [idx]: false }))}
                  onClick={() => !d.noButton ? d.link ? goto(d.link) : d.function() : {}}
                >
                  <p style={{ textAlign: d.align }}>{d.content}</p>
                  <div style={{ position: 'absolute', bottom: 26, right: -12, display: !d.noButton && hovers[idx] === true ? null : 'none' }}>
                    <Edit />
                  </div>
                  {!d.content && 'Hier bearbeiten'}
                </Container>
              </>
            );
            if (d.align === 'left') {
              return (
                <Grid item key={'clpart-' + idx} xl={12} md={12} sm={12} xs={12}>
                  {component}
                </Grid>
              );
            } else {
              return (
                <>
                  <Grid item key={'clpart-' + idx} xl={6} md={6} sm={12} xs={12}>
                  &nbsp;
                  </Grid>
                  <Grid item key={'clpart2-' + idx} xl={6} md={6} sm={12} xs={12}>
                    <div style={{ float: 'right', marginRight: 15 }}>{component}</div>
                  </Grid>
                </>
              );
            }
          })
        }
      </Grid>
      <CoverLetterWizardFooter
        previous={previousStep}
        next={goToNextStep}
        nextText='PDF erstellen'
      />
      {
        (pdfWindowOpen) &&
          <PDFViewer
            file={generatedCoverLetter?.file} close={() => {
              if (!generatedCoverLetter) return;
              openWindow('notification', {
                header: 'PDF speichern?',
                maxWidth: 500,
                text: close => {
                  return (
                    <>
                      Möchtest du dieses Anschreiben speichern und damit fortfahren?<br />
                      Oder musst du noch ein paar Änderungen machen?
                      <br /><br />
                      <PrimaryButton
                        onClick={() => {
                          setPdfWindowOpen(false);
                          setGeneratedCoverLetter(null);
                          close();
                        }}
                        style={{ background: 'red', color: 'white', float: 'left' }}
                      >
                        Zurück zur Bearbeitung
                      </PrimaryButton>
                      <PrimaryButton
                        onClick={() => {
                          setCoverLetter(generatedCoverLetter?.fileSpecs?.id);
                          changeCoverLetterData('fileId', generatedCoverLetter?.fileSpecs?.id);
                          props.close();
                          close();
                        }}
                        style={{ background: 'green', color: 'white', float: 'right' }}
                      >
                        Dieses PDF nutzen
                      </PrimaryButton>
                    </>
                  );
                }
              });
            }}
          >
            <div
              style={{
                padding: 12,
                width: '100%',
                marginBottom: 12
              }}
            >
              <PrimaryButton secondary onClick={() => { setPdfWindowOpen(false); setGeneratedCoverLetter(null); }}><ArrowBack />&nbsp;&nbsp;Bearbeiten</PrimaryButton>
              <PrimaryButton onClick={() => { setCoverLetter(generatedCoverLetter?.fileSpecs?.id); props.close(); }} style={{ float: 'right', marginRight: 30 }}>PDF speichern</PrimaryButton>
            </div>
          </PDFViewer>
          /* : (
            <div style={{ width: '100%' }}>
              <center><img src={require('assets/img/loading_transp.gif')} width='50' style={{ margin: '50px auto' }} /></center>
              <center>Lädt Dokument...</center>
            </div>
          ) */
      }
    </>
  );
};

export default Details;
