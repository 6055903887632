import React from 'react';
import config from '../configuration';

export const CREATE_RATING = config.backendAddress + '/open/createRating';
export const SIGNUP = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/signupArztbrief';
export const LOGIN = config.backendAddress + '/auth/login';
export const GET_AUTH_INFO = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getUserInfo';
export const GET_OLD_USER = config.backendAddress + '/auth/getOldUser';
export const CREATE_TRACKING = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createArztbriefTracking';
export const CREATE_USER_PROFILE = config.backendAddress + '/users/createProfile';
export const CREATE_NEW_APPLICATION = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createNewApplication';
export const UPDATE_USER_DATA = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/updateArztbriefUser';
export const RECEIVE_UPLOAD_URL = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getUploadURL';
export const CREATE_FILE_IN_DATABASE = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createFile';
export const GET_FILES = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getFiles';
export const RECEIVE_DOWNLOAD_URLS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getDownloadURLs';
export const GET_APPLICATION_BY_ID = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getApplicationById';
export const UPDATE_APPLICATION = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/updateApplication';
export const GENERATE_COVER_LETTER = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/generateCoverLetter';
export const CREATE_NEW_COVER_LETTER = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createNewCoverLetter';
export const GET_COVER_LETTER_BY_ID = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getCoverLetterById';
export const GET_COVER_LETTER_BY_FILE_ID = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getCoverLetterByFileId';
export const UPDATE_COVER_LETTER = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/updateCoverLetter';
export const GET_ALL_COVER_LETTERS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getAllCoverLettersForUser';
export const GET_ALL_APPLICATIONS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getAllApplicationsForUser';
export const DELETE_FILE = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/deleteFile';
export const SEND_APPLICATION = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/sendApplication';
export const GET_CREDIT_TRANSACTIONS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getCreditTransactionsForUser';
export const CREATE_DEPOSIT = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createArztbriefDeposit';
export const DECREASE_CREDITS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/decreaseCredits';
export const CREATE_NEW_CV = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createNewCV';
export const UPDATE_CV = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/updateCV';
export const GET_CV_BY_ID = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getCVById';
export const CHECK_IF_USER_EXISTS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/checkIfUserExists';
export const CREATE_ARZTBRIEF_USER_FROM_XASS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createArztbriefUserFromXass';
export const GET_ARZTBRIEF_USER_INFO = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getArztbriefUserInfo';
export const GENERATE_ARZTBRIEF = 'https://iooonxzqopjp6ufidrlp3fif2y0ixekk.lambda-url.eu-central-1.on.aws/';
export const CREATE_FEEDBACK = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createArztbriefFeedback';
export const CREATE_QUEUE_ITEM = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/createArztbriefQueueItem';
export const GET_ALL_QUEUED_ITEMS = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getAllArztbriefQueuedItems';
export const GENERATE_ARZTBRIEF_WITHOUT_AUTH = 'https://jzfqbpn53hxhvpmuqdfecynope0fcnyv.lambda-url.eu-central-1.on.aws/';
export const GET_QUEUED_ITEM_BY_ID = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/getArztbriefQueuedItemById';
export const SAVE_NEW_ARZTBRIEF_RESULT = 'https://anzaoxaloa.execute-api.eu-central-1.amazonaws.com/dev/saveNewUserWrittenArztbriefResult';
