import React, { useContext } from 'react';
import TextModuleBox from '../TextModuleBox';
import InputField from '../../forms/InputField';
import MedData from '../../../assets/datasets/MedData';
import CoverLetterWizardFooter from '../CoverLetterWizardFooter';
import { Grid, Hidden, useMediaQuery } from '@mui/material';
import SelectField from '../../forms/SelectField';
import PrimaryButton from '../../buttons/PrimaryButton';
import { WindowManagerContext } from '../../../context/WindowManager';

const variants = MedData.textModules.motivation;

const TextModuleButton = props => {
  return (
    <div
      style={{
        border: '1px solid rgba(0,0,0,0.2)',
        padding: 8,
        paddingLeft: 12,
        paddingRight: 12,
        width: 'fit-content',
        blockSize: 'fit-content',
        cursor: 'pointer',
        float: 'left',
        margin: 4,
        ...props.style
      }}
      onClick={props.onClick}
    >
      <span style={{ fontSize: 12 }}>{props.children}</span>
    </div>
  );
};

const Motivation = ({ coverLetterData, previousStep, nextStep, saveData, ...props }) => {
  const [text, setText] = React.useState('');
  const { motivation: relevantCoverLetterData } = coverLetterData;
  const [selectedItem, setSelectedItem] = React.useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const { openWindow } = useContext(WindowManagerContext);

  React.useEffect(() => {
    saveData({ text });
  }, [text]);

  React.useEffect(() => {
    if (relevantCoverLetterData?.text) setText(relevantCoverLetterData.text);
  }, []);

  const ChangeDataInput = ({ close, originalText, header }) => {
    const [value, setValue] = React.useState(originalText);
    return (
      <>
        <p>Hier kannst du den Text noch ändern:</p>
        <Grid container>
          <Grid item xs={12} xl={12}>
            <InputField
              multiline
              fullWidth
              rows={6}
              label={header}
              onChange={(e) => setValue(e?.currentTarget?.value || '')}
              value={value}
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <div style={{ maxWidth: 200, float: 'right' }}>
              <PrimaryButton onClick={() => { setText(old => old + (old !== '' ? ' ' : '') + value); close(); }}>Speichern</PrimaryButton>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const goToNextStep = () => {
    if (text) {
      nextStep();
    } else {
      alert('Bitte wähle einen der Textbausteine aus.');
    }
  };

  const editTextFunction = (header, originalText) => {
    openWindow('notification', {
      minWidth: Math.min(450, window.innerWidth - 0.1 * window.innerWidth),
      header,
      text: close => <ChangeDataInput {...{ header, originalText, close }} />
    });
  };

  return (
    <>
      <h3>Textbausteine anklicken</h3>
      <p>
        Wir haben dir hier ein paar Textbausteine als Vorschläge aufgeführt. Klicke einfach auf eine
        der Bausteine und ein Beispieltext wird angefügt.
      </p>
      {
        !isMobile
          ? variants.map((cb, idx) => {
              return (
                <TextModuleButton
                  key={idx}
                  style={{
                    backgroundColor: 'white'
                  }}
                  onClick={() => editTextFunction(cb.title,cb.text)}
                >
                  {cb.title}
                </TextModuleButton>
              );
            })
          : (
            <>
              <div style={{ width: '100%', height: 12 }} />
              <SelectField
                fullWidth
                items={variants.map((v, idx) => ({ value: idx, label: v.title }))}
                label='Baustein'
                onChange={(e) => setSelectedItem(e.target.value)}
                value={selectedItem}
              />
              <div style={{ width: '100%', height: 12 }} />
              <PrimaryButton
                secondary
                onClick={() => setText(old => old + (old !== '' ? ' ' : '') + variants[selectedItem].text)}
                style={{ float: 'right' }}
              >
                Text einfügen
              </PrimaryButton>
            </>
            )
        }
      <InputField
        label='Hier kommt deine Motivation hin'
        multiline
        fullWidth
        rows={12}
        onChange={(e) => setText(old => e.currentTarget.value)}
        value={text}
        style={{ marginBottom: 0, marginTop: 32, marginLeft: 0 }}
      />

      <CoverLetterWizardFooter
        previous={previousStep}
        next={goToNextStep}
      />

    </>
  );
};

export default Motivation;
