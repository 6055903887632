import React, { useContext } from 'react';
/* import CreateRating from './CreateRating';
import Signup from './Signup';
import LoadingWindow from './LoadingWindow';
import NotificationWindow from './NotificationWindow';
import Login from './Login';
import PreFilterWindow from './PreFilterWindow';
import CookieWindow from './CookieWindow';
import WizardWindow from './WizardWindow'; */
import NewApplicationWindow from './NewApplicationWindow';
import { WindowManagerContext } from '../context/WindowManager';
import EmployerDataWindow from './EmployerDataWindow';
import CoverLetterWindow from './CoverLetterWindow';
import NotificationWindow from './NotificationWindow';
import SendApplicationWindow from './SendApplicationWindow';
import LoginWindow from './LoginWindow';
import SignupWindow from './SignupWindow';
import PersonalDataWindow from './PersonalDataWindow';
import UploadWindow from './UploadWindow';
import Modal from 'react-responsive-modal';
import CreditDepositWindow from './CreditDepositWindow';
import SignatureWindow from './SignatureWindow';
import CVWindow from './CVWindow';
import LoadingWindow from './LoadingWindow';

const windowComponents = {
  /* createRating: (props) => <CreateRating {...props} />,
  signup: (props) => <Signup {...props} />,
  loading: (props) => <LoadingWindow {...props} />,
  notification: (props) => <NotificationWindow {...props} />,
  login: (props) => <Login {...props} />,
  preFilter: (props) => <PreFilterWindow {...props} />,
  chooseCookie: (props) => <CookieWindow {...props} />,
  wizard: (props) => <WizardWindow {...props} /> */
  newApplication: (props) => <NewApplicationWindow {...props} />,
  employerData: (props) => <EmployerDataWindow {...props} />,
  coverLetter: (props) => <CoverLetterWindow {...props} />,
  notification: (props) => <NotificationWindow {...props} />,
  sendApplication: (props) => <SendApplicationWindow {...props} />,
  login: props => <LoginWindow {...props} />,
  register: props => <SignupWindow {...props} />,
  personalData: props => <PersonalDataWindow {...props} />,
  upload: props => <UploadWindow {...props} />,
  creditDeposit: props => <CreditDepositWindow {...props} />,
  signature: props => <SignatureWindow {...props} />,
  cv: props => <CVWindow {...props} />,
  loading: props => <LoadingWindow {...props} />
};

const extraStyles = {
  coverLetter: { width: '100%', height: '100%', overflow: 'none', padding: '0', margin: '0' },
  cv: { width: '100%', height: '100%', overflow: 'none', padding: '0', margin: '0' },
  login: { maxWidth: '400px' },
  upload: { width: Math.min(800, window.innerWidth - 40) + 'px' },
  register: { width: '100%', height: '100%', overflow: 'none', padding: '0', margin: '0' },
  creditDeposit: { width: '100%', height: '100%', overflow: 'none', padding: '0', margin: '0' }
};

const closeCurrentWindow = (windows, closeWindow) => {
  let currentWindowName = null;
  let currentZIndex = 0;
  Object.entries(windows).forEach(([key, value]) => {
    if (value.open) {
      if (value.zIndex > currentZIndex) {
        currentWindowName = key;
        currentZIndex = value.zIndex;
      }
    }
  });
  closeWindow(currentWindowName);
};

const WindowsHolder = (props) => {
  const { windows, windowBackgroundZIndex, closeWindow } = useContext(WindowManagerContext);
  const [anyWindowOpen, setAnyWindowOpen] = React.useState(false);
  // const [backgroundZIndex, setBackgroundZIndex] = React.useState(windowBackgroundZIndex);

  React.useEffect(() => {
    setAnyWindowOpen(false);
    Object.keys(windows).forEach(windowName => {
      if (windows[windowName].open) {
        setAnyWindowOpen(true);
      }
    });
  }, [windows]);

  return (
    <>
      {
        Object.keys(windows).map(windowName => {
          const { zIndex, open, props } = windows[windowName];
          const { style = {}, notClosable = false } = props;
          if (open) {
            const preStyles = extraStyles[windowName] || {};
            return (
              <Modal
                key={windowName}
                classNames={{
                  modal: `${windowName}-customModal`,
                  root: `${windowName}-root-customModal`
                }}
                open={open}
                style={{ ...preStyles, zIndex }}
                onClose={() => { if (!notClosable) closeWindow(windowName); }}
              >
                {windowComponents[windowName]({
                  ...props,
                  close: () => { closeWindow(windowName); },
                  style: { ...style, zIndex },
                  className: props.className + (open ? ' window-open' : ' window-closed')
                })}

                <style>
                  {
                  `
                  .${windowName}-root-customModal {
                    z-index: ${zIndex};
                  }

                  .${windowName}-customModal {
                    width: ${preStyles.width + (preStyles?.width?.indexOf('%') > -1 ? '' : 'px')};
                    margin: ${preStyles.margin + (preStyles?.margin?.indexOf('%') > -1 ? '' : 'px')};
                    height: ${preStyles.height + (preStyles?.height?.indexOf('%') > -1 ? '' : 'px')};
                    padding: ${preStyles.padding + (preStyles?.padding?.indexOf('%') > -1 ? '' : 'px')}
                  }
                  ` + (preStyles.width === '100%'
                    ? `
                  .react-responsive-modal-modal {
                    max-width: 100%
                  }
                  `
                    : '') + ((`
                  .${windowName}-customModal .react-responsive-modal-closeButton {
                    margin-right: 10px;
                  `) + (notClosable
                      ? `
                      display : none; }
                  `
                      : '}'))
                }
                </style>
                {/* <React.Fragment key={windowName}>
                {
                  windowComponents[windowName]
                    ? windowComponents[windowName]({
                        ...props,
                        close: () => { closeWindow(windowName); },
                        style: { ...props.style, zIndex },
                        className: props.className + (open ? ' window-open' : ' window-closed')
                      })
                    : null
                }
              </React.Fragment> */}
              </Modal>
            );
          } else {
            return null;
          }
        })
      }
      {/* <div
        onClick={() => closeCurrentWindow(windows, closeWindow)}
        style={{
          zIndex: windowBackgroundZIndex,
          height: '100vh',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }}
      /> */}
      {props.children}
    </>
  );
};

export default WindowsHolder;
