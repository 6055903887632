
import { Button } from '@mui/material';
import React from 'react';

const PrimaryButton = ({ secondary, scale = 1, ...props }) => {
  const style = {
    backgroundColor: secondary ? 'secondary.main' : 'primary.main',
    fontSize: 0.8 * scale + 'rem',
    textTransformation: 'none',
    color: secondary ? 'white' : 'black',
    '&:hover': {
      color: secondary ? 'black' : 'white',
      backgroundColor: secondary ? 'primary.main' : 'secondary.main'
    }
  };
  return (
    <Button variant='contained' {...props} sx={style}>{props.children}</Button>
  );
};

export default PrimaryButton;
