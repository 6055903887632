import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/Auth'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import queryString from 'qs'
import { useLocation, useNavigate } from 'react-router-dom'
import { createNewCoverLetter, generateCoverLetter, getApplication, getCoverLetterById, updateCoverLetter } from '../../api/methods'
import Cause from './steps/Cause'
import StepImage from '../../assets/img/step1.png'
import Personal from './steps/Personal'
import Motivation from './steps/Motivation'
import Details from './steps/Details'
import CoverLetter from '../../classes/CoverLetter'
import File from '../../classes/File'
import { ApplicationDataContext } from '../../context/ApplicationData'
import { CircularProgress, Hidden } from '@mui/material'
import LoadingIcon from '../../assets/img/loading_transp.gif'

import Step1Img from '../../assets/img/cause.png'
import Step2Img from '../../assets/img/personal.png'
import Step3Img from '../../assets/img/motivation.png'
import Step4Img from '../../assets/img/overview.png'
import PrimaryButton from '../buttons/PrimaryButton'
import ApplicationWizardMenu from '../wizard/ApplicationWizardMenu'
import { WindowManagerContext } from '../../context/WindowManager'
import TeaserContent from '../misc/TeaserContent'

const MenuGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#F5F5F5',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  }
}))

const MainGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey.megaLight,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  }
}))

const steps = [
  null,
  props => <Cause {...props} />,
  props => <Personal {...props} />,
  props => <Motivation {...props} />,
  props => <Details {...props} />
]

const stepsMenuItems = [
  {},
  {
    image: Step1Img,
    header: 'Anlass',
    explanation: <>Der Anlass gibt dem Adressaten/der Adressatin die Möglichkeit, zu verstehen, woher deine Bewerbung kommt.</>,
    tip: 'Je persönlicher dein Bezug zum Arbeitgeber ist (z.B. PJ, Famulatur...), desto glaubwürdiger ist das Interesse.'
  },
  {
    image: Step2Img,
    header: 'Deine Person',
    explanation: <>Dieser Teil ist über deine Person. Hier musst du auf den Punkt bringen, welche Qualifikationen du mitbringst, wo du gerade stehst und durchblicken lassen, warum du für das Fachgebiet brennst, für das du dich bewirbst.</>,
    tip: 'Beschreibe nicht deinen ganzen Lebenslauf sondern erwähne die Kerneckpunkte deiner bisher erreichten Qualifikationen.'
  },
  {
    image: Step3Img,
    header: 'Deine Motivation',
    explanation: <>In diesem Fall sollst du formulieren, warum du gerade bei DEM Arbeitgeber arbeiten möchtest.</>,
    tip: 'Hier kannst du mal etwas recherchieren. Aber wir haben ein paar Tipps, was du hier schreiben kannst.'
  },
  {
    image: Step4Img,
    header: 'Übersicht',
    explanation: <>Hier kannst du den Text deines Anschreibens bearbeiten und als PDF herunterladen!</>,
    tip: (
      <>
        Du kannst nach dem Generieren das Anschreiben später noch anpassen.
      </>
    )
  }
]

const stepNames = [
  null,
  'cause',
  'personal',
  'motivation',
  'details'
]

const Root = styled(Box)(({ theme }) => ({
  paddingTop: '5%',
  color: 'grey.dark',
  padding: '5%',
  paddingLeft: '10%',
  paddingRight: '10%'
}))

const CoverLetterWizardHolder = ({ coverLetterId, close, saveCoverLetter: setCoverLetter, ...props }) => {
  const [step, setStep] = useState(1)
  const [coverLetterData, setCoverLetterData] = useState({
    ...stepNames.slice(1).reduce((acc, curr) => (acc[curr] = {}, acc), {})
  })
  const { data: applicationData } = useContext(ApplicationDataContext)
  const { auth, api } = useContext(AuthContext)
  const { openWindow } = useContext(WindowManagerContext)
  const scroller = React.useRef(null)

  const nextStep = async () => {
    if (step < steps.length - 1) {
      setStep(step + 1)
    } else {
      // await generatePDF();
      // setCoverLetter(coverLetterData.id);
    }
  }

  const previousStep = () => {
    if (step > 1) setStep(step - 1)
  };

  useEffect(() => {
    if (coverLetterId) {
      api(jwt => getCoverLetterById(coverLetterId, jwt))
        .then(cl => { setCoverLetterData(old => cl) })
        .catch(err => { alert('Leider konnte das Anschreiben nicht erstellt werden'); console.log(err) })
    } else {
      if (auth?.loggedIn && applicationData.id && !coverLetterData.id) {
        console.log('cover letter wizard create VORHER:', coverLetterData)
        api(jwt => createNewCoverLetter({ applicationId: applicationData.id }, jwt))
          .then(cl => {
            console.log('cover letter wizard create NACHHER:', { ...cl, ...coverLetterData })
            setCoverLetterData(old => ({ ...stepNames.slice(1).reduce((acc, curr) => (acc[curr] = {}, acc), {}), ...cl, ...old }))
          })
          .catch(err => { alert('Leider konnte das Anschreiben nicht erstellt werden'); console.log(err) })
      } else if (!auth?.loggedIn) {
        setCoverLetterData(old => ({ ...old, ...stepNames.slice(1).reduce((acc, curr) => (acc[curr] = {}, acc), {}) }))
      }
    }
  }, [auth, applicationData.id])

  React.useEffect(() => {
    if (scroller.current) scroller.current.scrollIntoView()
  }, [step])

  const generatePDF = async (data) => {
    // const data = generateData(coverLetterData);
    try {
      if (auth?.loggedIn) {
        const { fileSpecs } = await api(jwt => generateCoverLetter(data, jwt))
        const file = new File(fileSpecs)
        const fileAsBlob = await api(jwt => file.downloadBlob(jwt, () => {}))
        // updateAttribute('fileId', fileSpecs.id)
        return { fileSpecs, file: fileAsBlob }
      } else {
        openWindow('notification', {
          header: 'Login erforderlich',
          maxWidth: 400,
          text: close => {
            return (
              <>
                <TeaserContent close={close} />
              </>
            )
          }
        })
      }
    } catch (error) {
      alert('Leider hat das Generieren nicht geklappt. Bitte probiere es nochmal')
      return null
    }
  }

  const saveCoverLetter = () => {
    if (coverLetterData.id) {
      api(jwt => updateCoverLetter(coverLetterData, jwt))
    }
  }

  /* useEffect(() => {
    const interval = setInterval(() => {
      if (coverLetterData.id) {
        saveCoverLetter();
      }
    }, 20000);
    return () => clearInterval(interval);
  }, []); */

  useEffect(() => {
    // TODO: bessere Speichervariante! => für den Moment OK
    if (auth?.loggedIn) saveCoverLetter()
  }, [step, coverLetterData.fileId])

  const updateAttribute = (type, data) => {
    if (type === 'date') console.log(data)
    setCoverLetterData(old => ({ ...old, [type]: data }))
  };

  const wizardProps = {
    coverLetterData,
    saveData: stepNames[step] === 'details' ? (type, data) => { updateAttribute(type, data); saveCoverLetter() } : data => updateAttribute([stepNames[step]], data),
    previousStep,
    nextStep,
    goto: stepNames[step] === 'details' ? stepName => stepNames.indexOf(stepName) > -1 ? setStep(stepNames.indexOf(stepName)) : () => {} : null,
    close,
    generatePDF,
    setCoverLetter
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div ref={scroller} />
      <Grid container spacing={1}>
        <MenuGrid item xs={12} sm={12} md={3} lg={3} xl={2} style={{ background: 'white' }}>
          <ApplicationWizardMenu
            stepNr={step}
            {...stepsMenuItems[step]}
          />
        </MenuGrid>
        <MainGrid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ background: 'grey.dark' }}>
          {
            (auth?.loggedIn && coverLetterData.id) || (!auth?.loggedIn && coverLetterData[stepNames[1]])
              ? (
                <Root>
                  {steps[step]({
                    ...wizardProps
                  })}
                </Root>
                )
              : <CircularProgress />
            }
        </MainGrid>
      </Grid>
    </Box>
  )
};

export default CoverLetterWizardHolder
