import React, { useContext, useEffect } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { AuthContext } from '../context/Auth';
import WindowWrapper from './WindowWrapper';
import CheckField from '../components/forms/CheckField';
import InputField from '../components/forms/InputField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AppInfoContext } from '../context/AppInfo';
import { WindowManagerContext } from '../context/WindowManager';
import configuration from '../configuration';
import IconWithText from '../components/misc/IconWithText';
import Logo from '../assets/img/logo_other_way_around.png';
import LoadingIcon from '../assets/img/loading_transp.gif';
import CheckIcon from '../assets/img/check-icon.png';
import LocalOffer from '@mui/icons-material/LocalOffer';
import TeaserContent from '../components/misc/TeaserContent';
import GridBase from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TeaserImage from '../assets/img/teaser_img.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Check from '@mui/icons-material/Check';
import { AlternateEmail, BusinessCenter, CheckCircle } from '@mui/icons-material';
import SelectField from '../components/forms/SelectField';
import DomainData from '../assets/datasets/DomainData';
import { Container } from '@mui/system';
import { checkIfUserExists, createArztbriefUserFromXass } from '../api/methods';
import { Button, CircularProgress } from '@mui/material';
import WizardButton from '../components/buttons/WizardButton';

const BETA = false;

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`;

const FullHeightGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  },
  color: theme.palette.grey.dark,
  padding: '5%',
  flexGrow: 1
}));

const FullHeightGridSecondary = styled(FullHeightGrid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: 'white',
  paddingTop: '10%'
}));

const containsSpecialChars = str => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

const hasNumber = myString => {
  return /\d/.test(myString);
};

const SignupWindow = ({ style, close, className, ...props }) => {
  const [signedUp, setSignedUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const { openWindow } = React.useContext(WindowManagerContext);
  const { auth, signup } = useContext(AuthContext);

  const [data, setData] = React.useState({});

  const editData = (key, value) => {
    setData(old => ({ ...old, [key]: value }));
  };

  useEffect(() => {
    if (data.coupon === 'FACHARZTJETZT') {
      editData('credits', 5);
    } else {
      if (data.credits) editData('credits', 0);
    }
  }, [data.coupon]);

  const nextStep = async () => {
    if (step === 1) {
      setLoading(true);
      if (!data.firstName) { alert('Bitte gib\' deinen Vornamen an.'); return; }
      if (!data.lastName) { alert('Bitte gib\' deinen Vornamen an.'); return; }
      if (!data.email) { alert('Bitte gib\' deine E-Mail-Adresse an.'); return; }
      if (!data.expertiseArea) { alert('Bitte gib\' deinen Fachbereich an.'); return; }
      const { arztbriefExists, xassExists } = await checkIfUserExists(data.email);
      if (arztbriefExists) {
        openWindow('notification', {
          header: 'Du hast bereits einen Account...',
          text: closeFirst => (
            <>
              <p>Willkommen zurück, {data.firstName} 😊</p>
              <p>Du bist bereits registriert. Du kannst den Arztbrief-Generator jetzt nutzen 🔥</p>
              <WizardButton scale={1.5} style={{ float: 'right' }} onClick={() => { closeFirst(); close(); openWindow('login'); }}>Hier einloggen</WizardButton>
            </>
          ),
          maxWidth: 400
        });
      } else if (xassExists) {
        await createArztbriefUserFromXass(data.email);
        openWindow('notification', {
          header: 'Du hast bereits einen Account für den Bewerbungsgenerator...',
          text: closeFirst => (
            <>
              <p>Willkommen zurück, {data.firstName} 😊</p>
              <p>Wir haben deinen Account jetzt für das Beta-Programm registriert. Du kannst den Arztbrief-Generator nun <b><nobr>3 Monate</nobr> lang kostenlos</b> nutzen 🔥</p>
              <WizardButton scale={1.5} style={{ float: 'right' }} onClick={() => { closeFirst(); close(); openWindow('login'); }}>Hier einloggen</WizardButton>
            </>
          ),
          maxWidth: 400
        });
      } else {
        setStep(2);
      }
    }
    setLoading(false);
  };

  const makeSignup = async (data) => {
    if (!data.firstName) { alert('Bitte gib\' deinen Vornamen an.'); return; }
    if (!data.lastName) { alert('Bitte gib\' deinen Vornamen an.'); return; }
    if (!data.email) { alert('Bitte gib\' deine E-Mail-Adresse an.'); return; }
    if (!data.password) { alert('Bitte gib\' ein Passwort an.'); return; }
    if (data.password.length < 8) { alert('Bitte gib\' ein Passwort mit mindestens 8 Zeichen an.'); return; }
    if (!containsSpecialChars(data.password)) { alert('Bitte achte darauf, dass dein Passwort Sonderzeichen enthält!'); return; }
    if (!hasNumber(data.password)) { alert('Bitte achte darauf, dass dein Passwort Zahlen enthält!'); return; }
    if (data.password !== data.passwordRepeat) { console.log(data); alert('Leider stimmen die angegebenen Passwörter nicht überein.'); return; }
    if (!data.dataPrivacy) { alert('Bitte bestätige noch die Datenschutzbestimmungen und die AGB'); return; }
    if (BETA) data.subscription = { type: 'beta', validUntil: 90 };
    try {
      setLoading(true);
      await signup({ credits: data.credits || 0, ...data });
      setSignedUp(true);
      setLoading(false);
      // alert('Registriert');
    } catch (e) {
      // errorHandlers.signupErrorHandler(e.response.data);
      console.log(e);
      alert('Leider hat die Registrierung nicht geklappt. Hast du dich schon mit dieser E-Mail-Adresse registriert?');
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{ fontFamily: 'Work Sans', letterSpacing: '-0.02em' }}
      >
        <FullHeightGridSecondary
          item xs={12} md={6} lg={6} xl={6}
        >
          <img src={TeaserImage} width='100%' />
          <center>
            <div style={{ maxWidth: 300, margin: '0px auto' }}>
              <h2>Arztbrief-Generator</h2>
            </div>
          </center>
        </FullHeightGridSecondary>
        <FullHeightGrid item xs={12} md={6} lg={6} xl={6}>
          <div style={{ padding: 24, paddingTop: 0 }}>
            {
          signedUp
            ? (
              <center>
                <CheckCircleIcon style={{ fontSize: 50 }} />
                <h3 style={{ fontWeight: 'bold' }}>Herzlich Willkommen beim Arztbrief-Generator von Felix Medicus!</h3>
                <p style={{ color: 'grey', marginBottom: 0 }}>
                  Verwandle deine Notizen mit einem Klick in einen Fließtext. 🔥<br />
                  <br />
                </p>
                <WizardButton scale={2} onClick={() => { close(); openWindow('login', {}); close(); }}>Jetzt einloggen 🚀</WizardButton>
              </center>
              )
            : (
              <>
                {
                    auth?.loggedIn
                      ? (
                        <>Schon eingeloggt</>
                        )
                      : (
                        <>
                          <center><img src={Logo} width={70} /></center>
                          <br />

                          <p style={{ color: 'grey', marginBottom: 0 }}>
                            Wir freuen uns auf dich! Wir brauchen nur noch folgende Angaben:
                          </p>
                          <br />
                          {/* <p style={{ color: 'grey', marginBottom: 0 }}>Vertrieblicher Support</p>
                  <p style={{ fontSize: 24 }}>0176 / 473 39736</p> */}

                          <div style={{ margin: '0 auto' }}>
                            {
                              step === 1 && (
                                <>
                                  <IconWithText
                                    icon={<AccountCircle />}
                                    text={<b>Dein Name</b>}
                                  />
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} md={6} lg={6}>
                                      <InputField size='small' value={data.firstName || ''} onChange={e => editData('firstName', e.currentTarget.value)} label='Vorname' placeholder='Vorname' style={{ marginTop: 12 }} fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                      <InputField size='small' value={data.lastName || ''} onChange={e => editData('lastName', e.currentTarget.value)} label='Nachname' placeholder='Nachname' style={{ marginTop: 12 }} fullWidth />
                                    </Grid>
                                  </Grid>
                                  <div style={{ height: 20, width: '100%' }} />
                                  <IconWithText
                                    icon={<AlternateEmail />}
                                    text={<b>E-Mail</b>}
                                  />
                                  <InputField size='small' value={data.email || ''} onChange={e => editData('email', e.currentTarget.value)} type='email' label='E-Mail-Adresse' placeholder='E-Mail-Adresse' fullWidth style={{ marginTop: 12 }} />
                                  <div style={{ height: 20, width: '100%' }} />
                                  <IconWithText
                                    icon={<BusinessCenter />}
                                    text={<b>Fachbereich</b>}
                                  />
                                  <SelectField size='small' value={data.expertiseArea || ''} onChange={e => { editData('expertiseArea', e.target?.value); }} items={DomainData.medAreasOfExpertise} fullWidth style={{ marginTop: 12 }} />

                                  <div style={{ height: 20, width: '100%' }} />

                                  {/* <Container style={{ position: 'relative', width: '100%', fontFamily: 'Work Sans', fontWeight: 500, letterSpacing: '-0.02em', backgroundColor: '#00000021', color: 'black', paddingTop: 24, paddingBottom: 24, border: '1px solid rgba(0,0,0,0.3)' }}>
                                    <IconWithText
                                      icon={<CheckCircle style={{ marginTop: 2 }} />}
                                      style={{ alignItems: 'self-start' }}
                                      text={
                                        <Container>
                                          <span style={{ fontSize: '24px', fontFamily: 'Work Sans' }}><b>Beta-Phase</b></span>&nbsp;&nbsp;&nbsp;<span style={{ background: '#F9EEB1', fontSize: '24px', padding: 6, marginBottom: 12 }}>0€</span>
                                          <div style={{ height: 20, width: '100%' }} />
                                          {
                                            [
                                              '3 Monate kostenlose Nutzung des Tools',
                                              'Neueste Features sofort verfügbar',
                                              'Besonderer Support'
                                            ].map((v, i) => {
                                              return (
                                                <IconWithText
                                                  key={i}
                                                  icon={<><img src={CheckIcon} />&nbsp;</>}
                                                  text={v}
                                                  style={{ marginBottom: 8 }}
                                                />
                                              );
                                            })
                                          }
                                          <p style={{ color: 'rgba(0,0,0,0.8)', fontWeight: 400 }}>Dafür beantwortest du uns auf unregelmäßiger Basis ab und zu Fragen zum Tool 😊 So hilfst du uns, das Tool zu perfektionieren.</p>
                                        </Container>
                                      }
                                    />
                                  </Container> */}

                                  <div style={{ height: 10, width: '100%' }} />
                                  <IconWithText
                                    icon={<LocalOffer />}
                                    text={<b>Coupon-Code</b>}
                                  />
                                  <InputField size='small' value={data.coupon || ''} style={{ marginTop: 12, backgroundColor: data.credits ? 'green' : null, color: data.credits ? 'white' : null }} onChange={e => editData('coupon', e.currentTarget.value)} label='Coupon-Code' placeholder='Coupon-Code' fullWidth />
                                  {
                                    (data.credits && data.credits > 0) &&
                                      <IconWithText
                                        icon={<Check style={{ color: 'green' }} />}
                                        text={<b>{data.credits} Arztbriefe kostenlos</b>}
                                      />
                                  }
                                  <div style={{ height: 10, width: '100%' }} />
                                  <WizardButton scale={1.5} style={{ float: 'right' }} onClick={() => nextStep()}>{loading ? 'Lädt....' : 'Nächster Schritt (1/2)'}</WizardButton>&nbsp;&nbsp;{loading && <CircularProgress style={{ float: 'right', marginTop: 10 }} size={20} />}
                                </>
                              )
                            }

                            {
                              step === 2 && (
                                <>
                                  <b>Passwort</b>&nbsp;&nbsp;
                                  <a
                                    onClick={() => {
                                      openWindow('notification', {
                                        header: 'Passwort-Richtlinie',
                                        text: close =>
                                          <>
                                            {configuration.passwordPolicies.map(pp => <React.Fragment key={pp}>- {pp}<br /></React.Fragment>)}
                                          </>
                                      });
                                    }}
                                    style={{ fontSize: 13, cursor: 'pointer', textDecoration: 'underline' }}
                                  >
                                    Passwort-Richtlinie
                                  </a>
                                  <br />
                                  <InputField size='small' type='password' value={data.password || ''} onChange={e => editData('password', e.currentTarget.value)} label='Passwort' placeholder='Passwort' fullWidth style={{ marginTop: 12 }} />
                                  <InputField size='small' type='password' value={data.passwordRepeat || ''} onChange={e => editData('passwordRepeat', e.currentTarget.value)} label='Passwort wiederholen' placeholder='Passwort wiederholen' fullWidth style={{ marginTop: 12 }} />
                                  <IconWithText
                                    icon={
                                      <CheckField
                                        onChange={(e) => editData('dataPrivacy', e.target.checked)}
                                        value={data.dataPrivacy || false}
                                      />
                                    }
                                    text={
                                      <p style={{ color: 'grey', marginBottom: 0 }}>
                                        Ich bin mit der <a style={{ color: 'black', textDecoration: 'underline' }} href='https://felixmedicus.de/datenschutz' rel='noopener noreferrer' target='_blank'>Datenschutzerklärung</a> & den <a style={{ color: 'black', textDecoration: 'underline' }} href='https://felixmedicus.de/agb' rel='noopener noreferrer' target='_blank'>AGBs</a> einverstanden
                                      </p>
                                  }
                                  />
                                  <br />
                                  <Grid container>
                                    <Grid item xs={6}><WizardButton scale={1.5} onClick={() => setStep(1)} style={{ float: 'left' }}>Zurück</WizardButton></Grid>
                                    <Grid item xs={6}><WizardButton scale={1.5} onClick={() => makeSignup(data)} style={{ float: 'right' }}>{loading && <CircularProgress size={20} />}&nbsp;Kostenlos registrieren</WizardButton></Grid>
                                  </Grid>

                                </>
                              )
                              }
                          </div>
                        </>
                        )
                  }
              </>
              )
        }
          </div>
        </FullHeightGrid>
      </Grid>
    </>
  );
};

export default SignupWindow;
