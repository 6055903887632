import { Grid, Rating } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { createFeedback } from '../api/methods';
import { AuthContext } from '../context/Auth';
import { WindowManagerContext } from '../context/WindowManager';
import WizardButton from './buttons/WizardButton';
import InputField from './forms/InputField';

const ChangeDataInput = ({ close, save, onlyClose }) => {
  const [value, setValue] = React.useState('');
  return (
    <>
      <p>Bitte schreibe noch 1, 2 Worte hierzu. Das würde uns extrem helfen:</p>
      <Grid container>
        <Grid item xs={12} xl={12}>
          <InputField
            multiline
            fullWidth
            rows={6}
            label='Dein Feedback'
            onChange={(e) => setValue(e.currentTarget.value)}
            value={value}
          />
        </Grid>
        <Grid item xs={12} xl={12}>
          <br />
          <div style={{ maxWidth: 400, float: 'right' }}>
            <WizardButton style={{ background: '#F6BFC0' }} scale={1.5} onClick={() => { onlyClose(); }}>Abbrechen</WizardButton>
              &nbsp;&nbsp;
            <WizardButton scale={1.5} onClick={() => { save(value); }}>Speichern</WizardButton>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const FeedbackComponent = ({ show, onClose, style = {}, result, type }) => {
  const [rating, setRating] = useState(null);
  const { openWindow } = useContext(WindowManagerContext);
  const { api } = useContext(AuthContext);

  const save = async (text = 'Kein Kommentar') => {
    api(jwt => createFeedback({
      rating,
      text,
      type: type || 'general',
      result: result || ''
    }, jwt));
    onClose();
  };

  useEffect(() => {
    if (rating) {
      openWindow('notification', {
        header: '',
        text: close => (
          <>
            <ChangeDataInput
              close={() => save()}
              save={text => { save(text); close(); }}
              onlyClose={close}
            />
          </>
        ),
        maxWidth: 400
      });
    }
  }, [rating]);

  return (
    <>
      <Rating
        name='simple-controlled'
        precision={0.5}
        value={rating || 0}
        onChange={(event, newValue) => {
          setRating(newValue);
        }}
        style={style}
        size='large'
      />
    </>
  );
};

export default FeedbackComponent
;
