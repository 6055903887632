import React, { useContext } from 'react';
import { AuthContext } from '../context/Auth';
import WindowWrapper from './WindowWrapper';
import Logo from '../assets/img/loading_transp.gif';
import LoadingIcon from '../assets/img/loading_transp.gif';
import { WindowManagerContext } from '../context/WindowManager';
import { TrackingContext } from '../context/Tracking';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { Grid, Tooltip } from '@mui/material';
import SelectField from '../components/forms/SelectField';
import { getFiles } from '../api/methods';
import Upload from '../components/forms/Upload';
import MedData from '../assets/datasets/MedData';
import UploadItemsHolder from '../misc/UploadItemsHolder';
import FileAttachment from '../components/misc/FileAttachment';
import Check from '@mui/icons-material/Check';
import fileDownload from 'js-file-download';
import Visibility from '@mui/icons-material/Visibility';
import axios from 'axios';

const UploadWindow = ({ style, close, className, type, userFiles = [], addFile, removeFile, hideAvailable = false, ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  const { auth, api } = useContext(AuthContext);
  const { t } = useContext(TrackingContext);

  const [fileData, setFileData] = React.useState({
    type: type || MedData.documentTypes.filter(dt => !dt.hide).map(dt => dt.name)[0]
  });
  const [availableFiles, setAvailableFiles] = React.useState([]);
  const [downloadLoading, setDownloadLoading] = React.useState(false);

  const {
    name,
    headline,
    description,
    required,
    maxFiles,
    hide,
    allowed
  } = MedData.documentTypes.find(d => d.name === fileData.type) || {};

  const changeData = (key, value) => {
    setFileData(old => ({ ...old, [key]: value }));
  };

  const getAvailableFiles = () => {
    getFiles({ types: [fileData.type] }, auth.jwt)
      .then(files => setAvailableFiles(files));
  };

  React.useEffect(() => {
    // t('action', 'opened_login', props.justLoggedOut ? 'from_logout' : '');
    getAvailableFiles();
  }, [fileData.type]);

  const addFileToUpload = file => { if (addFile) addFile(file); setAvailableFiles(old => [...old, file]); if (maxFiles === 1) close(); };

  return (
    <>
      <div
        maxWidth={600}
        style={{ maxWidth: 600, ...style }}
        close={close}
      >
        {
          auth.loggedIn
            ? (
              <>
                <h2>{fileData.type ? name : 'Datei'} hochladen</h2>
                <Grid container>
                  <Grid item xs={12} md={12} lg={9} xl={9}>
                    {
                      !(fileData.type !== null && MedData.documentTypes.filter(dt => dt.hide).map(dt => dt.name).includes(fileData.type)) && (
                        <>
                          <SelectField
                            id='file-type'
                            label='Dokumenten-Typ'
                            variant='outlined'
                            value={fileData.type || ''}
                            onChange={(event) => {
                              changeData('type', event.target.value);
                            }}
                            items={MedData.documentTypes.filter(dt => !dt.hide).map(d => ({ value: d.name, label: d.name }))}
                            fullwidth
                          />
                          <br /><br />
                        </>
                      )
                    }
                  </Grid>
                  <span style={{ color: 'grey' }}>{description}</span>
                  {description ? (<><br /><br /><br /></>) : <br />}
                  {
                    (!fileData.type || (userFiles.length < maxFiles)) && (
                      <Grid item xs={12} md={12} xl={12}>
                        <>
                          <Upload
                            key={fileData.type}
                            maxFiles={maxFiles}
                            type={fileData.type}
                            description={description}
                            uploadItem={(props) => <UploadItemsHolder {...props} toUpload />}
                            name={name}
                            jwt={auth.jwt}
                            allowed={allowed}
                            onUploadedFile={addFileToUpload}
                          />
                        </>
                      </Grid>
                    )
                  }
                  <Grid item xs={12} md={12} xl={12}>
                    {
                    (!hideAvailable && availableFiles.length > 0) && (
                      <>
                        <h2>Vorhandene Dateien</h2>
                        <div style={{ display: 'flex', flexFlow: 'row wrap', marginTop: 15 }}>
                          {
                          availableFiles[0].type === fileData.type
                            ? availableFiles.map((file, idx) => {
                                return (
                                  <Tooltip
                                    title={file?.name || 'Lädt'}
                                    key={idx}
                                  >
                                    <FileAttachment
                                      file={file}
                                      label={file.name}
                                      actions={[
                                        ...addFile
                                          ? [{
                                              label: 'Auswählen',
                                              action: () => { addFile(file); close(); },
                                              icon: <Check style={{ marginLeft: 10, fontSize: 15, color: 'green' }} />
                                            }]
                                          : [],
                                        {
                                          label: 'Ansehen',
                                          action: () => api(jwt => { setDownloadLoading(true); file.downloadBlob(jwt, () => {}).then(file => fileDownload(file, file.name)).finally(() => setDownloadLoading(false)); }),
                                          icon: downloadLoading ? <img src={LoadingIcon} width={15} /> : <Visibility style={{ marginLeft: 10, fontSize: 15, color: 'grey' }} />
                                        }
                                      ]}
                                      style={{ width: 'auto' }}
                                    />
                                  </Tooltip>
                                );
                              })
                            : <><br /><br /><img src={LoadingIcon} width={25} /></>
                        }
                        </div>
                      </>
                    )
                  }
                  </Grid>

                  <Grid item xs={12} md={12} xl={12}>
                    <PrimaryButton onClick={() => close()} style={{ float: 'right' }}>Schließen</PrimaryButton>
                  </Grid>
                </Grid>
              </>
              )
            : (
              <>
                <center><img src={Logo} width={80} /></center>
                <br />
                <center><h2>Du bist leider nicht eingeloggt.</h2></center>
                <br />

                <center>
                  <PrimaryButton onClick={() => openWindow('login')}>Jetzt einloggen</PrimaryButton>
                </center>
              </>
              )
        }
      </div>
    </>
  );
};

export default UploadWindow;
