import './App.css';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import { Auth } from './context/Auth';
import { WindowManager } from './context/WindowManager';
import { AppInfo } from './context/AppInfo';
import { CookiesProvider } from 'react-cookie';
import { Tracking } from './context/Tracking';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ApplicationRouter from './components/ApplicationRouter';
import { AppData } from './context/AppData';
import { ApplicationData } from './context/ApplicationData';

function App (props) {
  return (
    <WindowManager>
      <Auth>
        <CookiesProvider>
          <Tracking>
            <AppInfo>
              <AppData>
                <ApplicationData>
                  <ThemeProvider theme={theme}><ApplicationRouter {...props} /></ThemeProvider>
                </ApplicationData>
              </AppData>
            </AppInfo>
          </Tracking>
        </CookiesProvider>
      </Auth>
    </WindowManager>
  );
}

export default App;
