import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { TrackingContext } from '../context/Tracking';
import { WindowManagerContext } from '../context/WindowManager';
import LoadingIcon from '../assets/img/loading_transp.gif';
import ApplicationHolder from './ApplicationHolder';
import ApplicationWizardHolder from './wizard/ApplicationWizardHolder';
import JobApplications from './pages/JobApplications';
import WindowsHolder from '../windows/WindowsHolder';
import { ApplicationDataContext } from '../context/ApplicationData';
import JobCoverLetters from './pages/JobCoverLetters';
import Files from './pages/Files';
import { AuthContext } from '../context/Auth';
import Profile from './pages/Profile';
import Credits from './pages/Credits';

const ApplicationRouter = props => {
  const { auth, user } = useContext(AuthContext);
  const { openWindow } = useContext(WindowManagerContext);
  /* const { openWindow } = useContext(WindowManagerContext);
  const { setAcceptedCookies } = useContext(TrackingContext); */

  /* {
    (ready && auth)
      ? (
        <WindowsHolder>
          <Child params={params} setParams={changeParams} />
          <CookieConsentFelixMedicus />
        </WindowsHolder>
        )
      : <center><img src={Loader} width={50} style={{ marginTop: window.innerHeight / 2 - 50 }} /></center>
  } */

  /* useEffect(() => {
    if (auth) {
      if (!auth.loggedIn) openWindow('login');
    }
  }, [auth]); */

  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/' element={<Navigate to={'/home' + window.location.search} />} />
          <Route path='home' element={<ApplicationHolder key={1} />} />
        </Routes>
      </Router>
      <WindowsHolder />
    </>
  );
};

export default ApplicationRouter;
