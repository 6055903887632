
import { Refresh } from '@mui/icons-material';
import { Container, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { getAllQueuedItems } from '../../api/methods';
import { AuthContext } from '../../context/Auth';
import { WindowManagerContext } from '../../context/WindowManager';
import WizardButton from '../buttons/WizardButton';
import ApplicationHeader from '../main-view/ApplicationHeader';
import ArztbriefIcon from '../misc/ArztbriefIcon';
import FileList from '../misc/FileList';
import NoAuthInfoBox from '../misc/NoAuthInfoBox';
import helper from '../../assets/helper';

const Overview = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'left',
  alignItems: 'center',
  paddingLeft: '0px!important',
  paddingRight: '0px!important',
  marginLeft: '-20px!important',
  marginRight: '0px!important'
}));

const ArztbriefOverview = ({ releaseCurrentItem, changeState, loadArztbrief, generateArztbriefMethod, ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  const { auth, api } = useContext(AuthContext);
  const [arztbriefe, setArztbriefe] = useState([]);
  const [prevArztbriefe, setPrevArztbriefe] = useState([]);
  const [loading, setLoading] = useState(true);

  const getQueuedItems = () => {
    if (arztbriefe.length === 0) setLoading(true);
    api(jwt => getAllQueuedItems(jwt))
      .then(newArztbriefe => {
        setPrevArztbriefe(
          old => newArztbriefe
        );
      })
      .catch(console.error)
      .finally(() => {
        if (arztbriefe.length === 0) {
          setLoading(false);
        }
      });
    /** {
            if (!helper.sameArrays(old.map(a => a.task_id), newArztbriefe.map(a => a.task_id))) {
              console.log('hä?', old.map(a => a.task_id), newArztbriefe.map(a => a.task_id));
              return newArztbriefe;
            }
            return old
          } */
  };

  useEffect(() => {
    if (JSON.stringify(arztbriefe) !== JSON.stringify(prevArztbriefe)) {
      setArztbriefe(prevArztbriefe);
    }
  }, [prevArztbriefe]);

  useEffect(() => {
    getQueuedItems();

    const intervalId = window.setInterval(() => { // assign interval to a variable to clear it.
      getQueuedItems();
    }, 6000);

    return () => window.clearInterval(intervalId); // This is important
  }, []);

  return (
    <>
      <Grid container alignItems='center'>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <h2 style={{ margin: 0 }}>Arztbriefe&nbsp;
            {/* <a onClick={() => getQueuedItems()} style={{ cursor: 'pointer' }}>
              <Refresh sx={{ animation: loading ? 'rotation 2s infinite linear' : null, ':hover': { color: 'blue' }, marginTop: 5 }} />
            </a> */}
            {/* {refreshed && (<span style={{ fontSize: 12, fontWeight: 400, color: 'rgba(0,0,0,0.6)' }}>{helper.getGermanTimeDifference(refreshed)}</span>)} */}
          </h2>
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}><WizardButton onClick={() => { releaseCurrentItem(); changeState('input'); }} scale={2.5} style={{ background: 'rgba(167, 255,146, 0.5)', float: 'right' }}>Neuer Arztbrief 🚀</WizardButton></Grid>
      </Grid>

      <Overview>
        {
            arztbriefe.map((arztbrief, i) => {
              return (
                <ArztbriefIcon {...arztbrief} key={i} reloadArztbriefe={getQueuedItems} regenerate={() => generateArztbriefMethod('', '', arztbrief)} onClick={() => loadArztbrief(arztbrief.task_id)} />
              );
            })
        }
        {
          (loading && arztbriefe.length === 0) && (
            [0, 1, 2, 3, 4, 5, 6].map((_, i) => {
              return (
                <Container
                  key={i}
                  style={{
                    width: 'fit-content',
                    marginLeft: 0,
                    paddingLeft: '0px!important',
                    paddingRight: '0px!important',
                    margin: 6,
                    marginTop: 32
                  }}
                >
                  <Skeleton style={{ margin: '0px auto' }} variant='rectangular' width={160} height={160 * 1.39} />
                  <Skeleton style={{ margin: '0px auto' }} variant='text' width={180} />
                  <Skeleton style={{ margin: '0px auto' }} variant='text' width={120} />
                </Container>
              );
            })
          )
        }
      </Overview>
    </>
  );
};

export default ArztbriefOverview;
