import React, { useContext, useState } from 'react';
import WindowWrapper from './WindowWrapper';
import LoadingIcon from '../assets/img/loading_transp.gif';
import { Grid } from '@mui/material';
import SelectField from '../components/forms/SelectField';
import IconWithText from '../components/misc/IconWithText';
import DomainData from '../assets/datasets/DomainData';
import InputField from '../components/forms/InputField';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { AuthContext } from '../context/Auth';

const ApplicantDataWindow = ({ style, close, maxWidth, className, saveData, ...props }) => {
  const { user = {} } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: user?.title,
    sex: user?.sex,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    street: user?.address?.street || '',
    postalCode: user?.address?.postalCode || '',
    city: user?.address?.city || '',
    country: user?.address?.country || 'Deutschland'
  });

  const changeData = (key, value) => {
    setFormData(old => ({ ...old, [key]: value }));
  };

  const saveApplicantData = async (data) => {
    if (!formData.sex) { alert('Bitte gib\' dein Geschlecht an'); return; }
    if (!formData.firstName) { alert('Bitte gib\' deinen Vornamen an'); return; }
    if (!formData.lastName) { alert('Bitte gib\' deinen Nachnamen an'); return; }
    if (!formData.email) { alert('Bitte gib\' deine E-Mail-Adresse an'); return; }
    if (!formData.street) { alert('Bitte gib\' deine Straße mit Hausnummer an'); return; }
    if (!formData.postalCode) { alert('Bitte gib\' deine PLZ an'); return; }
    if (!formData.city) { alert('Bitte gib\' deine Stadt an'); return; }
    try {
      setLoading(true);
      const applicantData = {
        sex: data.sex,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        address: {
          street: data.street,
          postalCode: data.postalCode,
          city: data.city,
          country: data.country
        }
      };

      await saveData(applicantData);

      close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        style={{ maxWidth, ...style }}
      >
        <h2>Deine persönlichen Daten</h2>
        <p>Gib uns deine persönlichen Daten, damit die Absender:innen-Daten korrekt sind.</p>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={6}>
            <SelectField
              id='applicant-sex'
              label='Geschlecht'
              variant='outlined'
              fullWidth
              value={formData.sex || ''}
              onChange={(event) => {
                changeData('sex', event.target.value);
              }}
              items={DomainData.sex}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <SelectField
              id='applicant-title'
              label='Titel'
              variant='outlined'
              fullWidth
              value={formData.title || ''}
              onChange={(event) => {
                changeData('title', event.target.value);
              }}
              items={DomainData.docTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <InputField
              id='applicant-firstname'
              label='Vorname'
              placeholder='Manuela'
              variant='outlined'
              fullWidth
              value={formData.firstName}
              onChange={(event) => {
                changeData('firstName', event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <InputField
              id='applicant-lastName'
              label='Nachname'
              placeholder='Mustermann'
              variant='outlined'
              fullWidth
              value={formData.lastName}
              onChange={(event) => {
                changeData('lastName', event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <InputField
              id='applicant-email'
              label='E-Mail-Adresse'
              placeholder='manuela.mustermann@xyz.de'
              variant='outlined'
              fullWidth
              value={formData.email}
              disabled={!!user?.email}
              onChange={(event) => {
                changeData('email', event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <InputField
              id='applicant-street'
              label='Straße & Hausnummer'
              placeholder='Straße & Hausnummer'
              variant='outlined'
              fullWidth
              value={formData.street}
              onChange={(event) => {
                changeData('street', event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <InputField
              id='applicant-postal-code'
              label='Postleitzahl'
              placeholder='XXXXXX'
              variant='outlined'
              fullWidth
              value={formData.postalCode}
              onChange={(event) => {
                changeData('postalCode', event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <InputField
              id='applicant-city'
              label='Stadt'
              placeholder='Köln'
              variant='outlined'
              fullWidth
              value={formData.city}
              onChange={(event) => {
                changeData('city', event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <IconWithText
              style={{ float: 'right' }}
              icon={loading && <img src={LoadingIcon} style={{ float: 'right' }} width={20} />}
              text={<PrimaryButton style={{ float: 'right' }} onClick={() => saveApplicantData(formData)}>Daten speichern</PrimaryButton>}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ApplicantDataWindow;
