import React from 'react';
import WindowWrapper from './WindowWrapper';
import LoadingIcon from '../assets/img/loading_transp.gif';
import CoverLetterWizardHolder from '../components/cover-letter-wizard/CoverLetterWizardHolder';

const CoverLetterWindow = ({ style, close, className, saveCoverLetter, saveData, ...props }) => {
  return (
    <>
      <WindowWrapper
        style={{ ...style, width: '100%', height: '100%', overflow: 'none', padding: 0 }}
        close={close}
      >
        <CoverLetterWizardHolder
          applicationData={props.applicationData}
          coverLetterId={props.coverLetterId}
          saveCoverLetter={saveCoverLetter}
          close={close}
        />
      </WindowWrapper>
    </>
  );
};

export default CoverLetterWindow;
