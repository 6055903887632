import { Container, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { generateArztbriefWithoutAuth, createArztbriefQueueItem } from '../../api/methods';
import { AppDataContext } from '../../context/AppData';
import { AuthContext } from '../../context/Auth';
import { WindowManagerContext } from '../../context/WindowManager';
import WizardButton from '../buttons/WizardButton';
import InputField from '../forms/InputField';
import SelectField from '../forms/SelectField';
import TeaserContent from '../misc/TeaserContent';
import { styled, useTheme } from '@mui/material/styles';
import DomainData from '../../assets/datasets/DomainData';

const ButtonContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex'
  },
  marginRight: 0,
  width: 'fit-content',
  paddingTop: 22,
  paddingRight: '0px!important',
  paddingLeft: '0px!important'
}));

const ExampleComponent = ({ save, onlyClose }) => {
  const [type, setType] = React.useState('ITS anch Operation');
  return (
    <>
      <p>Das Beispiel überschreibt deine Eingaben.</p>
      <Grid container>
        <Grid item xs={12} xl={12}>
          <SelectField
            fullWidth
            label='Beispiel'
            items={DomainData.arztbriefExamples.map(e => ({ value: e.type, label: e.type }))}
            onChange={(e) => setType(e.target?.value)}
            value={type}
          />
        </Grid>
      </Grid>
      <Container style={{ marginRight: 0, width: 'fit-content', paddingTop: 22, paddingRight: 0 }}>
        <WizardButton scale={1.5} style={{ background: '#f6bfc0' }} onClick={() => { onlyClose(); }}>❌ Abbrechen</WizardButton>
        &nbsp;&nbsp;&nbsp;
        <WizardButton scale={1.5} onClick={() => { save(type); }}>👉 Einfügen</WizardButton>
      </Container>
    </>
  );
};

const InputPage = ({ openWindowToDepositCredit, currentItem, setArztbrief, openLoadingWindow, generateArztbriefMethod, changeState, ...props }) => {
  const [anamnese, setAnamnese] = React.useState('');
  const [verlauf, setVerlauf] = React.useState('');
  const [geschlecht, setGeschlecht] = React.useState('männlich');
  const { auth = {}, api } = useContext(AuthContext);
  const { openWindow, closeWindow } = useContext(WindowManagerContext);

  const [rows, setRows] = useState(Math.floor((((window.innerHeight - 75) * 0.9) - 320) / 46));

  const setExample = (type) => {
    const { anamnese, verlauf } = DomainData.arztbriefExamples.find(e => e.type === type)?.example || DomainData.arztbriefExamples[0].example;
    setAnamnese(anamnese);
    setVerlauf(verlauf);
  };

  useEffect(() => {
    function handleResize () {
      if (window.innerHeight > window.innerWidth) {
        setRows(10);
      } else {
        setRows(Math.floor((((window.innerHeight - 75) * 0.9) - 320) / 46));
      }
    }

    window.addEventListener('resize', handleResize);
    return _ => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerHeight]);

  useEffect(() => {
    if (currentItem) {
      setAnamnese(currentItem.original_prompt?.anamnese);
      setVerlauf(currentItem.original_prompt?.verlauf);
    }
  }, currentItem);

  const openGenerationWindow = (generate) => {
    console.log(generate);
    openWindow('notification', {
      header: 'Keine Personendaten genutzt?',
      text: close => (
        <>
          <p>Wir weisen noch einmal darauf hin, dass KEINE Daten eingegeben werden dürfen, mit denen auf den Patienten rückgeschlossen werden kann.</p>

          <Container style={{ marginRight: 0, width: 'fit-content', paddingTop: 22, paddingRight: 0 }}>
            <WizardButton scale={1.5} style={{ background: '#f9eeb1' }} onClick={() => { close(); }}>Ich schaue nochmal...</WizardButton>
            &nbsp;&nbsp;&nbsp;
            <WizardButton scale={1.5} onClick={() => { close(); openLoadingWindow(); generate(); }}>Ja, überprüft!</WizardButton>
          </Container>
        </>
      ),
      maxWidth: 400
    });
  };

  const openExampleWindow = () => {
    openWindow('notification', {
      header: 'Beispiel einfügen',
      text: close => (
        <>
          <ExampleComponent
            save={type => { setExample(type); close(); }}
            onlyClose={close}
          />
        </>
      ),
      maxWidth: 400
    });
  };

  return (
    <>
      <Container style={{ fontWeight: 500, padding: 10, backgroundColor: '#FFBFBF', width: 'fit-content', marginLeft: 0 }}>
        ⚠️ Keine persönlichen Daten eingeben, mit denen auf den Patienten geschlossen werden könnte!
      </Container>
      <h2>Anamnese</h2>
      <InputField
        multiline
        fullWidth
        rows={rows}
        style={{ background: 'white' }}
        label=''
        placeholder={'- Vorstellung wegen Husten und Fieber seit 06.04.2023\n- …'}
        onChange={(e) => setAnamnese(e.currentTarget.value)}
        value={anamnese}
        id='anamnese'
      />
      <h2>Verlauf</h2>
      <InputField
        multiline
        fullWidth
        rows={rows}
        style={{ background: 'white' }}
        label=''
        placeholder={'- stationäre Aufnahme am 10.04.2023\n- empirische Therapie mit Meropenem 10.04.2023-14.04.2023\n- resistogrammgerechte Umstellung ….'}
        onChange={(e) => setVerlauf(e.currentTarget.value)}
        value={verlauf}
        id='verlauf'
      />
      <Container style={{ marginRight: 0, width: 'fit-content', paddingTop: 22, paddingRight: 0 }}>
        {
            currentItem
              ? (
                <>
                   &nbsp;&nbsp;&nbsp;
                  <WizardButton scale={2.5} style={{ background: 'rgba(255, 218, 24, 0.54)' }} onClick={() => changeState('arztbrief')}>{'<'} Zurück zum Arztbrief</WizardButton>
                </>
                )
              : (
                <WizardButton scale={2.5} style={{ background: 'rgba(255, 218, 24, 0.54)' }} onClick={() => openExampleWindow()}>👉 Beispiel nutzen</WizardButton>
                )
        }

        &nbsp;&nbsp;&nbsp;
        <WizardButton onClick={() => openGenerationWindow(generateArztbriefMethod(anamnese, verlauf))} scale={2.5} style={{ background: 'rgba(167, 255, 146, 0.5)' }}>{currentItem ? 'Neu generieren 🚀' : 'Generieren 🚀'}</WizardButton>
      </Container>
    </>
  );
};

export default InputPage;
