import { Container } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/Auth';
import { WindowManagerContext } from '../../context/WindowManager';
import WizardButton from '../buttons/WizardButton';
import FeedbackComponent from '../FeedbackComponent';
import InputField from '../forms/InputField';

const ArztbriefView = ({ changeState, currentItem, arztbrief, setArztbrief, saveNewResult, ...props }) => {
  const [copied, setCopied] = React.useState(false);
  const { auth = {}, api } = useContext(AuthContext);
  const { openWindow, closeWindow } = useContext(WindowManagerContext);

  const [rows, setRows] = useState(Math.floor((((window.innerHeight - 75) * 0.9) - 300) / 46));

  useEffect(() => {
    function handleResize () {
      setRows(Math.floor((((window.innerHeight - 75) * 0.9) - 300) / 46));
    }

    window.addEventListener('resize', handleResize);
    return _ => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerHeight]);

  const waitAndSetCopy = (milliseconds = 5000) => {
    const myInterval = setInterval(() => setCopied(false), milliseconds);
  };

  return (
    <>
      <h2>Dein Arztbrief</h2>
      <p style={{ fontSize: 'smaller', color: 'grey', fontFamily: 'Work Sans' }}>
        <b>Disclaimer:</b> Trotz fortlaufender Qualitätsverbesserung können wir selbstverständlich keine Haftung für die Qualität der automatisch generierten Arztbriefvorschläge übernehmen.
        Für den fertigen Arztbrief ist der jeweilige Arzt und die jeweilige Ärztin verantwortlich.
      </p>
      <InputField
        multiline
        fullWidth
        rows={rows * 2}
        style={{ background: 'white' }}
        label=''
        onChange={(e) => setArztbrief(e.currentTarget.value)}
        value={arztbrief}
      />
      <Container style={{ marginRight: 0, width: 'fit-content', display: 'flex', alignItems: 'center', paddingTop: 22, paddingRight: 0 }}>
        <FeedbackComponent result={arztbrief} type='prompt' />
        &nbsp;&nbsp;&nbsp;
        <WizardButton scale={2.5} style={{ background: 'rgba(255, 218, 24, 0.54)' }} onClick={() => changeState('input')}>Notizen anpassen 🖊️</WizardButton>
        &nbsp;&nbsp;&nbsp;
        <WizardButton onClick={() => { navigator.clipboard.writeText(arztbrief); setCopied(true); waitAndSetCopy(5000); }} scale={2.5} style={{ background: copied ? 'white' : 'rgba(167, 255, 146, 0.5)' }}>{copied ? 'Kopiert! 🎉' : 'Kopieren 📋'}</WizardButton>
        {
          (!!currentItem && arztbrief !== currentItem.result) && (
            <>
              &nbsp;&nbsp;&nbsp;
              <WizardButton onClick={() => saveNewResult()} scale={2.5} style={{ background: 'white' }}>Speichern</WizardButton>
            </>
          )
        }
      </Container>
    </>
  );
};

export default ArztbriefView;
