import React, { useContext } from 'react';
import TextModuleBox from '../TextModuleBox';
import InputField from '../../forms/InputField';
import MedData from '../../../assets/datasets/MedData';
import CoverLetterWizardFooter from '../CoverLetterWizardFooter';
import { ApplicationDataContext } from '../../../context/ApplicationData';

const variants = MedData.textModules.cause;

const Cause = ({ coverLetterData, previousStep, nextStep, saveData, ...props }) => {
  const [textModules, setTextModules] = React.useState({});
  const [checked, setChecked] = React.useState(null);

  const { data: applicationData } = useContext(ApplicationDataContext);
  const { applicationCause, employerData } = applicationData;
  const { cause: relevantCoverLetterData } = coverLetterData;

  React.useEffect(() => {
    // Wenn ein Feld gecheckt wird, ändert sich der Text für den Teil des Anschreibens
    if (checked !== null) {
      saveData({ textModules, checked, text: textModules[checked] });
    } else {
      saveData({ });
    }
  }, [checked, textModules[checked]]);

  React.useEffect(() => {
    if (relevantCoverLetterData?.textModules) setTextModules(old => ({ ...old, ...relevantCoverLetterData.textModules }));
    if (relevantCoverLetterData?.checked !== null) setChecked(old => relevantCoverLetterData?.checked);
  }, []);

  const setPartText = (idx, text) => {
    setTextModules(old => ({ ...old, [idx]: text }));
  };

  const goToNextStep = () => {
    if (checked !== null) {
      nextStep();
    } else {
      alert('Bitte wähle einen der Textbausteine aus.');
    }
  };

  return (
    <>
      <h3>Wähle aus:</h3>

      {
        variants.map((cb, idx) => {
          return (
            <TextModuleBox
              headline={cb.title}
              key={'tmb' + idx}
              checked={checked === idx}
              saveData={() => { setChecked(idx); setPartText(idx, textModules[idx] || cb.text(applicationCause || 'initiativ')); }}
            >
              <InputField
                key={'input-tmb-' + idx}
                name={'input-tmb-' + idx}
                id={'input-tmb-' + idx}
                label='Ändere gerne deinen Text'
                multiline
                fullWidth
                rows={6}
                onChange={(e) => setPartText(idx, e?.currentTarget?.value || '')}
                value={textModules[idx] || cb.text(applicationCause || 'ownInitiative')}
                /* style={{ maxHeight: 200, padding: 10, marginBottom: 0 }} */
              />
            </TextModuleBox>
          );
        })
      }
      <CoverLetterWizardFooter
        previous={previousStep}
        next={goToNextStep}
      />

    </>
  );
};

export default Cause;
