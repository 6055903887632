import React from 'react';
import {
  TwitterShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon
} from 'react-share';

const ShareButtons = ({
  title = 'Arztbriefe generieren auf Felix Medicus',
  summary = 'Mit einem Klick Arztbriefe erstellen',
  hashtags = ['felixmedicus', 'glücklichemedis'],
  separator = ' ',
  url = 'https://arztbriefgenerator.felixmedicus.de'
}) => {
  const shareProps = {
    url,
    beforeOnClick: () => {},
    // only email
    subject: title,
    // only facebook
    hashtag: hashtags[0],
    title: title + ': ' + summary,
    // Description of the shared page (Linkedin)
    summary,
    // only twitter
    hashtags,
    // (string, default=" "): Separates title from the url (Whatsapp)
    separator
  };

  return (
    <table style={{ width: '100%', margin: '0 auto' }}>
      <tbody>
        <tr>
          <td><TwitterShareButton {...shareProps}><TwitterIcon /></TwitterShareButton></td>
          <td><FacebookShareButton {...shareProps}><FacebookIcon /></FacebookShareButton></td>
          <td><LinkedinShareButton {...shareProps}><LinkedinIcon /></LinkedinShareButton></td>
          <td><TelegramShareButton {...shareProps}><TelegramIcon /></TelegramShareButton></td>
          <td><WhatsappShareButton {...shareProps}><WhatsappIcon /></WhatsappShareButton></td>
        </tr>
      </tbody>
    </table>
  );
};

export default ShareButtons;
