import { CircularProgress } from '@mui/material';
import fileDownload from 'js-file-download';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import PrimaryButton from '../components/buttons/PrimaryButton';
import dataURLtoBlob from 'blueimp-canvas-to-blob'


const SignatureWindow = ({ style, close, minWidth, maxWidth, width, onSave, className, ...props }) => {
  const sigCanvas = useRef({});
  const [loading, setLoading] = useState(false);

  /* a function that uses the canvas ref to clear the canvas
  via a method given by react-signature-canvas */
  const clear = () => sigCanvas.current.clear();

  /* a function that uses the canvas ref to trim the canvas
  from white spaces via a method given by react-signature-canvas
  then saves it in our state */
  const save = async () => {
    setLoading(true);
    await onSave(dataURLtoBlob(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')), close);
    setLoading(false);
  };

  return (
    <>
      <div style={{ maxWidth, minWidth, width }}>
        <h2>Unterschreiben</h2>
        <p>Du kannst auf diesem Feld hier für deine Bewerbung unterschreiben:</p>
        {
          !loading
            ? (
              <div style={{ border: '1px solid grey', borderRadius: 2 }}>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor='blue'
                  canvasProps={{ width: 500, height: 200 }}
                />
              </div>
              )
            : (
              <div style={{ width: 500, height: 200, paddingTop: 90 }}>
                <center>
                  <CircularProgress />
                </center>
              </div>
              )
        }

        <br />
        <PrimaryButton
          secondary
          onClick={() => clear()}
          style={{ float: 'left' }}
        >
          Neu anfangen
        </PrimaryButton>
        <PrimaryButton
          onClick={() => save()}
          style={{ float: 'right' }}
        >
          Speichern
        </PrimaryButton>
      </div>
    </>
  );
};

export default SignatureWindow;
