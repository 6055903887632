
import { Checkbox } from '@mui/material'
import React, { useContext, useState } from 'react'
import helper from '../../assets/helper'
import { AuthContext } from '../../context/Auth'
import { TrackingContext } from '../../context/Tracking'
import PrimaryButton from '../buttons/PrimaryButton'
import LoadingIcon from '../../assets/img/loading_transp.gif'
import IconWithText from '../misc/IconWithText'
import { saveAs } from 'file-saver'
import CheckIcon from '@mui/icons-material/Check'
import FileAttachment from '../misc/FileAttachment'
import Delete from '@mui/icons-material/Delete'

const mimeTypeToImage = helper.mimeTypeToImage
const shortenName = helper.shortenString

const Download = ({ files: filesToDownload = [], ...props }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [progress, setProgress] = useState({})
  const [files, setFiles] = useState(filesToDownload)
  const { api } = useContext(AuthContext)

  const JSZip = require('jszip')
  const zip = new JSZip()
  const zipFolder = zip.folder('Bewerbung')

  const downloadAll = async () => {
    setStatus('Loading URLs')
    const downloadedFiles = []
    for (const file of files) {
      const downloadedFile = await api(jwt => file.download(jwt, (prg) => setProgress(old => ({ ...old, [file.id]: prg }))))
      setProgress(old => ({ ...old, [file.id]: 100 }))
      downloadedFiles.push(downloadedFile)
      zipFolder.file(file.name, downloadedFile)
    }
    zip.generateAsync({ type: 'blob' })
      .then(content => {
        saveAs(content, 'Bewerbung')
      })
  }

  console.log(files)

  return (
    <>
      {
        files.map((file, idx) => {
          return (
            <FileAttachment
              file={file}
              style={{ borderColor: 'green' }}
              key={idx}
              actions={
                [
                  {
                    label: 'Entfernen',
                    action: () => { setFiles(old => old.filter(f => f.id !== file.id)) },
                    icon: <Delete style={{ marginLeft: 10, fontSize: 15, color: 'red' }} />
                  }
                ]
              }
            />
          )
        })
      }
      {
        loading && <img src={LoadingIcon} width={30} />
      }
      <br />
      <PrimaryButton onClick={() => downloadAll()}>Als ZIP downloaden</PrimaryButton>
    </>
  )
}

/* <IconWithText
            key={idx}
            icon={<img src={mimeTypeToImage(file.mime_type || file.type)} height={24} />}
            text={
              <>
                <span style={{ color: 'grey' }}>{file.name ? shortenName(file.name, 30) : ''}</span>
                  &nbsp;&nbsp;
                {
                  !status
                    ? (
                      <a
                        style={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => setFiles(old => old.filter(f => f.id !== file.id))}
                      >
                        x
                      </a>
                      )
                    : (
                      <>
                        {
                          (progress[file.id] && progress[file.id] <= 100)
                            ? <><img src={LoadingIcon} width={20} height={20} />&nbsp;&nbsp;{progress[file.id] ? progress[file.id] + ' %' : 'Wartet...'}</>
                            : (progress[file.id] >= 99 ? <CheckIcon /> : null)
                        }
                      </>
                      )
                }
              </>
            }
          /> */

export default Download
