import React from 'react';

const IconWithText = ({ onClick, icon, text, style, ...props }) => {
  return (
    <div onClick={onClick} style={{ display: 'flex', alignItems: 'center', cursor: onClick ? 'pointer' : null, ...style }}>
      {icon}{icon && <>&nbsp;</>}{text}
    </div>
  );
};

export default IconWithText;
