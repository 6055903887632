import React from 'react';
import { CircularProgress } from '@mui/material';
import LogoLang from '../assets/img/logo_beta.png';

const LoadingWindow = ({ style, close, minWidth, maxWidth, width, className, content, text, ...props }) => {
  return (
    <>
      <div style={{ maxWidth, minWidth, width }}>
        <center>
          <img src={LogoLang} height={50} />
          <p style={{ color: 'grey' }}>{text || 'Wird geladen...'}</p>
          <p style={{ color: 'grey' }}><CircularProgress size={40} /></p>
        </center>
      </div>
    </>
  );
};

export default LoadingWindow;
