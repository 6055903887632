import React, { useContext, useState, useEffect } from 'react'
import WindowWrapper from './WindowWrapper'
import Grid from '@mui/material/Grid'
import InputField from '../components/forms/InputField'
import PrimaryButton from '../components/buttons/PrimaryButton'
import SelectField from '../components/forms/SelectField'
import DomainData from '../assets/datasets/DomainData'
import { AuthContext } from '../context/Auth'
import { createNewApplication as createNewApplicationAPIMethod } from '../api/methods'
import LoadingIcon from '../assets/img/loading_transp.gif'
import FileAttachment from '../components/misc/FileAttachment'
import Check from '@mui/icons-material/Check'
import Delete from '@mui/icons-material/Delete'
import IconWithText from '../components/misc/IconWithText'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileList from '../components/misc/FileList'
import { WindowManagerContext } from '../context/WindowManager'
import { CircularProgress, Tooltip } from '@mui/material'
import Info from '@mui/icons-material/Info'

const SendApplicationWindow = ({ methods, style, applicationData, close, className, ...props }) => {
  const [formData, setFormData] = useState({
    recipient: applicationData.contactPerson?.email || '',
    emailText: '',
    subject: ''
  })
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState(applicationData.files)
  const [cv, setCv] = useState(null)
  const [cl, setCl] = useState(null)
  const { openWindow } = useContext(WindowManagerContext)

  const changeData = (key, value) => {
    setFormData(old => ({ ...old, [key]: value }))
  }

  const getCurrentCoverLetter = () => {
    if (methods.hasCoverLetter()) {
      methods.getCoverLetterFile()
        .then(coverLetter => setCl(coverLetter))
        .catch(err => console.log(err))
    }
  }

  const getCurrentCV = () => {
    if (methods.hasCoverLetter()) {
      methods.getCVFile()
        .then(cv => setCv(cv))
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    getCurrentCV()
    getCurrentCoverLetter()
  }, [])

  const sendMail = async () => {
    if (!formData.recipient) { alert('Bitte gib noch die E-Mail-Adresse des Arbeitgebers an.'); return }
    if (!formData.emailText) { alert('Bitte gib einen Text für die E-Mail an. Oder lass ihn dir generieren.'); return }
    if (!formData.subject) { alert('Bitte gib noch einen Betreff für die E-Mail an.'); return }
    setLoading(true)
    const sendingInformation = {
      ...formData,
      applicant: {
        firstName: applicationData.personalData?.firstName,
        lastName: applicationData.personalData?.lastName,
        email: applicationData.personalData?.email
      },
      files: [...files.map(f => f.id), ...(cv?.id ? [cv.id] : []), ...(cl?.id ? [cl.id] : [])],
      timestamp: new Date().getTime()
    }
    try {
      await methods.sendMail(sendingInformation)
      close()
    } catch (error) {
      console.log(error)
      if (error.message.indexOf('InvalidParameterValue') > -1) alert('Leider sind das zu viele Anhänge... Kannst du die noch verkleinern?')
    } finally {
      setLoading(false)
    }
  }

  const generateEmailText = () => {
    const generatedText = `${methods.getSalutation()}\n\n${applicationData.applicationCause === 'ownInitiative' ? `anbei erhalten Sie meine Bewerbung${applicationData.jobRole ? ' als ' + applicationData.jobRole : ''}.` : `mit großem Interesse habe ich Ihre Stellenausschreibung${applicationData.jobRole ? ' für die Stelle als ' + applicationData.jobRole : ''} gelesen.`}\nIm Anhang dieser E-Mail finden Sie meine Bewerbungsunterlagen. Gerne stehe ich Ihnen für Rückfragen jederzeit zur Verfügung und freue mich, Sie in einem persönlichen Gespräch von mir zu überzeugen.\n\nMit besten Grüßen,\n${methods.getApplicantName()}`
    changeData('emailText', generatedText)
  }

  const generateSubject = () => {
    const generatedText = `Bewerbung als ${applicationData.jobRole || (applicationData.personalData?.sex === 'm' ? 'Weiterbildungsassistent' : 'Weiterbildungsassistentin')}`
    changeData('subject', generatedText)
  }

  return (
    <>
      <WindowWrapper
        maxWidth={500}
        maxHeight={500}
        style={{ ...style }}
        close={close}
      >
        <div>
          <h2>Bewerbung an den Arbeitgeber senden</h2>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <InputField
                id='recipient'
                label='Empfänger E-Mail-Adresse'
                variant='outlined'
                placeholder='hallo@email.de'
                fullWidth
                value={formData.recipient}
                onChange={(event) => {
                  changeData('recipient', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <a onClick={() => generateSubject()} style={{ float: 'right', cursor: 'pointer', textDecoration: 'underline', marginBottom: 0, fontWeight: 'normal' }}>Betreff generieren</a>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <InputField
                id='subject'
                label='Betreff'
                placeholder='z.B. Bewerbung als Weiterbildungsassistent:in'
                variant='outlined'
                fullWidth
                value={formData.subject}
                onChange={(event) => {
                  changeData('subject', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <a onClick={() => generateEmailText()} style={{ float: 'right', cursor: 'pointer', textDecoration: 'underline', marginBottom: 0, fontWeight: 'normal' }}>E-Mail-Text generieren</a>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <InputField
                id='email-text'
                label='E-Mail-Text'
                placeholder=''
                variant='outlined'
                fullWidth
                multiline
                rows={6}
                value={formData.emailText}
                onChange={(event) => {
                  changeData('emailText', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <div style={{ display: 'flex', flexFlow: 'row wrap', marginTop: 15 }}>
                <Tooltip
                  title={cv?.name || 'Lädt'}
                >
                  <FileAttachment
                    file={cv?.name ? cv : { name: 'Lädt...', type: 'application/pdf' }}
                    style={{ borderColor: 'green' }}
                    actions={
                      cv
                        ? [
                            {
                              label: 'Entfernen',
                              action: () => { setCv({}) },
                              icon: <Delete style={{ marginLeft: 10, fontSize: 15, color: 'grey' }} />
                            }
                          ]
                        : [
                            {
                              label: 'Lädt...',
                              action: () => { },
                              icon: <img src={LoadingIcon} width={20} />
                            }
                          ]
                      }
                  />
                </Tooltip>
                <Tooltip
                  title={cl?.name || 'Lädt'}
                >
                  <FileAttachment
                    file={cl?.name ? cl : { name: 'Lädt...', mimeType: 'application/pdf', type: 'Anschreiben' }}
                    style={{ borderColor: 'green' }}
                    actions={
                      cl
                        ? [
                            {
                              label: 'Entfernen',
                              action: () => { setCl({}) },
                              icon: <Delete style={{ marginLeft: 10, fontSize: 15, color: 'grey' }} />
                            }
                          ]
                        : [
                            {
                              label: 'Lädt...',
                              action: () => { },
                              icon: <img src={LoadingIcon} width={20} />
                            }
                          ]
                      }
                  />
                </Tooltip>
                {
                  files.map((file, idx) => {
                    if (['Lebenslauf', 'Anschreiben'].includes(file.type)) return null
                    return (
                      <Tooltip
                        key={idx}
                        title={file.name}
                      >
                        <FileAttachment
                          file={file}
                          style={{ borderColor: 'green' }}
                          actions={
                        [
                          {
                            label: 'Entfernen',
                            action: () => { methods.removeFileId(file.id); setFiles(old => old.filter(f => file.id !== f.id)) },
                            icon: <Delete style={{ marginLeft: 10, fontSize: 15, color: 'grey' }} />
                          }
                        ]
                      }
                        />
                      </Tooltip>
                    )
                  })
                }
                <IconWithText
                  icon={<AttachFileIcon />}
                  text='Anhang hinzufügen'
                  style={{ paddingBottom: 10, fontSize: '0.8rem' }}
                  onClick={() => openWindow('notification', {
                    maxWidth: 800,
                    header: '',
                    text: close => (
                      <>
                        <h2>Dokumente</h2>
                        <FileList
                          tableProps={['fileObject', 'actions']}
                          mark={files.map(f => f.id)}
                          actions={
                              [
                                {
                                  label: 'Hinzufügen',
                                  action: (file, refresh) => { setFiles(old => { if (!old.map(f => f.id).includes(file.id)) { methods.addFile(file); return [...old, file] } else { return old } }); close() },
                                  icon: <Check style={{ color: 'green', marginLeft: 10 }} />
                                }
                              ]
                            }
                        />
                      </>
                    )
                  })}
                />
              </div>
              <div style={{ float: 'right' }}>
                <IconWithText
                  icon={<Info />}
                  text={'Wie geht\'s danach weiter?'}
                  onClick={() => openWindow('notification', {
                    header: 'Nach dem Absenden',
                    maxWidth: 500,
                    text: close => (
                      <>
                        <p>
                          Deine Bewerbung wird automatisch über Felix Medicus-Server versendet.<br />
                          Wenn die Klinik antwortet, bekommst DU aber die E-Mail an deine E-Mail-Adresse, die du vorher angegeben hast!
                        </p>
                      </>
                    )
                  })}
                  style={{ marginBottom: 8, marginTop: 8 }}
                />
                <PrimaryButton style={{ float: 'right' }} onClick={() => { if (!loading) sendMail(formData) }}>{loading && <CircularProgress size={20} />}&nbsp;Bewerbung absenden</PrimaryButton>
              </div>

            </Grid>

          </Grid>

        </div>
      </WindowWrapper>
    </>
  )
}

export default SendApplicationWindow
