// utils/useDeviceDetect.js
import React from 'react';

export default function useDeviceDetect () {
  const [isMobile, setIsMobile] = React.useState(false);
  const [userAgent, setUserAgent] = React.useState(null);
  React.useEffect(() => {
    const userAgent =
        typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setIsMobile(mobile);
    setUserAgent(userAgent)
  }, []);

  return { isMobile, userAgent };
}
