
import React, { useContext, useState } from 'react';
import WindowWrapper from './WindowWrapper';
import Grid from '@mui/material/Grid';
import InputField from '../components/forms/InputField';
import PrimaryButton from '../components/buttons/PrimaryButton';
import SelectField from '../components/forms/SelectField';
import DomainData from '../assets/datasets/DomainData';
import { AuthContext } from '../context/Auth';
import { createNewApplication } from '../api/methods';
import LoadingIcon from '../assets/img/loading_transp.gif';
import configuration from '../configuration';
import { CircularProgress } from '@mui/material';
import { WindowManagerContext } from '../context/WindowManager';
import IconWithText from '../components/misc/IconWithText';
import InfoIcon from '@mui/icons-material/Info';
import { display } from '@mui/system';

const NewApplicationWindow = ({ style, close, navigate, createSearchParams, className, ...props }) => {
  const { auth, api, decreaseCreditsForUser } = useContext(AuthContext);
  const { openWindow } = useContext(WindowManagerContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    applicationCause: 'ownInitiative',
    jobRole: '',
    expertiseArea: null,
    subject: ''
  });

  const changeData = (key, value) => {
    setFormData(old => ({ ...old, [key]: value }));
  };

  const createApplication = async (applicationData) => {
    try {
      if (!formData.applicationCause) { alert('Bitte gib\' einen Anlass für die Bewerbung an'); return; }
      if (!formData.jobRole) { alert('Bitte gib\' eine Job-Rolle für die Bewerbung an'); return; }
      if (!formData.expertiseArea) { alert('Bitte gib\' einen Fachbereich für die Bewerbung an'); return; }
      if (!formData.subject) { alert('Bitte gib\' einen Betreff für die Bewerbung an'); return; }
      setLoading(true);
      if (auth?.loggedIn) {
        await decreaseCreditsForUser(configuration.applicationPrice);
        const newApplication = await api(jwt => createNewApplication({
          ...formData,
          domainSpecificInformation: { expertiseArea: formData.expertiseArea }
        }, jwt));
        if (newApplication.id) {
          navigate('/applications/new?aid=' + newApplication.id);
          close();
        }
      } else {
        navigate(
          {
            pathname: '/applications/new',
            search: createSearchParams({
              ...formData
            }).toString()
          });
        close();
      }
    } catch (error) {
      if (error.message.indexOf('Credits') > -1) {
        openWindow('notification', {
          header: 'Zu wenig Credits',
          text: close => (
            <>
              <p>Leider hast du zu wenig Credits. Du kannst sie aber schnell aufladen...</p>
              <br />
              <PrimaryButton
                onClick={() => openWindow('creditDeposit', { onClose: () => close() })}
                style={{ float: 'right' }}
              >
                Credits aufladen
              </PrimaryButton>
            </>
          )
        });
      } else {
        alert('Das Erstellen der Bewerbung hat leider nicht funktioniert...');
      }
      setLoading(false);
    }
  };

  const generateSubject = () => {
    const generatedText = `Bewerbung als ${formData.jobRole || 'Weiterbildungsassistent'}${formData.expertiseArea ? ' für den Fachbereich ' + formData.expertiseArea : ''}`;
    changeData('subject', generatedText);
  };

  return (
    <>
      <WindowWrapper
        maxWidth={500}
        maxHeight={500}
        style={{ ...style }}
        close={close}
      >
        <div style={{ padding: 20 }}>
          <h2>Neue Bewerbung</h2>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              <SelectField
                id='application-cause'
                key='application-cause'
                label='Anlass der Bewerbung'
                variant='outlined'
                fullWidth
                value={formData.applicationCause}
                onChange={(event) => {
                  changeData('applicationCause', event.target.value);
                }}
                items={[
                  { value: 'ownInitiative', label: 'Initiativbewerbung' },
                  { value: 'jobOffer', label: 'Stellenanzeige' }
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <InputField
                id='job-role'
                key='job-role'
                label='Rolle im Job'
                variant='outlined'
                placeholder='z.B. Weiterbildungsassistentin'
                fullWidth
                value={formData.jobRole}
                onChange={(event) => {
                  changeData('jobRole', event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <SelectField
                id='expertise-area'
                key='expertise-area'
                label='Fachbereich'
                variant='outlined'
                fullWidth
                value={formData.expertiseArea}
                onChange={(event) => {
                  changeData('expertiseArea', event.target.value);
                }}
                items={DomainData.medAreasOfExpertise}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <a onClick={() => generateSubject()} style={{ float: 'right', cursor: 'pointer', textDecoration: 'underline', marginBottom: 0, fontWeight: 'normal' }}>Betreff generieren</a>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
                  <InputField
                    id='subject'
                    key='subject'
                    label='Betreff'
                    placeholder='z.B. Bewerbung als Weiterbildungsassistent:in'
                    variant='outlined'
                    fullWidth
                    value={formData.subject}
                    onChange={(event) => {
                      changeData('subject', event.target.value);
                    }}
                    onClick={formData.subject ? null : ()=>{generateSubject()}}
                    disabled={formData.subject === ""}
                  />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <IconWithText
                icon={<i>Kosten: {configuration.applicationPrice} Credits</i>}
                text={
                  auth.loggedIn
                    ? (
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => openWindow('notification', {
                          header: 'Was sind Credits?',
                          text: close => (
                            <>
                              <p>
                                Credits sind die Felix Medicus Währung, mit denen du deine Bewerbungen bezahlen kannst.<br />
                                Wenn du einen Account hast, kannst du diese Währung kaufen.
                              </p>
                              <br />
                              <PrimaryButton
                                style={{ float: 'right', margin: 8 }}
                                onClick={() => openWindow('register')}
                              >
                                Account erstellen
                              </PrimaryButton>
                              <PrimaryButton
                                style={{ float: 'right', margin: 8 }}
                                onClick={() => openWindow('login')}
                              >
                                Einloggen
                              </PrimaryButton>
                            </>
                          )
                        })}
                      >
                        <InfoIcon />
                      </a>
                      )
                    : null
                }
                style={{ float: 'right' }}
              />
              <br /><br />
              <PrimaryButton style={{ float: 'right', marginTop: 10 }} onClick={!loading ? () => createApplication(formData) : ()=>{}}>{loading && <CircularProgress size={20} />}&nbsp;Bewerbung erstellen</PrimaryButton>
            </Grid>
          </Grid>
        </div>
      </WindowWrapper>
    </>
  );
};

export default NewApplicationWindow;
