
const neededAttributes = [
  'title',
  'firstName',
  'lastName',
  'email',
  'address'
];

export default class PersonalData {
  constructor (data) {
    this.title = data.title;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.address = data.address;
    this.sex = data.sex;
  }

  createAddress () {}

  update () {
    console.log('update attributes of personal data', { ...this });
  }
}
