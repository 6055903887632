import React from 'react';
import DomainData from './datasets/DomainData';

const isInObject = (object, keyArray) => {
  try {
    let currentObject = object;
    for (const keyIdx in keyArray) {
      const key = keyArray[keyIdx];
      currentObject = currentObject[key];
      if (currentObject == undefined || currentObject == null) throw new Error('not available');
    }
    return true;
  } catch (error) {
    return false;
  }
};

const getTimeDifferenceInDays = (date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime(); // Get the difference in milliseconds
  const days = Math.floor(diff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

  return Math.abs(days);
}


const returnDataModelAttributes = (dataModel, options = { /* include: [], exclude: [], addSpecs: {} */ }) => {
  const attributes = { ...dataModel.attributes };
  let newAttributes = {};
  if (Object.keys(options).includes('include')) {
    newAttributes = filterObjectWithAllowed(attributes, options.include);
  }
  if (Object.keys(options).includes('exclude')) {
    newAttributes = filterObjectWithForbidden(attributes, options.exclude);
  }
  if (Object.keys(options).includes('addSpecs')) {
    for (const key in newAttributes) {
      const newAttribute = newAttributes[key];
      if (Object.keys(options.addSpecs).includes(key)) newAttributes[key] = { ...newAttribute, ...options.addSpecs[key] };
    }
  }
  return { ...dataModel, attributes: newAttributes };
};

const filterObjectWithAllowed = (raw, allowed) => Object.keys(raw)
  .filter(key => allowed.includes(key))
  .reduce((obj, key) => {
    obj[key] = raw[key];
    return obj;
  }, {});

const filterObjectWithForbidden = (raw, notAllowed) => Object.keys(raw)
  .filter(key => !notAllowed.includes(key))
  .reduce((obj, key) => {
    obj[key] = raw[key];
    return obj;
  }, {});

const objectAsArray = (object) => {
  const array = [];
  Object.keys(object).forEach(key => {
    array.push(object[key]);
  });
  return array;
};

const shortenString = (string, maxLength) => {
  if (string.length <= maxLength) return string;
  const cut = maxLength - 6;
  const cutted1 = string.slice(0, cut);
  const cutted2 = string.slice(string.length - 6, string.length);
  return cutted1 + '...' + cutted2;
};

const shortenText = (text, maxLength = 40) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const mimeTypeToImage = mimeType => {
  const fileTypeMapper = DomainData.fileTypeMapper;
  if (Object.keys(fileTypeMapper).includes(mimeType)) return fileTypeMapper[mimeType];
  return fileTypeMapper.fallback;
};

const addToDate = (myCurrentDate, years, months, days, hours, seconds) => {
  // currently only months & days
  myCurrentDate = myCurrentDate || new Date();
  // months
  const myFutureDate = new Date(myCurrentDate);
  myFutureDate.setMonth(myCurrentDate.getMonth() + months);
  // days
  myFutureDate.setDate(myFutureDate.getDate() + days);
  return myFutureDate;
};

const getProfileCompleteness = doctor => {
  let completeness = 100;
  if (doctor.address && (!doctor.address.street || !doctor.address.postalCode || !doctor.address.city)) completeness -= 5;
  if (doctor.desired_areas_of_education.length === 0)completeness -= 6;
  if (doctor.desired_areas_of_expertise.length === 0) completeness -= 10;
  if (doctor.desired_places_of_work.length === 0) completeness -= 12;
  if (Object.keys(doctor.desired_working_conditions).length === 0) completeness -= 10;
  if (doctor.desires_list.length === 0) completeness -= 6;
  if (doctor.experiences.length === 0) completeness -= 4;
  if (doctor.qualifications.length === 0) completeness -= 6;
  if (doctor.spoken_languages.length === 0) completeness -= 9;
  if (!doctor.status) completeness -= 12;
  if ((doctor.title === 'yes' || doctor.title === 'almost' || doctor.title === true) && Object.keys(doctor.promotion).length === 0) completeness -= 4;
  if (doctor.status === 'Student' && Object.keys(doctor.study_information).length === 0) completeness -= 10;
  if (doctor.status === 'Student' && doctor.pj_tertials.length === 0) completeness -= 4;
  if (!doctor.has_referred) completeness -= 2;
  return completeness;
};

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const currencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR'
});

const formatCurrency = (x, r = false) => r ? '€ ' + currencyFormatter.format(x).replace('€', '') : currencyFormatter.format(x);

function getGermanTimeDifference (date) {
  const now = new Date();
  const diff = (now.getTime() - date.getTime()) / 1000; // Get the difference in seconds
  let result;

  if (diff < 60) {
    result = 'vor ' + Math.floor(diff) + ' Sek.';
  } else if (diff < 60 * 60) {
    const minutes = Math.floor(diff / 60);
    result = 'vor ' + minutes + (minutes > 1 ? ' Min.' : ' Min.');
  } else if (diff < 60 * 60 * 24) {
    const hours = Math.floor(diff / (60 * 60));
    result = 'vor ' + hours + (hours > 1 ? ' Std.' : ' Std.');
  } else {
    const days = Math.floor(diff / (60 * 60 * 24));
    result = 'vor ' + days + (days > 1 ? ' Tagen' : ' Tag');
  }

  return result;
}

const sameArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false; // arrays are different lengths
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false; // strings in the same index are different
    }
  }

  return true; // arrays have the same strings (not necessarily in the same order)
};

const wait = (seconds) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
};

export default {
  isInObject,
  returnDataModelAttributes,
  objectAsArray,
  shortenString,
  mimeTypeToImage,
  addToDate,
  getProfileCompleteness,
  numberWithCommas,
  currencyFormatter,
  formatCurrency,
  getGermanTimeDifference,
  sameArrays,
  shortenText,
  getTimeDifferenceInDays,
  wait
}
;
