import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

export default function PaypalButton ({ price, onClick, disabled, formData, onSuccessfulCheckout, handleValidationFail, setLoading }) {
  /* test 'AaFc6XayzPV0WJi-YAlGRqIVR0-7N-SiOoIg1DkHY8EUENc_u6OPiiMM0VTpUNilN2TYNHsipy3Q_giZ'/ */

  console.log('paypal', price);
  return (
    <PayPalScriptProvider options={{ 'client-id': 'AUzviNHjGnyuuW0TzgFSrjuZ8g5Z3hEDtxHpbQvJGXpgTnEgyx1lKeVe9oW5SgckgP0qrrEuMd7BFWMi', currency: 'EUR' }}>
      <PayPalButtons
        onCancel={() => handleValidationFail('has cancelled')}
        onError={err => handleValidationFail(err)}
        forceReRender={[price, disabled]}
        fundingSource='paypal'
        showSpinner
        onClick={onClick}
        disabled={disabled}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: 'EUR',
                    value: price
                  }
                }
              ]
            })
            .then((orderId) => {
            // Your code here after create the order
              setLoading(true);
              return orderId;
            })
            .catch(() => handleValidationFail());
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(() => {
            onSuccessfulCheckout(data);
          });
        }}
      />
    </PayPalScriptProvider>
  );
}
