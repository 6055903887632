import MoreVert from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext } from '../../context/Auth';
import LoadingIcon from '../../assets/img/loading_transp.gif';

const FileActionMenu = ({ file, actions, deleteFile, fileDownload, downloadFiles, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { api } = useContext(AuthContext);
  const open = Boolean(anchorEl);
  const handleClick = (event, file) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setLoading(false);
    setAnchorEl(null);
  };
  return (
    <>
      <a
        id={file.id}
        key={file.id}
        aria-controls={open ? 'list-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={event => handleClick(event, file)}
      >
        <MoreVert />
      </a>
      <Menu
        id='list-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'list-menu-button'
        }}
      >
        {
          loading &&
            <MenuItem
              key={1}
              onClick={() => api(jwt => { setLoading(true); file.downloadBlob(jwt, console.log).then(blob => fileDownload(blob, file.name)).finally(() => setLoading(false)); })}
            >
              <img src={LoadingIcon} width={20} />
            </MenuItem>
        }
        {
          actions
            ? (
              <>
                {
                  actions.map((action, idx) => {
                    if (!action.action) return null;
                    return (
                      <MenuItem
                        key={idx}
                        onClick={() => { setLoading(true); action.action(); handleClose(); }}
                      >
                        {action.label}
                      </MenuItem>
                    );
                  })
                }
              </>
              )
            : [
              <MenuItem
                key={1}
                onClick={() => api(jwt => { setLoading(true); file.downloadBlob(jwt, console.log).then(blob => fileDownload(blob, file.name)).finally(() => setLoading(false)); })}
              >
                Download
              </MenuItem>,
              <MenuItem
                key={2}
                onClick={() => deleteFile(() => { setLoading(true); api(jwt => file.delete(jwt)).then(() => downloadFiles()).finally(() => setLoading(false)); })}
                style={{ color: 'red' }}
              >
                Löschen
              </MenuItem>
              ]
        }

      </Menu>
    </>
  );
};

export default FileActionMenu;
