
import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';
import React, { useContext } from 'react';
import configuration from '../../configuration';
import { WindowManagerContext } from '../../context/WindowManager';
import PrimaryButton from '../buttons/PrimaryButton';

const StyledContainer = styled(Container)(({ theme }) => {
  console.log(theme);
  return {
    border: '2px solid',
    borderColor: theme.palette.grey.lightest,
    background: theme.palette.grey.megaLight,
    padding: 24,
    maxWidth: configuration.standardWidth,
    margin: 0,
    marginTop: 24
  };
});

const NoAuthInfoBox = ({ ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  return (
    <StyledContainer>
      <h2>Du bist nicht eingeloggt...</h2>
      <p>
        Um auf alle Funktionen zugreifen zu können, musst du eingeloggt sein.<br />
        <b>Du hast Glück:</b> Du kannst dich <b>kostenlos</b> registrieren.
      </p>
      <br />
      <PrimaryButton style={{ textAlign: 'right' }} onClick={() => openWindow('register')}>Kostenlos registrieren</PrimaryButton>
      &nbsp;&nbsp;
      <PrimaryButton secondary style={{ textAlign: 'right' }} onClick={() => openWindow('login')}>Einloggen</PrimaryButton>
    </StyledContainer>
  );
};

export default NoAuthInfoBox;
