import React, { useState, useContext, useEffect } from 'react';
import LoadingIcon from '../../assets/img/loading_transp.gif';
import { CircularProgress, Grid, Hidden, Menu, MenuItem, Table, TableCell, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import configuration from '../../configuration';
import { styled } from '@mui/system';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../context/Auth';
import { WindowManagerContext } from '../../context/WindowManager';
import DownloadIcon from '@mui/icons-material/Download';
import fileDownload from 'js-file-download';
import { getFiles } from '../../api/methods';
import IconWithText from './IconWithText';
import DomainData from '../../assets/datasets/DomainData';
import PDFViewer from './pdfview/PDFViewer';
import helper from '../../assets/helper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileActionMenu from './FileActionMenu';
import ArrowBack from '@mui/icons-material/ArrowBack';

const sortFilesByDate = files => {
  const ordered = files.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((a.createdAt > b.createdAt) ? -1 : 0));
  return ordered;
};

const FileList = ({ style, fileTypes, downloadFilesMethod, mark = [], listObject, actions, tableProps = ['uploadDate', 'fileObject', 'actions'], ...props }) => {
  const [pdf, setPdf] = useState();
  const [pdfWindowOpen, setPdfWindowOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const { auth, api } = useContext(AuthContext);
  const { openWindow } = useContext(WindowManagerContext);
  const isMobile = useMediaQuery('(max-width:600px)');

  const downloadFiles = downloadFilesMethod
    ? () => downloadFilesMethod(setFiles, setLoading, sortFilesByDate)
    : () => {
        api(jwt => getFiles({ types: fileTypes }, jwt))
          .then(result => setFiles(sortFilesByDate(result)))
          .catch(err => { alert('Bewerbungen konnten nicht geladen werden. ' + err.stack); console.log(err); })
          .finally(() => setLoading(false));
      };

  useEffect(() => {
    if (auth.loggedIn) {
      setLoading(true);
      downloadFiles();
    }
  }, [auth.loggedIn]);

  const StyledTableCell = styled(TableCell)({
    color: 'inherit',
    padding: 6,
    paddingTop: 10
  });
  const applicationNameStyle = {
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: -0.02,
    color: 'rgba(0, 0, 0, 0.67)'
  };
  const applicationUnderTitleStyle = {
    fontStyle: 'normal',
    letterSpacing: -0.02,
    color: 'rgba(0, 0, 0, 0.46)'
  };
  const ListObject = listObject
    ? ({ file }) => listObject({ file, openDocument, nameStyle: applicationNameStyle, underTitleStyle: applicationUnderTitleStyle })
    : ({ file }) => (
      <IconWithText
        icon={<>{!isMobile && <img src={helper.mimeTypeToImage(file.mimeType)} height={48} style={{ marginRight: 12 }} />}</>}
        text={
          <div>
            <h3 style={{ ...applicationNameStyle, margin: 0 }}>
              {file.type || file.name}
            </h3>
            {
              isMobile
                ? <p style={{ ...applicationUnderTitleStyle, margin: 0 }}>{helper.shortenString(file.name, 20)}</p>
                : <p style={{ ...applicationUnderTitleStyle, margin: 0 }}>{file.name}</p>
            }
          </div>
             }
        onClick={
              [
                'application/pdf',
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/tiff'
              ].includes(file.mimeType)
                ? () => openDocument(file)
                : null
            }
      />
      );
  const openDocument = async (file) => {
    if ([
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/tiff'
    ].includes(file.mimeType)) {
      const image = await api(jwt => file.downloadBlob(jwt, () => {}));
      openWindow('notification', {
        header: 'Dokument anschauen',
        text: close => {
          return (
            <>
              {
             image
               ? (
                 <>
                   {
                    [
                      'image/jpeg',
                      'image/png',
                      'image/gif',
                      'image/tiff'
                    ].includes(file.mimeType) && <><img src={URL.createObjectURL(image)} style={{ maxWidth: '100%' }} /></>
                }
                 </>
                 )
               : (
                 <CircularProgress />
                 )
            }

            </>
          );
        }
      });
    } else {
      setPdfWindowOpen(true);
      api(jwt => file.downloadBlob(jwt, () => {}))
        .then(f => { setPdf(f); });
    }
  };
  const deleteFile = (deleteMethod) => {
    openWindow('notification', {
      header: 'Wirklich löschen?',
      text: close => {
        return (
          <>
            <Grid container>
              <Grid item xl={12} xs={12}>
                <p>Möchtest du dieses Dokument wirklich löschen?</p>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xl={6} xs={6}>
                <PrimaryButton style={{ background: 'red' }} onClick={() => close()}>Nein</PrimaryButton>
              </Grid>
              <Grid item xl={6} xs={6}>
                <PrimaryButton style={{ background: 'green', float: 'right' }} onClick={() => { setLoading(true); deleteMethod(); close(); }}>Ja</PrimaryButton>
              </Grid>
            </Grid>
          </>
        );
      }
    });
  };
  const TableTitle = props => <h4 style={{ ...applicationUnderTitleStyle, fontWeight: 'bold', margin: 0 }}>{props.children}</h4>;
  return (
    <>
      <Table style={{ maxWidth: configuration.standardWidth, color: 'rgba(0, 0, 0, 0.67)' }}>
        <TableHead>
          <TableRow sx={{ 'td, th': { border: 0, borderBottom: '2px solid rgba(0,0,0,0.3)' } }}>
            {(!isMobile && tableProps.includes('uploadDate')) && <StyledTableCell><TableTitle>Hochgeladen</TableTitle></StyledTableCell>}
            {tableProps.includes('fileObject') && <StyledTableCell><TableTitle>Datei</TableTitle></StyledTableCell>}
            {tableProps.includes('actions') && <StyledTableCell><TableTitle>Aktionen</TableTitle></StyledTableCell>}
          </TableRow>
        </TableHead>
        {
          files
            ? files.map((file, idx) => {
                const date = new Intl.DateTimeFormat('de').format(new Date(file.createdAt));
                return (
                  <TableRow
                    key={idx}
                    sx={{ 'td, th': { border: 0, borderBottom: '0.5px solid rgba(0,0,0,0.09)', background: mark.includes(file.id) ? '#cefac3' : null } }}
                  >
                    {(!isMobile && tableProps.includes('uploadDate')) && <StyledTableCell>{date}</StyledTableCell>}
                    {tableProps.includes('fileObject') && <StyledTableCell><ListObject file={file} /></StyledTableCell>}
                    {
                tableProps.includes('actions') &&
                  <StyledTableCell>
                    {
                      !isMobile || actions?.length === 1
                        ? (
                          <>
                            {
                            [
                              'application/pdf',
                              'image/jpeg',
                              'image/png',
                              'image/gif',
                              'image/tiff'
                            ].includes(file.mimeType) && <a style={{ cursor: 'pointer' }} onClick={() => openDocument(file)}><VisibilityIcon /></a>
                          }
                            {
                            actions
                              ? (
                                <>
                                  {
                                    actions.map((action, idx) => {
                                      if (!action.action) return null;
                                      return (
                                        <Tooltip key={idx} title={action.label}><a style={{ cursor: 'pointer' }} onClick={() => action.action(file, downloadFiles)}>{action.icon}</a></Tooltip>
                                      );
                                    })
                                  }
                                </>
                                )
                              : (
                                <>
                                  <a style={{ cursor: 'pointer' }} onClick={() => api(jwt => file.downloadBlob(jwt, console.log)).then(blob => fileDownload(blob, file.name))}><DownloadIcon /></a>
                                  <a style={{ cursor: 'pointer' }} onClick={() => deleteFile(() => { setLoading(true); api(jwt => file.delete(jwt)).then(() => downloadFiles()).finally(() => setLoading(false)); })}><DeleteIcon sx={{ color: 'red' }} /></a>
                                </>
                                )
                          }
                          </>
                          )
                        : (
                          <>
                            <center>
                              <FileActionMenu
                                key={file.id}
                                file={file}
                                action={actions}
                                deleteFile={deleteFile}
                                downloadFiles={downloadFiles}
                                fileDownload={fileDownload}
                              />
                            </center>
                          </>
                          )
                    }

                  </StyledTableCell>
                }
                  </TableRow>
                );
              })
            : (
              <></>
              )
          }
      </Table>

      {
        files?.length === 0 && <>Keine Dateien vorhanden.<br /></>
      }

      {
        loading && <img src={LoadingIcon} width={30} />
      }
      {
          (pdfWindowOpen) &&
            <PDFViewer file={pdf} close={() => { setPdfWindowOpen(false); setPdf(null); }}>
              <div
                style={{
                  padding: 10
                }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <PrimaryButton secondary onClick={() => { setPdfWindowOpen(false); setPdf(null); }} style={{ float: 'right' }}><ArrowBack />&nbsp;&nbsp;Zurück</PrimaryButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </PDFViewer>
        }
    </>
  );
};

export default FileList;
