import React from 'react';
import WindowWrapper from './WindowWrapper';

const NotificationWindow = ({ style, close, minWidth, maxWidth, width, className, content, header, text, ...props }) => {
  return (
    <>
        <div style={{ maxWidth, minWidth, width }}>
          <h2>{header}</h2>
          <>{text(close, props)}</>
        </div>
    </>
  );
};

export default NotificationWindow;
