import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import LoadingIcon from '../../../assets/img/loading_transp.gif';
import { CircularProgress } from '@mui/material';

// import './pdf-styles.css';

const AbsoluteDiv = props => {
  let { top, left, right, bottom } = props;

  if (!top && !left && !right && !bottom) {
    top = 0;
    left = 0;
  }
  return (
    <div style={{ position: 'relative', height: props.fullHeight ? '100%' : 1, width: '100%', ...props.outerStyle }}>
      <div style={{ position: 'absolute', top: top || null, left: left || null, right: right || null, bottom: bottom || null, ...props.innerStyle }}>
        {props.children}
      </div>
    </div>
  );
}
;

const PDFViewer = props => {
  const [loading, setLoading] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  const [zoom, setZoom] = React.useState(1.0);
  const [zoomHover, setZoomHover] = React.useState(false);
  const { file } = props;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoading(false);
    setPageCount(numPages);
  };

  const handleScroll = e => {
    // console.log(e);
    // const element = e.target;
    // window.onscroll = null;
  };

  const maxWidth = 800;
  const baseWidth = Math.min(window.innerWidth - 10, maxWidth);
  const difference = window.innerWidth - baseWidth;

  return (
    <>
      <div onClick={() => props.close()} style={{ zIndex: 10001, backgroundColor: 'rgba(0,0,0,0.5)', width: '100%', height: '100%', position: 'fixed', top: 0, left: 0 }} />
      <div
        style={{
          width: baseWidth,
          marginLeft: difference / 2,
          backgroundColor: 'grey',
          paddingBottom: 10,
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10002
        }}
      >
        <div
          style={{
            maxWidth: 400,
            backgroundColor: 'white',
            boxShadow: '0 8px 6px -6px black',
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            margin: '0px auto'
          }}
        >
          {props.children}
        </div>
        <div
          style={{
            marginTop: -10,
            width: baseWidth * 0.9,
            backgroundColor: 'white',
            marginLeft: baseWidth * 0.05,
            marginRight: baseWidth * 0.05,
            overflowX: 'scroll',
            overflowY: 'scroll',
            height: Math.min((baseWidth / 595) * 842, window.innerHeight - window.innerHeight * 0.1)
          }}
          onScroll={handleScroll}
          className='custom-scroll'
        >
          {
            file
              ? (
                <Document
                  file={file/* { url: config.APIConfig.GET_WISHES_PDF + '?' + queryString.stringify(parameters), httpHeaders: { Bearer: jwt }, withCredentials: true } */}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {
                    Array.from(Array(pageCount)).map((_, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <Page pageNumber={idx + 1} width={baseWidth * 0.9} scale={zoom} />
                          {idx < (pageCount - 1) && <div style={{ height: 10, width: '100%', background: 'grey' }} />}
                        </React.Fragment>
                      );
                    })
                  }
                </Document>
                )
              : (
                <>
                  <center><CircularProgress /></center>
                </>
                )
          }
        </div>
        <AbsoluteDiv bottom={30} right={50}>
          <div
            onMouseEnter={() => setZoomHover(true)}
            onMouseLeave={() => setZoomHover(false)}
            style={{ opacity: zoomHover ? '1' : '0.3', color: 'black', fontSize: 40, cursor: 'pointer' }}
          >
            <a onClick={() => setZoom(Math.min(100, zoom + 0.05))}><ZoomInIcon /> </a>
            <br />
            <a onClick={() => setZoom(Math.max(0, zoom - 0.05))}><ZoomOutIcon /> </a>
          </div>
        </AbsoluteDiv>
        {/* <div style={{ borderRadius: 15, backgroundColor: 'white', padding: 12, margin: '-20px auto', width: 'fit-content', blockSize: 'fit-content' }}>
          <table style={{ width: 'fit-content', blockSize: 'fit-content' }}>
            <tbody>
              <tr>
                <td>
                  <a style={{ display: pageNumber > 1 ? null : 'none', cursor: 'pointer' }} onClick={() => setPageNumber(pageNumber - 1)}><ArrowLeftIcon /></a>
                </td>
                <td>
                  <p><b>Seite {pageNumber} / {pageCount}</b></p>
                </td>
                <td>
                  <a style={{ display: pageNumber < pageCount ? null : 'none', cursor: 'pointer' }} onClick={() => setPageNumber(pageNumber + 1)}><ArrowRightIcon /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};

export default PDFViewer;
