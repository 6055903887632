import axios from 'axios'
import { deleteFile, receiveDownloadURLs } from '../api/methods'

export default class File {
  constructor (data) {
    this.id = data.id
    this.name = data.file_name
    this.description = data.description
    this.mimeType = data.mime_type
    this.type = data.type
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
  }

  async delete (jwt) {
    console.log('delete ', this.name)
    await deleteFile(this.id, jwt)
  }

  async download (jwt, progressTracker) {
    const downloadURL = await receiveDownloadURLs([this.name], jwt)
    const fileData = await axios.get(downloadURL, {
      onDownloadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        progressTracker(percentCompleted)
      }
    })
    const downloadedFile = new Blob([fileData.data], { type: this.mimeType })
    return downloadedFile
  }

  async downloadBlob (jwt, progressTracker) {
    const downloadURL = await receiveDownloadURLs([this.name], jwt)
    const fileData = await axios.get(downloadURL, {
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        progressTracker(percentCompleted)
      }
    })
    return fileData.data
  }
}
