import ArrowDownward from '@mui/icons-material/ArrowDownward'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import React from 'react'
import DomainData from '../../assets/datasets/DomainData'
import helper from '../../assets/helper'
import IconWithText from './IconWithText'

const FileAttachment = ({ onClick, actions, file, style, label, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event, file) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: '2px solid rgba(0, 0, 0, 0.2)',
        padding: 8,
        fontSize: '0.9rem',
        marginRight: 10,
        marginBottom: 10,
        fontWeight: 500,
        ...style
      }}
    >
      <a
        id={file.id}
        key={file.id}
        aria-controls={open ? 'file-menu-' + file.name : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={event => handleClick(event, file)}
        style={{ width: '100%' }}
      >
        <img src={helper.mimeTypeToImage(file.mimeType)} width={20} />&nbsp;&nbsp;&nbsp;
        {/* {helper.shortenString(file.name, 20)} */}
        {label || file.type}
      </a>

      <Menu
        id={'file-menu-' + file.name}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'list-menu-button'
        }}
      >
        {
          actions
            ? actions.map((action, idx) => {
              console.log(action.icon)
              if (!action.action) return null
              return (
                <MenuItem
                  key={action.label}
                  onClick={() => { action.action(file); handleClose() }}
                >
                  <IconWithText
                    icon={action.icon}
                    text={action.label}
                  />
                </MenuItem>
              )
            })
            : []
        }
      </Menu>
    </div>
  )
}

export default FileAttachment
