import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/Auth';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ApplicationMenu from '../ApplicationMenu';
import queryString from 'qs';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import EmployerData from './steps/EmployerData';
import { createNewApplication, decreaseCredits, getApplication } from '../../api/methods';
import CoverLetter from './steps/CoverLetter';
import CV from './steps/CV';
import OtherFiles from './steps/OtherFiles';
import SendApplication from './steps/SendApplication';
import PersonalData from '../../classes/PersonalData';
import { WindowManagerContext } from '../../context/WindowManager';
import { ApplicationDataContext } from '../../context/ApplicationData';
import LoadingIcon from '../../assets/img/loading_transp.gif';
import CloseIcon from '@mui/icons-material/Close';
import ApplicationWizardMenu from './ApplicationWizardMenu';

import Step1Img from '../../assets/img/step1_img.png';
import Step2Img from '../../assets/img/step2_img.png';
import Step3Img from '../../assets/img/step3_img.png';
import Step5Img from '../../assets/img/step4_img.png';
import PrimaryButton from '../buttons/PrimaryButton';
import configuration from '../../configuration';
import { CircularProgress } from '@mui/material';
import FeedbackButton from '../buttons/FeedbackButton';

const MenuGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: 'grey.dark'
}));

const MainGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#F5F5F5',
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    minHeight: '100vw'
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
    minHeight: '100vw'
  },
  color: 'grey.dark'
}));

const steps = [
  null,
  props => <EmployerData {...props} />,
  props => <CoverLetter {...props} />,
  props => <CV {...props} />,
  props => <OtherFiles {...props} />,
  props => <SendApplication {...props} />
];

const stepsMenuItems = [
  {},
  {
    image: Step1Img,
    header: 'Wohin geht die Bewerbung?',
    explanation: <>Wir brauchen die richtigen Kontaktdaten des/der Adressat:in.<br />Achte hier darauf, dass die Angaben auf jeden Fall richtig sind. Kein Mensch mag es, mit falschem Namen angesprochen zu werden...</>,
    tip: 'Nimm als Ansprechpartner:in immer die Chefärztin oder den Chefarzt.'
  },
  {
    image: Step2Img,
    header: 'Das Anschreiben',
    explanation: <>Das Anschreiben ist nach wie vor Pflicht - und auch definitiv ein lästiges Thema.<br />Bei uns kannst du ein hochwertiges Anschreiben generieren lassen, das dir die Formulierungs-Arbeit deutlich erleichtert.</>,
    tip: 'Ein gut geschriebenes Anschreiben ist oft ein Indikator für persönliche Motivation - kann durchaus nicht schaden ;-)'
  },
  {
    image: Step3Img,
    header: 'Der Lebenslauf',
    explanation: <>Hier steht alles drin, das du in der Vergangenheit gemacht hast.<br />Lad' doch gerne einfach ein Muster herunter. Wir stellen dir das kostenlos zur Verfügung. Dann vergisst du keine wichtigen Daten :-)</>,
    tip: 'Du darfst ruhig irrelevante Dinge aus deinem Lebenslauf herauslassen.'
  },
  {
    image: Step3Img,
    header: 'Weitere Dokumente',
    explanation: <>Es gibt einige Dokumente, die Arbeitgeber in deiner Bewerbung sehen wollen.<br />In diesem Schritt kannst du sie hochladen.</>,
    tip: (
      <>
        Im Zweifel lieber mehr...<br />
        Blöd wäre es, wenn du aus der engeren Auswahl bist, weil du ein Dokument vergisst ;-)
      </>
    )
  },
  {
    image: Step5Img,
    header: 'Bewerbung versenden',
    explanation: <>Nun fehlt nur noch der letzte Schritt: Deine Bewerbung versenden und einen guten Text in der Mail ergänzen, der das Ganze abrundet.</>,
    tip: 'Eine freundliche Mail ist das erste, das ein:e Personaler:in von dir sieht.'
  }
];

const Root = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: '15%'
  },
  [theme.breakpoints.down('md')]: {
    padding: '10%'
  }
}));

const ApplicationWizardHolder = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user = {}, auth, api, saveUserData, decreaseCreditsForUser } = useContext(AuthContext);
  const { methods, data } = useContext(ApplicationDataContext);
  const { loadData, hasPersonalData, setPersonalData, setOfflineData, resetData } = methods;
  const { openWindow, closeWindow } = useContext(WindowManagerContext);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const scroller = React.useRef(null);
  const [params, setParams] = useSearchParams();

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      navigate('/home');
    }
  };

  const previousStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const openWindowToDepositCredit = data => {
    openWindow('notification', {
      maxWidth: 600,
      header: 'Nicht genügend Credits!',
      notClosable: true,
      text: close => {
        return (
          <p>
            Leider konnte deine Bewerbung nicht gespeichert werden, da dir die Credits für die Bewerbung fehlen.<br />
            <b>Du kannst die Credits aber direkt aufladen.</b>
            <PrimaryButton style={{ float: 'right' }} onClick={() => openWindow('creditDeposit', { onClose: () => { createNewApplicationAfterPayment(data); close(); } })}>Credits aufladen</PrimaryButton>
          </p>
        );
      }
    });
  };


  const createNewApplicationAfterPayment = data =>
    decreaseCreditsForUser(configuration.applicationPrice)
      .then(() => {
        console.log({ ...data, expertiseArea: data.domainSpecificInformation.expertiseArea });
        api(jwt => createNewApplication({ ...data, expertiseArea: data.domainSpecificInformation.expertiseArea }, jwt)
        )
          .then(newApplication => setOfflineData(newApplication))
          .catch(() => alert('Das Speichern hat gerade nicht funktioniert. Probier\' es später nochmal.'));
      })
      .catch(error => {
        console.log(error, error.message, error.stack, error.response, error.request);
        console.log(error);
        if (error.message.indexOf('Credits') > -1) {
          openWindowToDepositCredit();
        } else {
          alert('Das Speichern hat gerade nicht funktioniert. Probier\' es später nochmal.');
        }
      });

  const getQuery = async (applicationId) => {
    // pass via aid
    try {
      if (applicationId) {
        await loadData(applicationId);
        setLoading(false);
      } else {
        throw new Error('Leider hat es nicht funktioniert');
      }
    } catch (error) {
      console.log(error);
      alert('Deine Bewerbung konnte nicht geladen werden... Bitte nochmal probieren');
      navigate('/home');
    }
  };

  const getIdFromParams = () => {
    return params.get('aid');
  };

  const initialStart = () => {
    const applicationId = getIdFromParams();
    if (auth?.loggedIn && !applicationId) {
      console.log('user is now logged in');
      if (params.get('subject')) {
        // create new application
        console.log('ApplicationWizardHolder', 'createNewApplication', { ...data, expertiseArea: data.domainSpecificInformation.expertiseArea });

        if (user?.credits < configuration.applicationPrice) { openWindowToDepositCredit(data); return; }

        createNewApplicationAfterPayment(data);
      }
    } else if (!auth?.loggedIn) {
      console.log('user is NOT logged in!');
      if (applicationId) {
        console.log('user is NOT logged in and there is an application id', applicationId);
        closeWindow('personalData');
        navigate('/home');
        return;
      }
      const newApplication = {
        applicationCause: params.get('applicationCause'),
        jobRole: params.get('jobRole'),
        domainSpecificInformation: { expertiseArea: params.get('expertiseArea') },
        subject: params.get('subject')
      };
      if (data?.subject === newApplication.subject && !data?.id) {
        console.log('keine vorhandenen Daten => neue Bewerbung');
        setOfflineData(newApplication);
      } else {
        console.log('schon vorhandene Daten => reset!');
        resetData(newApplication);
      }
      checkForPersonalData();
      setLoading(false);
    } else if (auth?.loggedIn && applicationId) {
      console.log('user is logged in and there is an application id');
      getQuery(applicationId);
    }
  };

  const checkForPersonalData = () => {
    console.log('checks personal data');
    if (!hasPersonalData()) {
      console.log('no personal data');
      openWindow('personalData', {
        saveData: data => {
          setPersonalData(data);
          setLoading(false);
          if (auth?.loggedIn) saveUserData(data);
        },
        notClosable: true
      });
    }
  };

  useEffect(() => {
    if (auth?.ready) initialStart();
  }, [auth, location.search]);

  useEffect(() => {
    if (auth?.ready && data?.id) {
      checkForPersonalData();
      if (params.get('subject') && !params.get('aid')) {
        console.log('application already has been created but not yet changed urls');
        params.set('aid', data.id + '');
        setParams(params);
      }
    }
  }, [data]);

  const wizardProps = {
    previousStep: step > 1 ? previousStep : null,
    nextStep
  };

  React.useEffect(() => {
    if (scroller.current) scroller.current.scrollIntoView();
  }, [step]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <div ref={scroller} />
        <CloseIcon style={{ position: 'absolute', top: 20, right: 20, fontSize: 40, cursor: 'pointer' }} onClick={() => navigate('/home')} />
        <Grid container spacing={1}>
          <MenuGrid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <ApplicationWizardMenu
              stepNr={step}
              {...stepsMenuItems[step]}
            />
          </MenuGrid>
          <MainGrid item xs={12} sm={12} md={8} lg={8} xl={9}>
            {
              loading
                ? <CircularProgress />
                : (
                  <Root>
                    {steps[step]({
                      ...wizardProps
                    })}
                  </Root>
                  )
            }

          </MainGrid>
        </Grid>
      </Box>

    </>
  );
  /* */
};

export default ApplicationWizardHolder;
