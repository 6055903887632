import React from 'react';
export default {
  textModules: {
    cause: [
      {
        title: <><b>Anlass: </b>Persönlicher Kontakt mit Empfehlung</>,
        text: (type) => 'durch einen persönlichen Kontakt zu XXX wurde mir Ihre Abteilung empfohlen, ' +
      'da sie sich durch gute Weiterbildung und faire Arbeitsbedingungen auszeichnet. Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Internetrecherche und gute Presse</>,
        text: (type) => 'durch einen Beitrag im XX bin ich auf die außerordentlichen Rahmenbedingungen Ihrer Klinik ' +
      'gestoßen, die sich durch gute Weiterbildung und faire Arbeitsbedingungen auszeichnet. Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Fokus auf gute Weiterbildungsmöglichkeiten und fairen Arbeitsbedingungen</>,
        text: (type) => 'auf der Suche nach einem Arbeitgeber mit dem Fokus auf gute Weiterbildungsmöglichkeiten ' +
      'und fairen Arbeitsbedingungen bin ich auf Sie aufmerksam geworden. Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Klinik hat für das Fachgebiet ein besonders gutes Angebot für das Fachgebiet</>,
        text: (type) => 'gerne möchte ich meinen Berufsweg bei Ihnen beginnen, da Sie durch mein besonders großes ' +
      'Interesse am Fachgebiet XXX die optimalen Bedingungen für meinen erfolgreichen Weiterbildungsbeginn anbieten. ' +
      'Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Promotionsbetreuung durch die Klinik</>,
        text: (type) => 'aufgrund der Betreuung meiner Promotion zu Thema XXX und die daraus resultierende produktive ' +
      'Zusammenarbeit mit Herrn/Frau Dr. XXX hat mich dazu bewogen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Erfahrungen aus dem PJ</>,
        text: (type) => 'meine Erfahrungen mit Ihnen als Arbeitgeber in meinem zweiten PJ-Tertial in der XXX bewegen ' +
      'mich dazu, den Start in meine Weiterbildungszeit mit Ihnen gehen zu wollen. ' +
      'Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Erfahrungen aus Famulatur(en)</>,
        text: (type) => 'während meiner klinischen Zeit im Studium durfte ich Ihre Abteilung bereits kennenlernen. ' +
      'Der positive Eindruck, den ich gewinnen konnte, hebt sich gegenüber anderen Erfahrungen in Famulaturen ab. ' +
      'Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Erfahrungen anderer Mediziner:innen</>,
        text: (type) => 'seit dem Abschluss meines zweiten Staatsexamens habe ich mir durch zahlreiche Gespräche mit ' +
      'anderen Mediziner:innen ein gutes Bild der Kliniken im Umkreis von XXX machen. Insbesondere Ihre ' +
      'Abteilung wurde immer wieder positiv hervorgehoben, da Sie einen großen Wert auf XXX legen. ' +
      'Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Emotionale Verbundenheit mit der Fachrichtung</>,
        text: (type) => 'da mich der Wunsch antreibt, Menschen zu mehr Lebensqualität zu verhelfen und Ihre Klinik sich ' +
      'Tag für Tag für Menschen mit XXX einsetzt, bin ich davon überzeugt, dass eine Zusammenarbeit ' +
      'zwischen Ihnen als Arbeitgeber und mir als ArbeitnehmerXXX gut funktionieren wird. ' +
      'Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b>Bereits geführtes Gespräch z.B. auf einer Messe</>,
        text: (type) => 'nach dem sehr informativen Gespräch mit Herrn/Frau XXX am XXX bin ich umso mehr bestärkt ' +
      'darin, meinen Berufsweg bei Ihnen zu beginnen. Besonders aufgrund der Tatsache XXX halte ich ' +
      'diese Stelle für einen optimalen Start. ' +
      'Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <><b>Anlass: </b> Suche über Felix Medicus</>,
        text: (type) => 'über Felix Medicus habe ich einen Arbeitgeber gesucht, der mit fairen Arbeitsbedingungen und ' +
      'Weiterbildungsmöglichkeiten überzeugen kann. Durch ihren besonderen Umgang mit diesen ' +
      'Themen bin auf Sie aufmerksam geworden. ' +
      'Aus diesem Grund habe ich beschlossen, ' +
      (type === 'ownInitiative'
        ? 'mich eigeninitiativ bei Ihnen zu bewerben.'
        : 'mich passend zu Ihrer Stellenausschreibung bei Ihnen zu bewerben.'
      )
      },
      {
        title: <>komplett individuell</>,
        text: (type) => ''
      }
    ],
    personal: [
      {
        title: <>Sachliche Einleitung und Beschreibung deiner Qualifikationen</>,
        text: (type) => 'Da ich bereits im praktischen Jahr einige Erfahrungen in der XXX sammeln konnte ' +
        'habe ich für mich festgestellt, dass ich in diesem Fachgebiet meinen Berufsweg starten möchte. '
      },
      {
        title: <>Emotionale Beschreibung, für welche Bereiche der Medizin du brennst</>,
        text: (type) => 'Schon während meines Studiums und des PJs war das Fach XXX meine große Leidenschaft und ich bin ' +
        'mir sicher, dass das Fachgebiet der XXX der richtige Startpunkt für meine Karriere ist.'
      },
      {
        title: <>Fokus auf deine Promotion und deine Eignung für das Fach</>,
        text: (type) => 'Da ich bereits im praktischen Jahr einige Erfahrungen in der XXX sammeln konnte ' +
        'und ich gleichzeitig meine Promotion im Rahmen dieses Fachgebiets angegangen bin, ' +
        'bin ich motiviert, den Berufsstart in der XXX anzugehen.'
      },
      {
        title: <>Außergewöhnliche Persönlichkeit </>,
        text: (type) => 'Da ich durch mein Interesse am Thema XXX und mein Engagement für den Bereich XXX ' +
        'eine sehr XXX Person bin, bin ich überzeugt davon, dass ich Ihr Team gut ergänzen und zum Erfolg Ihrer ' +
        'Abteilung beitragen würde.'
      },
      {
        title: <>Studijob</>,
        text: (type) => 'In meinem Studentenjob hatte ich bereits einige Berührungspunkte mit XXX. ' +
        'Dadurch hat sich eine Begeisterung für das Fachgebiet entwickelt.'
      },
      {
        title: <>Interessen im Studium</>,
        text: (type) => 'Schon im Studium hat mich das Fach XXX besonders fasziniert- speziell die XXX. Ich freue mich ' +
        'darauf das Erlernte endlich in der Praxis umzusetzen und weiteres Wissen zu erhalten und ' +
        'Erfahrungen zu machen.'
      },
      {
        title: <>Begeisterte:r Mediziner:in</>,
        text: (type) => 'Ich bin aus voller Überzeugung Mediziner:in, und brenne darauf, mit viel Engagement meiner ' +
        'Leidenschaft für das Fachgebiet der XXX nachzugehen und den Patient:innen Ihrer Klinik bestmöglich zu helfen.'
      },
      {
        title: <>Entstandenes Interesse aus dem PJ</>,
        text: (type) => 'In meinem praktischen Jahr habe ich das Fach XXX kennengelernt. ' +
        'Die Kombination aus XXX und XXX haben mich begeistert. Daher kann ich mir gut vorstellen, meine Karriere ' +
        'in diesem Fachbereich zu starten.'
      },
      {
        title: <>Schon in dem Fachbereich tätig</>,
        text: (type) => 'Ich habe nun bereits XXX Jahre Erfahrung in diesem Fachbereich und möchte hier weiterhin ' +
        'meinen Beitrag als Arzt/Ärztin leisten. Mir gefallen hier inbesondere die XXX und das XXX. Daher bin ich ' +
        'überzeugt, einen wertvollen Beitrag für Ihr Team leisten zu können.'
      },
      {
        title: <>Extrakurrikulare Aktivitäten</>,
        text: (type) => 'Über mein Studium und meine bisherigen Erfahrungen hinaus, zeichnen mich ein besonders großer ' +
        'Antrieb aus. Nebenbei bringe ich mich in XXX und XXX ein, um XXX zu erreichen.'
      },
      {
        title: <>Eine andere Beschreibung</>,
        text: (type) => ''
      }
    ],
    motivation: [
      {
        title: 'Gutes Team',
        text: 'Durch meine persönliche Erfahrung im praktischen Jahr, konnte ich bereits ' +
        'sehr gute Einblicke in das Miteinander im Team gewinnen. Hier ist mir das besonders ' +
        'positive Verhältnis untereinander und mit den Vorgesetzten aufgefallen. Da ich ' +
        'menschliche Wertschätzung als Grundpfeiler für ein angenehmes Angestelltenverhältnis sehe, ' +
        'habe ich eine hohe Motivation gerade in Ihrer Abteilung meinen Berufsweg fortzusetzen.'
      },
      {
        title: 'Gute:r Chef:in (Hörensagen)',
        text: 'Besonders häufig wurde mir von zahlreichen Stimmen berichtet, wie gut das Verhältnis der ' +
        'Chef:innen zu den angestellten Ärzt:innen ist. Da ein gutes Verhältnis zu den Vorgesetzten ein ' +
        'Schlüssel für gute Arbeitsbedingungen und ein gutes Lernklima ist, sehe ich einen großen Vorteil für ' +
        'meine persönliche Weiterentwicklung.'
      },
      {
        title: 'Gute:r Chef:in (Fachlich)',
        text: 'Durch mein großes Interesse am Thema XXX wäre es für meine persönliche Weiterentwicklung ' +
        'besonders hilfreich, für Herrn/Frau XXX arbeiten zu dürfen.'
      },
      {
        title: 'Weiterbildungsmöglichkeiten',
        text: 'Aufgrund meines großen Interesses für das Thema XXX bin ich besonders angetan von der ' +
        'Möglichkeit, bei Ihnen XXX machen zu können, um dem Ziel meiner Weiterbildung näher zu ' +
        'kommen.'
      },
      {
        title: 'Besondere Verfahren/ Geräte, die die Abteilung kann',
        text: 'Da ich ein besonders großes Interesse an XXX habe, würde ich mich sehr freuen bei Ihnen arbeiten ' +
        'zu dürfen, um diese Verfahren / den Umgang mit Gerät erlernen zu dürfen. Das gibt mir die Möglichkeit, ' +
        'mich passend fachlich weiterentwickeln zu können.'
      },
      {
        title: 'Vereinbarkeit von Familie und Beruf',
        text: 'Da ich weiß, dass Ihr Haus neben einer guten Ausbildung ein besonders gutes Angebot zur ' +
        'Vereinbarkeit von Familie und Beruf bietet, bin ich hochmotiviert, meinen Berufsweg bei Ihnen zu starten, ' +
        'da diese besonderen Möglichkeiten eine Seltenheit bei Arbeitgebern sind.'
      },
      {
        title: 'Sympathischer Auftritt online',
        text: 'Ich habe Ihren sympathischen Auftritt bei/auf XXX gesehen und habe Lust, mich als Teil Ihres Team ' +
        'einzubringen.'
      },
      {
        title: 'Lage der Klinik (Lokalpatriot)',
        text: 'Da ich XXX und die Menschen hier sehr schätze, möchte ich bevorzugt in dieser Region bleiben und ' +
        'Teil Ihres Hauses werden.'
      },
      {
        title: 'Besuchte Kongresse in dem Fachbereich',
        text: 'Am XXX und XXX durfte ich auf dem XXX und XXX tiefere Einblicke in das Fachgebiet XXX gewinnen. ' +
        'Meine bisherigen positiven Eindrücke wurden nur bestätigt und haben mich von einer Bewerbung in Ihrem Fachbereich ' +
        'bestärkt.'
      },
      {
        title: 'Forschungsmöglichkeiten',
        text: 'Da ich es essentiell finde, das Thema Forschung als Teil meiner medizinischen Karriere in meinen ' +
        'Arbeitsalltag zu integrieren, bin ich davon überzeugt, durch die Möglichkeiten Ihres Hauses im Zusammenhang ' +
        'mit meinen geplanten Forschungen zum Thema XXX mit einer Stelle bei Ihnen die richtige Wahl für meinen Berufsstart ' +
        'zu treffen.'
      },
      {
        title: 'Werte (zB Christliches Haus)',
        text: 'Mit den Werten Ihres Hauses kann ich mich gut identifizieren. Da mir das sehr wichtig ist, ' +
        'ist das ein essentieller Grund für mich, dass ich mich gerade bei Ihnen bewerbe.'
      }
    ]
  },
  documentTypes: [
    {
      name: 'Anschreiben',
      headline: 'Anschreiben',
      description: 'Das Anschreiben ist das Herzstück der Bewerbung. Wenn man ehrlich ist der einzige Grund, warum Bewerbungen immer noch als sehr förmlich wahrgenommen werden. Hier gilt es, auf den Punkt zu bringen, warum du bei dem Arbeitgeber arbeiten möchtest.',
      required: true,
      maxFiles: 100,
      hide: true
    },
    {
      name: 'Deckblatt',
      headline: 'Deckblatt',
      description: null,
      required: false,
      maxFiles: 100,
      hide: true
    },
    {
      name: 'Lebenslauf-Foto',
      headline: 'Lebenslauf-Foto',
      description: null,
      required: false,
      maxFiles: 1,
      hide: true,
      allowed: ['.jpeg', '.png', '.jpg']
    },
    {
      name: 'Unterschrift',
      headline: 'Unterschrift',
      description: null,
      required: false,
      maxFiles: 1,
      hide: true,
      allowed: ['.jpeg', '.png', '.jpg']
    },
    {
      name: 'Lebenslauf',
      headline: 'Lebenslauf',
      description: null,
      required: true,
      maxFiles: 1,
      hide: true
    },
    {
      name: 'Approbationsurkunde',
      headline: 'Approbationsurkunde',
      description: 'Wenn du deine Approbationsurkunde schon hast, kannst du sie hier hochladen. Das wäre empfehlenswert. Wenn nicht, reichen erst einmal die Zeugnisse der Examina.',
      required: false,
      maxFiles: 1
    },
    {
      name: 'Zeugnisse der Examina',
      headline: 'Zeugnisse der Examina',
      description: 'Hier kannst du alle Zeugnisse hochladen, die du im Laufe deines Studiums für Examina erhalten hast.',
      required: true,
      maxFiles: 100
    },
    {
      name: 'Zeugnis der ärztlichen Prüfung',
      headline: 'Zeugnis der ärztlichen Prüfung',
      description: null,
      required: true,
      maxFiles: 1
    },
    {
      name: 'Ausbildungszeugnis',
      headline: 'Ausbildungszeugnis',
      description: 'Wenn du z.B. eine Ausbildung im Rettungsdienst absolviert hast, kannst du das Zeugnis hier hochladen.',
      required: true,
      maxFiles: 1
    },
    {
      name: 'Abiturzeugnis',
      headline: 'Abiturzeugnis',
      description: 'Auch wenn es etwas unnötig wirken kann: Arbeitgeber haben gerne vollständige Unterlagen von dir.',
      required: true,
      maxFiles: 1
    },
    {
      name: 'Empfehlungsschreiben',
      headline: 'Empfehlungsschreiben',
      description: 'Wenn du im Laufe deiner ärztlichen Karriere oder im PJ Empfehlungsschreiben erhalten hast, kannst du sie hier hochladen',
      required: true,
      maxFiles: 100
    },
    {
      name: 'Auslandsaufenthalte',
      headline: 'Auslandsaufenthalte',
      description: 'Wenn du Nachweise und Zeugnisse von Auslandsaufenthalten hast, kannst du sie hier hochladen.',
      required: false,
      maxFiles: 100
    },
    {
      name: 'Fachkunde- und Fortbildungsnachweise',
      headline: 'Fachkunde- und Fortbildungsnachweise',
      description: 'Hier kannst du alles hochladen, was du an Fortbildungen gemacht und Fachkundenachweisen bekommen hast.',
      required: false,
      maxFiles: 100
    },
    {
      name: 'Promotionsurkunde',
      headline: 'Promotionsurkunde',
      description: 'Wenn du bereits promoviert bist, kannst du das Zeugnis hier hochladen.',
      required: false,
      maxFiles: 1
    },
    {
      name: 'Weiteres',
      headline: 'Andere relevante Dokumente',
      description: null,
      required: false,
      maxFiles: 100
    }
  ]
}
;
