import Check from '@mui/icons-material/Check';
import { Container, Icon, Typography } from '@mui/material';
import React, { useContext } from 'react';
import TeaserImage from '../../assets/img/teaser_img.png';
import IconWithText from './IconWithText';
import LocalOffer from '@mui/icons-material/LocalOffer';
import PrimaryButton from '../buttons/PrimaryButton';
import { WindowManagerContext } from '../../context/WindowManager';
import WizardButton from '../buttons/WizardButton';
import Timer from './Timer';
import helper from '../../assets/helper';
import DomainData from '../../assets/datasets/DomainData';

const Chip = ({ children, ...props }) =>
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      border: '2px solid rgba(0, 0, 0, 0.2)',
      padding: '4px 2px',
      borderRadius: 4,
      fontSize: '0.9rem',
      fontWeight: 500,
      backgroundColor: '#065367',
      color: 'white'
    }}
  >
    {children}
  </div>;

const points = [
  'Mehrere Briefe gleichzeitig generieren',
  'Schnellere Generierungen'
];

const TeaserContent = ({ close = () => {}, hintText, ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  return (
    <div style={{ maxWidth: 400, margin: '0 auto', fontFamily: 'Work Sans' }}>
      <center><img src={TeaserImage} width='80%' /></center>
      <br />
      {/* <h4 style={{ color: 'red', marginBottom: 0, textAlign: 'center' }}>Nur noch <Timer /> kostenlos</h4> */}
      {/* <table style={{ margin: '0 auto' }}>
        <tbody>
          <tr>
            <td style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
              Jetzt kostenlos anmelden&nbsp;
            </td>
            <td>
              <Chip>0 €</Chip>
            </td>
          </tr>
        </tbody>
      </table> */}
      <center><Typography variant='p' sx={{ color: 'grey.light' }}><b>1.500+ Medis</b> sind dabei</Typography></center>
      <br />

      {
        hintText &&
        (
          <Container style={{ margin: '12px auto', fontWeight: 400, textAlign: 'center', padding: 10, backgroundColor: '#fcf3b8', width: 'fit-content', maxWidth: 350 }}>
            {hintText}
          </Container>
        )
      }

      <div style={{ margin: 12 }}>
        {
          points.map((point, idx) => {
            return (
              <IconWithText
                key={idx}
                icon={<Check style={{ color: 'green' }} />}
                text={<Typography variant='p' sx={{ color: 'rgba(0,0,0,0.8)' }}>{point}</Typography>}
              />
            );
          })
        }
        <br />

        <IconWithText
          icon={<Check style={{ color: 'green' }} />}
          text={<Typography variant='p' sx={{ fontWeight: 600 }}>Pro Arztbrief nur {helper.formatCurrency(DomainData.PRICE_PER_CREDIT)}</Typography>}
        />
        <br />
        {/* <IconWithText
          icon={<LocalOffer />}
          text={
            <IconWithText
              icon={<b>AKTION:</b>}
              text={<Typography variant='p' sx={{ fontWeight: 600 }}>1 Bewerbung kostenlos*</Typography>}
            />
          }
        /> */}
        <center><WizardButton scale={2} onClick={() => openWindow('register', { onClose: () => close() })}>Jetzt registrieren</WizardButton></center>
        <div style={{ width: '100%', height: 10 }} />
        <center><a style={{ cursor: 'pointer' }} onClick={() => openWindow('login', { onClose: () => close() })}><Typography variant='p' sx={{ textDecoration: 'underline', color: 'grey.light' }}>Einloggen</Typography></a></center>
      </div>
    </div>
  );
};

export default TeaserContent;
