import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PersonalData from '../classes/PersonalData';

const AppDataContext = createContext();

// MOCKING
const mockData = {
  personalData: new PersonalData({
    title: 'Dr.',
    firstName: 'Thorben',
    lastName: 'Schlätzer',
    email: 'thorben@felixmedicus.de',
    address: {
      street: 'Grafenwerthstr. 5',
      postalCode: '50937',
      city: 'Köln',
      country: 'Deutschland'
    }
  })
};

const cookieName = 'fxmed-arztbrief';

const MAX_ARZTBRIEFE = 2;

const AppData = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const [remaining, setRemaining] = useState(cookies[cookieName]?.arztbriefe);

  useEffect(() => {
    if (!cookies[cookieName]) {
      console.log('sets cookie');
      setCookie(cookieName, JSON.stringify({ arztbriefe: MAX_ARZTBRIEFE }), { path: '/' });
      setRemaining(MAX_ARZTBRIEFE);
    }
  }, []);

  const decreaseArztbriefe = () => {
    console.log('descreases', cookies[cookieName]?.arztbriefe);
    const currentArztbriefGenerations = cookies[cookieName]?.arztbriefe;
    // cookies[cookieName].arztbriefe = currentArztbriefGenerations - 1;
    setCookie(cookieName, JSON.stringify({ arztbriefe: currentArztbriefGenerations - 1 }), { path: '/' });
    setRemaining(currentArztbriefGenerations - 1);
  };

  const deleteCookie = () => {
    removeCookie(cookieName, { path: '/' });
  };

  return (
    <AppDataContext.Provider value={{ remainingArztbriefe: remaining, decreaseGeneratedArztbriefe: decreaseArztbriefe, deleteCookie, maxArztbriefe: MAX_ARZTBRIEFE }}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export { AppData, AppDataContext }
;
