import { Container } from '@mui/system';
import React from 'react';

const PhotoContainer = ({ children, sx }) => (
  <Container
    sx={{
      backgroundColor: 'grey.dark',
      width: { xs: 300, sm: 300, md: 300, lg: 400, xl: 400 },
      height: { xs: 375, sm: 375, md: 375, lg: 500, xl: 500 },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx
    }}
  >{children}
  </Container>
);

export default PhotoContainer
;
