import React, { useEffect } from 'react';
import WindowWrapper from './WindowWrapper';
import LoadingIcon from '../assets/img/loading_transp.gif';
import CoverLetterWizardHolder from '../components/cover-letter-wizard/CoverLetterWizardHolder';
import CVWizardHolder from '../components/cv-wizard/CVWizardHolder';

const CVWindow = ({ style, close, className, saveCv, ...props }) => {
  useEffect(() => {
    console.log('sauhdasudgusih');
  }, []);
  return (
    <>
      <WindowWrapper
        style={{ ...style, width: '100%', height: '100%', overflow: 'none', padding: 0 }}
        close={close}
      >
        <CVWizardHolder
          applicationData={props.applicationData}
          cvId={props.cvId}
          saveCv={saveCv}
          close={close}
        />
      </WindowWrapper>
    </>
  );
};

export default CVWindow;
