import React from 'react';
import Grid from '@mui/material/Grid';
import PrimaryButton from '../buttons/PrimaryButton';
import WizardButton from '../buttons/WizardButton';

const CoverLetterWizardFooter = ({ header, next, nextText, previous, previousText }) => {
  return (
    <>
      <Grid container spacing={1} style={{ marginTop: 24 }}>
        <Grid item xs={6} md={6} xl={6}>
          <WizardButton secondary scale={1.8} style={{ display: previous ? null : 'none' }} onClick={() => previous()}>{'<'} {previousText || 'Zurück'}</WizardButton>
        </Grid>
        <Grid item xs={6} md={6} xl={6}>
          <WizardButton scale={1.8} style={{ display: next ? null : 'none', float: 'right' }} onClick={() => next()}>{nextText || 'Weiter'} {'>'}</WizardButton>
        </Grid>
      </Grid>
    </>
  );
};

export default CoverLetterWizardFooter;
