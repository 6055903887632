
import { Button, Container, Grid, Rating } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { createFeedback } from '../../api/methods';
import FeedbackIcon from '../../assets/img/feedback-icon.png';
import { AuthContext } from '../../context/Auth';
import { WindowManagerContext } from '../../context/WindowManager';
import FeedbackComponent from '../FeedbackComponent';
import InputField from '../forms/InputField';
import WizardButton from './WizardButton';

const FeedbackModal = ({ show, onClose }) => {
  return (
    <div className={`popup-modal ${show ? 'show' : ''}`} style={{ display: 'relative', pointerEvents: show ? null : 'none' }}>
      <a onClick={() => onClose()} style={{ textDecoration: 'none', cursor: 'pointer', float: 'right', marginRight: 10, fontSize: 22, marginTop: 10 }}>x</a>
      <div className='popup-modal-content'>
        <h2>Gib uns Feedback!</h2>
        <p>Jede Rückmeldung hilft uns, besser zu werden!</p>
        <FeedbackComponent style={{ margin: '0px auto' }} onClose={onClose} />
        <br /><br />
        <WizardButton style={{ background: '#F6BFC0', float: 'right', marginBottom: 20 }} scale={1.5} onClick={onClose}>Schließen</WizardButton>
      </div>
    </div>
  );
};

const FeedbackButton = ({ secondary, scale = 1, style: propStyle, ...props }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { auth = {}, api } = useContext(AuthContext);

  const style = {
    backgroundColor: 'primary.main',
    textTransformation: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    padding: 0,
    paddingLeft: '13px!important',
    paddingTop: '10px!important',
    position: 'fixed',
    left: '50px',
    bottom: '50px',
    '&:hover': {
      backgroundColor: 'secondary.main'
    },
    display: auth.loggedIn ? null : 'none',
    ...propStyle
  };

  return (
    <>
      <Container onClick={() => setShowPopup(!showPopup)} sx={style}><img src={FeedbackIcon} width={30} /></Container>
      <FeedbackModal show={showPopup} onClose={() => setShowPopup(false)} />
    </>
  );
};

export default FeedbackButton;
