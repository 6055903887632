import React, { useContext, useEffect, useState } from 'react';
import { WindowManagerContext } from '../../context/WindowManager';
import ApplicationHeader from '../main-view/ApplicationHeader';
import Table from '@mui/material/Table';
import { getAllApplications } from '../../api/methods';
import { AuthContext } from '../../context/Auth';
import { Hidden, Skeleton, styled, TableCell, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import { createSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import LoadingIcon from '../../assets/img/loading_transp.gif';
import Avatar from '../../assets/img/felix_medicus_avatar.png';
import configuration from '../../configuration';
import IconWithText from '../misc/IconWithText';
import SyncIcon from '@mui/icons-material/Sync';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NoAuthInfoBox from '../misc/NoAuthInfoBox';

const statusMapper = {
  'in progress': <Tooltip title='in Bearbeitung'><SyncIcon sx={{ color: 'secondary.main' }} /></Tooltip>,
  sent: <Tooltip title='Versandt'><CheckCircleIcon sx={{ color: 'success.main' }} /></Tooltip>
};

const sortApplicationsByDate = applications => {
  const applicationsByDate = {};
  for (const application of applications) {
    const date = new Intl.DateTimeFormat('en-US').format(new Date(application.updatedAt));
    if (!applicationsByDate[date]) {
      applicationsByDate[date] = [application];
    } else {
      applicationsByDate[date] = [...applicationsByDate[date], application];
    }
  }
  const ordered = Object.keys(applicationsByDate).sort().reverse().reduce(
    (obj, key) => {
      obj[key] = applicationsByDate[key];
      return obj;
    },
    {}
  );
  console.log(ordered);
  return ordered;
};

const applicationNameStyle = {
  fontStyle: 'normal',
  fontWeight: 500,
  letterSpacing: -0.02,
  color: 'grey.dark'
};

const applicationUnderTitleStyle = {
  fontStyle: 'normal',
  fontWeight: 500,
  letterSpacing: -0.02,
  color: 'grey.light'// 'rgba(0, 0, 0, 0.46)'
};

const JobApplications = ({ navigate, close = () => {}, ...props }) => {
  const { openWindow } = useContext(WindowManagerContext);
  const { auth, api } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  console.log({ navigate, ...props });

  const StyledTableCell = styled(TableCell)({
    padding: 10
  });

  useEffect(() => {
    if (auth?.jwt) {
      setLoading(true);
      api(jwt => getAllApplications(jwt))
        .then(result => setApplications(sortApplicationsByDate(result)))
        .catch(err => { alert('Bewerbungen konnten nicht geladen werden. ' + err.stack); console.log(err); })
        .finally(() => setLoading(false));
    }
  }, []);

  const TableTitle = props => <h4 style={{ ...applicationUnderTitleStyle, fontWeight: 'bold', margin: 0 }}>{props.children}</h4>;

  return (
    <>
      <ApplicationHeader
        header='Bewerbungen'
        ctaText='+ Neue Bewerbung'
        cta={() => openWindow('newApplication', { navigate, createSearchParams })}
      />
      {
        auth?.loggedIn
          ? (
            <Table style={{ maxWidth: configuration.standardWidth, color: 'rgba(0, 0, 0, 0.67)' }}>
              <TableHead>
                <TableRow sx={{ 'td, th': { border: 0, borderBottom: '2px solid rgba(0,0,0,0.3)' } }}>
                  <StyledTableCell><TableTitle>Bewerbung</TableTitle></StyledTableCell>
                  {!isMobile && <StyledTableCell><TableTitle>Status</TableTitle></StyledTableCell>}
                  <StyledTableCell><TableTitle>Aktionen</TableTitle></StyledTableCell>
                </TableRow>
              </TableHead>
              {
                applications
                  ? Object.keys(applications).map((orDate, idx) => {
                      const date = new Intl.DateTimeFormat('de').format(new Date(orDate));
                      return (
                        <React.Fragment key={idx}>
                          <TableRow sx={{ 'td, th': { border: 0 } }}>
                            <StyledTableCell>
                              <h3 style={{ marginBottom: 12 }}>{date}</h3>
                            </StyledTableCell>
                            {!isMobile && <StyledTableCell>&nbsp;</StyledTableCell>}
                            <StyledTableCell>&nbsp;</StyledTableCell>
                          </TableRow>
                          {
                        applications[orDate].map((application, idx2) => {
                          return (
                            <TableRow
                              key={idx2}
                              sx={{ 'td, th': { border: 0, borderBottom: '0.5px solid rgba(0,0,0,0.09)' } }}
                            >
                              <StyledTableCell>
                                <IconWithText
                                  icon={isMobile ? statusMapper[application.status || 'in progress'] : <img src={Avatar} />}
                                  text={
                                    <div style={{ marginLeft: 12 }}>
                                      <h3 style={{ ...applicationNameStyle, margin: 0 }}>
                                        {application.employerData?.name || 'Name: keine Angabe'}
                                      </h3>
                                      <p style={{ ...applicationUnderTitleStyle, margin: 0 }}>{application.subject || 'Noch kein Betreff'}</p>
                                    </div>
                                  }
                                />
                              </StyledTableCell>
                              {!isMobile && <StyledTableCell><center>{statusMapper[application.status || 'in progress']}</center></StyledTableCell>}
                              <StyledTableCell><center><a style={{ cursor: 'pointer' }} onClick={() => { navigate('/applications/new?aid=' + application.id); close(); }}><EditIcon /></a></center></StyledTableCell>
                            </TableRow>
                          );
                        })
                      }
                        </React.Fragment>
                      );
                    })
                  : [
                    <TableRow sx={{ 'td, th': { border: 0 } }} key={0}>
                      <StyledTableCell>
                        {
                        isMobile
                          ? <Skeleton variant='rectangular' width={50} height={25} />
                          : <Skeleton variant='rectangular' width={50} height={25} />
                        }
                      </StyledTableCell>
                      {!isMobile && <StyledTableCell>&nbsp;</StyledTableCell>}
                      <StyledTableCell>&nbsp;</StyledTableCell>
                    </TableRow>,
                    <TableRow sx={{ 'td, th': { border: 0 } }} key={1}>
                      <StyledTableCell>
                        {
                        isMobile
                          ? <Skeleton variant='rectangular' width={200} height={50} />
                          : <Skeleton variant='rectangular' width={600} height={40} />
                        }
                      </StyledTableCell>
                      {!isMobile && <StyledTableCell><Skeleton variant='rectangular' width={10} height={25} /></StyledTableCell>}
                      <StyledTableCell><Skeleton variant='rectangular' width={10} height={25} /></StyledTableCell>
                    </TableRow>,
                    <TableRow sx={{ 'td, th': { border: 0 } }} key={2}>
                      <StyledTableCell>
                        {
                        isMobile
                          ? <Skeleton variant='rectangular' width={200} height={50} />
                          : <Skeleton variant='rectangular' width={600} height={40} />
                        }
                      </StyledTableCell>
                      {!isMobile && <StyledTableCell><Skeleton variant='rectangular' width={10} height={25} /></StyledTableCell>}
                      <StyledTableCell><Skeleton variant='rectangular' width={10} height={25} /></StyledTableCell>
                    </TableRow>,
                    <TableRow sx={{ 'td, th': { border: 0 } }} key={3}>
                      <StyledTableCell>
                        {
                        isMobile
                          ? <Skeleton variant='rectangular' width={200} height={50} />
                          : <Skeleton variant='rectangular' width={600} height={40} />
                        }
                      </StyledTableCell>
                      {!isMobile && <StyledTableCell><Skeleton variant='rectangular' width={10} height={25} /></StyledTableCell>}
                      <StyledTableCell><Skeleton variant='rectangular' width={10} height={25} /></StyledTableCell>
                    </TableRow>
                    ]
              }
            </Table>
            )
          : (
            <NoAuthInfoBox />
            )
      }

      {
        applications?.length === 0 && <>Keine Bewerbungen vorhanden.<br /></>
      }
    </>
  );
};

export default JobApplications;
