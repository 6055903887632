import React, { createContext } from 'react';
import useDeviceDetect from '../utils/useDeviceDetect';

const AppInfoContext = createContext();

const AppInfo = (props) => {
  const { isMobile } = useDeviceDetect();

  return (
    <AppInfoContext.Provider value={{ isMobile, type: 'med' }}>
      {props.children}
    </AppInfoContext.Provider>
  );
};

export { AppInfo, AppInfoContext }
;
