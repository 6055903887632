// import React from 'react';

const TEST = process.env.NODE_ENV === 'development';
const backendAddress = TEST ? 'http://localhost:5001' : 'https://rating-backend-jfrpoi655a-ey.a.run.app';
const baseDomain = TEST ? 'http://localhost:3001' : 'https://app.felixmedicus.de';
const website = 'https://felixmedicus.de';

const serviceCredentials = {
  mapsKey: 'AIzaSyCGTnBAGej6cwezP_KEMWYEvQoewy-uuiU'
};

const ratingColors = {
  '-1': '#AB0000',
  0: '#748AFF',
  1: '#017421',
  null: 'grey'
};

const stats = {
  averageBeds: 258
};

const standardWidth = 800;

const websiteRoutes = {
  dataPrivacy: '/datenschutz',
  agb: '/agb'
};

const passwordPolicies = [
  'Mindestens 8 Zeichen',
  'Mindestens ein Großbuchstabe',
  'Mindestens ein Kleinbuchstabe',
  'Mindestens eine Zahl'
];

export default {
  stats,
  applicationPrice: 200,
  ratingColors,
  baseDomain,
  backendAddress,
  serviceCredentials,
  website,
  websiteRoutes,
  passwordPolicies,
  standardWidth
};
