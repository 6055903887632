import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import helper from '../assets/helper';
import IconWithText from '../components/misc/IconWithText';

const FileWithFileName = ({ file, removeFile, ...props }) => {
  const mimeTypeToImage = helper.mimeTypeToImage;
  const shortenName = helper.shortenString;

  return (
    <IconWithText
      icon={<img src={mimeTypeToImage(file.mime_type || file.type)} height={24} />}
      text={
        <>
          <span style={{ color: 'grey' }}>{file.name ? shortenName(file.name, 20) : ''}</span>
          &nbsp;&nbsp;
          <a
            style={{ cursor: 'pointer', color: 'red' }}
            onClick={() => removeFile(file)}
          >
            x
          </a>
        </>
      }
    />
  );
};

export default FileWithFileName
;
