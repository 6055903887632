
import { Button } from '@mui/material';
import React from 'react';

const WizardButton = ({ secondary, scale = 1, ...props }) => {
  const style = {
    backgroundColor: secondary ? 'white' : 'rgba(167, 255, 146, 0.5)',
    border: '1px solid rgba(0, 0, 0, 0.13)',
    fontSize: Math.min(0.9, 0.6 * (scale * 0.9)) + 'rem',
    fontWeight: 600,
    color: 'grey.dark',
    textTransform: 'none',
    padding: `${4 * scale}px ${12 * scale}px!important`,
    '&:hover': {
      backgroundColor: secondary ? 'grey.lightest' : 'rgba(167, 255, 146, 0.3)',
      boxShadow: 0
    },
    boxShadow: 0
  };
  return (
    <Button variant='contained' {...props} sx={style}>{props.children}</Button>
  );
};

export default WizardButton;
