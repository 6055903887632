import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import React, { createContext } from 'react';
import UserPool from '../auth/UserPool';
import { signup, getAuthInfo, getOldUser, updateUserData, decreaseCredits } from '../api/methods';
import { WindowManagerContext } from './WindowManager';

const AuthContext = createContext();

const Auth = (props) => {
  const [auth, setAuth] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const { openWindow } = React.createContext(WindowManagerContext);

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      } else {
        reject(new Error('no user'));
      }
    });
  };

  const authenticatedAPICall = async (method) => {
    try {
      const result = await method(auth.jwt);
      return result;
    } catch (error) {
      console.log('apiCall:', error, error.response?.status, error.stack);
      if (error.response?.status === 401 || error.response?.status === 403 || error.stack.indexOf('CORS') > -1) {
        const authDetails = await getAuth();
        console.log('is loggedIn?', authDetails.loggedIn);
        if (authDetails.loggedIn) {
          const result = await method(authDetails.jwt);
          const userInfo = await getAuthInfo(authDetails.jwt);
          console.log('userInfo', userInfo);
          setUser(userInfo);
          setAuth(authDetails);
          return result;
        } else {
          openWindow('login');
        }
      } else {
        console.log(error.response);
        throw new Error(error.response?.data?.failure || 'failure');
      }
    }
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool: UserPool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log('onSuccess', data);
          resolve(data);
        },
        onFailure: (err) => {
          console.log('onFailure', err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          delete data.email_verified;
          user.completeNewPasswordChallenge(Password, data, {
            onSuccess: result => {
              resolve(result);
            },
            onFailure: err => {
              alert(err);
              reject(err);
            }
          });
        }
      });
    });
  };

  const getAuth = async () => {
    let session;
    try {
      session = await getSession();
      const jwt = session.idToken.jwtToken;
      return ({
        loggedIn: true,
        jwt
      });
    } catch (err) {
      return ({
        loggedIn: false,
        jwt: null
      });
    }
  };

  /* const hasLicense = async (licenseType) => {

  } */

  const login = async (email, password) => {
    let accessInfo;
    try {
      accessInfo = await authenticate(email, password);
    } catch (error) {
      console.log(error);
      throw new Error('Username und Passwort stimmen nicht überein');
    }
    const jwt = accessInfo.idToken.jwtToken;
    const userInfo = await getAuthInfo(jwt);
    setUser(userInfo);
    setAuth({ ...auth, loggedIn: true, jwt, ready: true });
  };

  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      setAuth({ loggedIn: false, ready: true });
      setUser({ });
    }
  };

  const decreaseCreditsForUser = async creditsToDecrease => {
    await authenticatedAPICall(jwt => decreaseCredits(creditsToDecrease, jwt));
    await reloadUser();
  };

  const reloadUser = async () => {
    if (auth.loggedIn) {
      const userInfo = await getAuthInfo(auth.jwt);
      setUser(userInfo);
    }
  };

  const silentLogin = async () => {
    try {
      const info = await getAuth();
      const userInfo = await getAuthInfo(info.jwt);
      console.log('userInfo', userInfo);
      setUser(userInfo);
      setAuth({ ...info, ready: true });
    } catch (error) {
      setUser(null);
      setAuth({ loggedIn: false, ready: true });
      console.log(error);
      // openWindow('login');
    }
  };

  const saveUserData = async (data) => {
    if (auth.loggedIn) {
      console.log('saving...', data);
      await authenticatedAPICall(jwt => updateUserData({ ...auth.user, ...data }, jwt));
      setUser(old => ({ ...old, ...data }));
    }
  };

  React.useEffect(() => {
    silentLogin();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, user, reloadUser, decreaseCreditsForUser, login, logout, signup, saveUserData, setLoggedIn: () => setAuth({ ...auth, loggedIn: true }), api: authenticatedAPICall }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { Auth, AuthContext }
;
