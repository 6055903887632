import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import helper from '../assets/helper';
import IconWithText from '../components/misc/IconWithText';
import FileWithFileName from './FileWithFileName';

const UploadItemsHolder = ({ removeFile, toUpload, files }) => {
  if (!toUpload) {
    return files.map((file, idx) => {
      return (
        <Card key={'file' + idx} style={{ border: '1px solid grey' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid xl={12} md={12} xs={12}>
                <FileWithFileName removeFile={removeFile} file={file} />
                {/* <img src={mimeTypeToImage(file.mime_type || file.type)} height={24} />
                {file.name}&nbsp;&nbsp;
                <span style={{ fontSize: 12, color: 'grey' }}>{file.file_name ? shortenName(file.file_name, 20) : ''}</span> */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    });
  } else {
    return files.map((file, idx) => {
      return (
        <Grid container key={'file' + idx}>
          <Grid xl={12} md={12} xs={12}>
            <FileWithFileName file={file} />
          </Grid>
        </Grid>
      );
    });
  }
};

export default UploadItemsHolder
;
